import Layout from '../../Containers/Layout';
import styles from './Privacy.module.scss';
import gdpr1 from '../../Media/gdpr - 1.svg';
import gdpr2 from '../../Media/gdpr - 2.svg';
import gdpr3 from '../../Media/gdpr - 3.svg';
import gdpr4 from '../../Media/gdpr - 4.svg';
import gdpr5 from '../../Media/gdpr - 5.svg';
import gdpr6 from '../../Media/gdpr - 6.svg';
import gdpr7 from '../../Media/gdpr - 7.svg';
import { useTranslation } from 'react-i18next';

const GetPolicyDescription = () => {
    return (
        <div>
        <img src={gdpr1} alt="gdpr" style={{width: "60vw", margin: "auto"}}/>
        <img src={gdpr2} alt="gdpr "style={{width: "60vw"}}/>
        <img src={gdpr3} alt="gdpr" style={{width: "60vw"}}/>
        <img src={gdpr4} alt="gdpr" style={{width: "60vw"}}/>
        <img src={gdpr5} alt="gdpr" style={{width: "60vw"}}/>
        <img src={gdpr6} alt="gdpr" style={{width: "60vw"}}/>
        <img src={gdpr7} alt="gdpr" style={{width: "60vw"}}/>
        </div>
    );
}  

const Privacy = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.tableElements}>
                        <div id="politica" className={styles.container}>
                            {GetPolicyDescription()}
                        </div>
                    </div>
                    <h3>{t('terms.rulesApplicationDate')}</h3>
                </div>
            </div>
        </Layout>
    );
};

export default Privacy;
