/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum StudyDomainEnum {
    LCti = 'L_CTI',
    LAuto = 'L_Auto',
    LMateriale = 'L_Materiale',
    LMediu = 'L_Mediu',
    LAlimente = 'L_Alimente',
    LSisteme = 'L_Sisteme',
    LTransporturi = 'L_Transporturi',
    LAerospatiala = 'L_Aerospatiala',
    LChimica = 'L_Chimica',
    LElectrica = 'L_Electrica',
    LEtti = 'L_ETTI',
    LEnergetica = 'L_Energetica',
    LIndustriala = 'L_Industriala',
    LMecanica = 'L_Mecanica',
    LManagement = 'L_Management',
    LMecatronica = 'L_Mecatronica',
    LAplicate = 'L_Aplicate',
    MCti = 'M_CTI',
    MAuto = 'M_Auto',
    MMateriale = 'M_Materiale',
    MMediu = 'M_Mediu',
    MAlimente = 'M_Alimente',
    MSisteme = 'M_Sisteme',
    MTransporturi = 'M_Transporturi',
    MAerospatiala = 'M_Aerospatiala',
    MChimica = 'M_Chimica',
    MElectrica = 'M_Electrica',
    MEtti = 'M_ETTI',
    MEnergetica = 'M_Energetica',
    MIndustriala = 'M_Industriala',
    MMecanica = 'M_Mecanica',
    MManagement = 'M_Management',
    MMecatronica = 'M_Mecatronica',
    MAplicate = 'M_Aplicate',
    MComunicare = 'M_Comunicare',
    MRelatii = 'M_Relatii',
    MEconomie = 'M_Economie',
    MEducatie = 'M_Educatie',
    All = 'All'
}

export function StudyDomainEnumFromJSON(json: any): StudyDomainEnum {
    return StudyDomainEnumFromJSONTyped(json, false);
}

export function StudyDomainEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudyDomainEnum {
    return json as StudyDomainEnum;
}

export function StudyDomainEnumToJSON(value?: StudyDomainEnum | null): any {
    return value as any;
}

