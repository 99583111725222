/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiGenerateProposalGenerateProposalIdGetRequest {
    proposalId: string;
}

/**
 * 
 */
export class GenerateProposalApi extends runtime.BaseAPI {

    /**
     */
    async apiGenerateProposalGenerateProposalIdGetRaw(requestParameters: ApiGenerateProposalGenerateProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiGenerateProposalGenerateProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/GenerateProposal/Generate/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiGenerateProposalGenerateProposalIdGet(requestParameters: ApiGenerateProposalGenerateProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiGenerateProposalGenerateProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
