import Layout from '../../Containers/Layout';
import styles from './Terms.module.scss';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const { REACT_APP_DOMAIN } = process.env;

const Terms = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={styles.tableofContents}>
                    <ul style={{ listStyle: 'none', height: "auto", width: " 100%" }}>
                        <li><Link activeClass="active" to="terms" spy={true} smooth={true}>1. {t('terms.termsAndConditions')}</Link></li>
                        <li><Link to="register" spy={true} smooth={true}>2. {t('terms.register')}</Link></li>
                        <li><Link activeClass="active" to="verify" spy={true} smooth={true}>3. {t('terms.verify')}</Link></li>
                        <li><Link to="opportunities" spy={true} smooth={true}>4. {t('terms.jobOpportunities')}</Link></li>
                        <li><Link to="payment" spy={true} smooth={true}>5. {t('terms.payment')}</Link></li>
                        <li><Link activeClass="active" to="rights" spy={true} smooth={true}>6. {t('terms.propertyRigths')}</Link></li>
                        <li><Link activeClass="active" to="activities" spy={true} smooth={true}>7. {t('terms.unauthorizedActivities')}</Link></li>
                        <li><Link activeClass="active" to="thirdParties" spy={true} smooth={true}>8. {t('terms.thirdPartyWebsites')}</Link></li>
                        <li><Link activeClass="active" to="dispo" spy={true} smooth={true}>9. {t('terms.availability')}</Link></li>
                        <li><Link activeClass="active" to="disclaimer" spy={true} smooth={true}>10. {t('terms.disclaimer')}</Link></li>
                        <li><Link activeClass="active" to="limitation" spy={true} smooth={true}>11. {t('terms.responsibilityLimitation')}</Link></li>
                        <li><Link activeClass="active" to="procedures" spy={true} smooth={true}>12. {t('terms.appealProcedures')}</Link></li>
                        <li><Link activeClass="active" to="changes" spy={true} smooth={true}>13. {t('terms.termsChanges')}</Link></li>

                    </ul>
                </div>
                <div className={styles.content}>
                    <div className={styles.tableElements}>
                        <div id="terms" className={styles.container}>
                            <h2>{t('terms.termsAndConditions')}</h2>
                            <p>
                                {t('terms.welcomeToUpb')}<br />
                                {REACT_APP_DOMAIN} {t('terms.domain')}<br />
                                {t('terms.poliAddress')}<br />
                                {t('terms.termsAndConditionsRules')}<br />
                            </p>
                        </div>

                        <div id="register" className={styles.container}>
                            <h2>{t('terms.register')}</h2>
                            <p>{t('terms.registerRules')}</p>
                        </div>

                        <div id="verify" className={styles.container}>
                            <h2>{t('terms.verify')}</h2>
                            <p>{t('terms.verifyRules')}</p>
                        </div>

                        <div id="opportunities" className={styles.container}>
                            <h2>{t('terms.jobOpportunities')}</h2>
                            <p>{t('terms.jobOpportunitiesRules')}</p>
                        </div>

                        <div id="payment" className={styles.container}>
                            <h2>{t('terms.payment')}</h2>
                            <p>{t('terms.paymentRules')}</p>
                        </div>

                        <div id="rights" className={styles.container}>
                            <h2>{t('terms.propertyRigths')}</h2>
                            <p>{t('terms.propertyRightsRules')}</p>
                        </div>

                        <div id="activities" className={styles.container}>
                            <h2>{t('terms.unauthorizedActivities')}</h2>
                            <p>{t('terms.unauthorizedActivitiesRules')}</p>
                        </div>

                        <div id="thirdParties" className={styles.container}>
                            <h2>{t('terms.thirdPartyWebsites')}</h2>
                            <p>{t('terms.thirdPartyWebsitesRules')}</p>
                        </div>

                        <div id="dispo" className={styles.container}>
                            <h2>{t('terms.availability')}</h2>
                            <p>{t('terms.availabilityRules')}</p>
                        </div>

                        <div id="disclaimer" className={styles.container}>
                            <h2>{t('terms.disclaimer')}</h2>
                            <p>{t('terms.disclaimer')}</p>
                        </div>

                        <div id="limitation" className={styles.container}>
                            <h2>{t('terms.responsibilityLimitation')}</h2>
                            <p>{t('terms.responsibilityLimitationRules')}</p>
                        </div>

                        <div id="procedures" className={styles.container}>
                            <h2>{t('terms.appealProcedures')}</h2>
                            <p>{t('terms.appealProceduresRules')}</p>
                        </div>
                        <div id="changes" className={styles.container}>
                            <h2>{t('terms.termsChanges')}</h2>
                            <p>{t('terms.termsChangesRules')}</p>
                        </div>
                    </div>
                    <h3>{t('terms.rulesApplicationDate')}</h3>
                </div>
            </div>
        </Layout >
    );
};

export default Terms;
