/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiFileManagerDownloaRNPPApprovalProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadCVsProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadDepartmentApprovalProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadFacultyApprovalProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadQAApprovalProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadSenateApprovalProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerDownloadSubjectFilesProposalIdGetRequest {
    proposalId: string;
}

export interface ApiFileManagerUploadCVsProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadDepartmentApprovalProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadFacultyApprovalProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadQAApprovalProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadRNPPApprovalProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadSenateApprovalProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

export interface ApiFileManagerUploadSubjectFilesProposalIdPostRequest {
    proposalId: string;
    file?: Blob;
}

/**
 * 
 */
export class FileManagerApi extends runtime.BaseAPI {

    /**
     */
    async apiFileManagerDownloaRNPPApprovalProposalIdGetRaw(requestParameters: ApiFileManagerDownloaRNPPApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloaRNPPApprovalProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloaRNPPApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloaRNPPApprovalProposalIdGet(requestParameters: ApiFileManagerDownloaRNPPApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloaRNPPApprovalProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadCVsProposalIdGetRaw(requestParameters: ApiFileManagerDownloadCVsProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadCVsProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadCVs/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadCVsProposalIdGet(requestParameters: ApiFileManagerDownloadCVsProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadCVsProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadDepartmentApprovalProposalIdGetRaw(requestParameters: ApiFileManagerDownloadDepartmentApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadDepartmentApprovalProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadDepartmentApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadDepartmentApprovalProposalIdGet(requestParameters: ApiFileManagerDownloadDepartmentApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadDepartmentApprovalProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadFacultyApprovalProposalIdGetRaw(requestParameters: ApiFileManagerDownloadFacultyApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadFacultyApprovalProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadFacultyApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadFacultyApprovalProposalIdGet(requestParameters: ApiFileManagerDownloadFacultyApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadFacultyApprovalProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadQAApprovalProposalIdGetRaw(requestParameters: ApiFileManagerDownloadQAApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadQAApprovalProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadQAApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadQAApprovalProposalIdGet(requestParameters: ApiFileManagerDownloadQAApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadQAApprovalProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadSenateApprovalProposalIdGetRaw(requestParameters: ApiFileManagerDownloadSenateApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadSenateApprovalProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadSenateApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadSenateApprovalProposalIdGet(requestParameters: ApiFileManagerDownloadSenateApprovalProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadSenateApprovalProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerDownloadSubjectFilesProposalIdGetRaw(requestParameters: ApiFileManagerDownloadSubjectFilesProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerDownloadSubjectFilesProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/FileManager/DownloadSubjectFiles/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async apiFileManagerDownloadSubjectFilesProposalIdGet(requestParameters: ApiFileManagerDownloadSubjectFilesProposalIdGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiFileManagerDownloadSubjectFilesProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFileManagerUploadCVsProposalIdPostRaw(requestParameters: ApiFileManagerUploadCVsProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadCVsProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadCVs/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadCVsProposalIdPost(requestParameters: ApiFileManagerUploadCVsProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadCVsProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadDepartmentApprovalProposalIdPostRaw(requestParameters: ApiFileManagerUploadDepartmentApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadDepartmentApprovalProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadDepartmentApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadDepartmentApprovalProposalIdPost(requestParameters: ApiFileManagerUploadDepartmentApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadDepartmentApprovalProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadFacultyApprovalProposalIdPostRaw(requestParameters: ApiFileManagerUploadFacultyApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadFacultyApprovalProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadFacultyApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadFacultyApprovalProposalIdPost(requestParameters: ApiFileManagerUploadFacultyApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadFacultyApprovalProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadQAApprovalProposalIdPostRaw(requestParameters: ApiFileManagerUploadQAApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadQAApprovalProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadQAApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadQAApprovalProposalIdPost(requestParameters: ApiFileManagerUploadQAApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadQAApprovalProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadRNPPApprovalProposalIdPostRaw(requestParameters: ApiFileManagerUploadRNPPApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadRNPPApprovalProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadRNPPApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadRNPPApprovalProposalIdPost(requestParameters: ApiFileManagerUploadRNPPApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadRNPPApprovalProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadSenateApprovalProposalIdPostRaw(requestParameters: ApiFileManagerUploadSenateApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadSenateApprovalProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadSenateApproval/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadSenateApprovalProposalIdPost(requestParameters: ApiFileManagerUploadSenateApprovalProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadSenateApprovalProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFileManagerUploadSubjectFilesProposalIdPostRaw(requestParameters: ApiFileManagerUploadSubjectFilesProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiFileManagerUploadSubjectFilesProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/FileManager/UploadSubjectFiles/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFileManagerUploadSubjectFilesProposalIdPost(requestParameters: ApiFileManagerUploadSubjectFilesProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFileManagerUploadSubjectFilesProposalIdPostRaw(requestParameters, initOverrides);
    }

}
