/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDTO
 */
export interface AdminDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly departmentNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDTO
     */
    readonly departmentNameEn?: string | null;
}

export function AdminDTOFromJSON(json: any): AdminDTO {
    return AdminDTOFromJSONTyped(json, false);
}

export function AdminDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'departmentNameRo': !exists(json, 'departmentNameRo') ? undefined : json['departmentNameRo'],
        'departmentNameEn': !exists(json, 'departmentNameEn') ? undefined : json['departmentNameEn'],
    };
}

export function AdminDTOToJSON(value?: AdminDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

