/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ANAFCompanyDetailsDTO,
    ANAFCompanyDetailsDTOFromJSON,
    ANAFCompanyDetailsDTOToJSON,
    CompanyAddDTO,
    CompanyAddDTOFromJSON,
    CompanyAddDTOToJSON,
    CompanyDTO,
    CompanyDTOFromJSON,
    CompanyDTOToJSON,
    CompanyDTOPagedResponse,
    CompanyDTOPagedResponseFromJSON,
    CompanyDTOPagedResponseToJSON,
    CompanyLoginDTO,
    CompanyLoginDTOFromJSON,
    CompanyLoginDTOToJSON,
    CompanyLoginResponseDTO,
    CompanyLoginResponseDTOFromJSON,
    CompanyLoginResponseDTOToJSON,
    CompanyStateEnum,
    CompanyStateEnumFromJSON,
    CompanyStateEnumToJSON,
    CompanyUpdateDTO,
    CompanyUpdateDTOFromJSON,
    CompanyUpdateDTOToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
} from '../models';

export interface ApiCompanyActivateIdPutRequest {
    id: string;
}

export interface ApiCompanyApproveIdPutRequest {
    id: string;
}

export interface ApiCompanyDeleteIdDeleteRequest {
    id: string;
}

export interface ApiCompanyGetAllGetRequest {
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyGetByCuiCuiGetRequest {
    cui: number;
}

export interface ApiCompanyGetByIdIdGetRequest {
    id: string;
}

export interface ApiCompanyGetCompaniesByStateGetRequest {
    namePattern?: string;
    companyStates?: Array<CompanyStateEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyLoginPostRequest {
    companyLoginDTO?: CompanyLoginDTO;
}

export interface ApiCompanyRegisterPostRequest {
    companyAddDTO?: CompanyAddDTO;
}

export interface ApiCompanyRejectIdPutRequest {
    id: string;
}

export interface ApiCompanyUpdateIdPutRequest {
    id: string;
    companyUpdateDTO?: CompanyUpdateDTO;
}

export interface ApiCompanyValidateEmailTokenPostRequest {
    token: string;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyActivateIdPutRaw(requestParameters: ApiCompanyActivateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyActivateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Activate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyActivateIdPut(requestParameters: ApiCompanyActivateIdPutRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyActivateIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyApproveIdPutRaw(requestParameters: ApiCompanyApproveIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyApproveIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Approve/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyApproveIdPut(requestParameters: ApiCompanyApproveIdPutRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyApproveIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyDeleteIdDeleteRaw(requestParameters: ApiCompanyDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyDeleteIdDelete(requestParameters: ApiCompanyDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyGetAllGetRaw(requestParameters: ApiCompanyGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyGetAllGet(requestParameters: ApiCompanyGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyDTOPagedResponse> {
        const response = await this.apiCompanyGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyGetByCuiCuiGetRaw(requestParameters: ApiCompanyGetByCuiCuiGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ANAFCompanyDetailsDTO>> {
        if (requestParameters.cui === null || requestParameters.cui === undefined) {
            throw new runtime.RequiredError('cui','Required parameter requestParameters.cui was null or undefined when calling apiCompanyGetByCuiCuiGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetByCui/{cui}`.replace(`{${"cui"}}`, encodeURIComponent(String(requestParameters.cui))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ANAFCompanyDetailsDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyGetByCuiCuiGet(requestParameters: ApiCompanyGetByCuiCuiGetRequest, initOverrides?: RequestInit): Promise<ANAFCompanyDetailsDTO> {
        const response = await this.apiCompanyGetByCuiCuiGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyGetByIdIdGetRaw(requestParameters: ApiCompanyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyGetByIdIdGet(requestParameters: ApiCompanyGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyGetCompaniesByStateGetRaw(requestParameters: ApiCompanyGetCompaniesByStateGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.companyStates) {
            queryParameters['CompanyStates'] = requestParameters.companyStates;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetCompaniesByState`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyGetCompaniesByStateGet(requestParameters: ApiCompanyGetCompaniesByStateGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyDTOPagedResponse> {
        const response = await this.apiCompanyGetCompaniesByStateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyGetCompanyStateFiltersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/GetCompanyStateFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiCompanyGetCompanyStateFiltersGet(initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiCompanyGetCompanyStateFiltersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyLoginPostRaw(requestParameters: ApiCompanyLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyLoginResponseDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyLoginDTOToJSON(requestParameters.companyLoginDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyLoginResponseDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyLoginPost(requestParameters: ApiCompanyLoginPostRequest = {}, initOverrides?: RequestInit): Promise<CompanyLoginResponseDTO> {
        const response = await this.apiCompanyLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyRegisterPostRaw(requestParameters: ApiCompanyRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyAddDTOToJSON(requestParameters.companyAddDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyRegisterPost(requestParameters: ApiCompanyRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyRejectIdPutRaw(requestParameters: ApiCompanyRejectIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyRejectIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Reject/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyRejectIdPut(requestParameters: ApiCompanyRejectIdPutRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyRejectIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUpdateIdPutRaw(requestParameters: ApiCompanyUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUpdateDTOToJSON(requestParameters.companyUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUpdateIdPut(requestParameters: ApiCompanyUpdateIdPutRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyUpdateIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyValidateEmailTokenPostRaw(requestParameters: ApiCompanyValidateEmailTokenPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling apiCompanyValidateEmailTokenPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Company/ValidateEmail/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyValidateEmailTokenPost(requestParameters: ApiCompanyValidateEmailTokenPostRequest, initOverrides?: RequestInit): Promise<CompanyDTO> {
        const response = await this.apiCompanyValidateEmailTokenPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
