import { AcademicUserApi, AcademicUserUpdateDTO, StudentYearEnum, AcademicUserUpdateRoleDTO } from "../Api";
import { arrayParamAjust } from "../utils/dataUtils";
import { getAuthConfiguration } from "./configuration";

const AuthAcademicUserApiFactory = () => new AcademicUserApi(getAuthConfiguration());

//de testat toate
export const getAllAcademicUsers = (namePattern?: string, page?: number, pageSize?: number, faculties?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAllAcademicUsersGet({ namePattern, faculties: arrayParamAjust(faculties), page, pageSize });
}

export const getAllStudentsAdmin = (namePattern?: string, page?: number, pageSize?: number, years?: string[], faculties?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsGet(
        {
            namePattern,
            years: arrayParamAjust(years?.map(e => StudentYearEnum[e as keyof typeof StudentYearEnum])),
            faculties: arrayParamAjust(faculties),
            page,
            pageSize
        }
    );
}

export const getAllStudentsProfessor = (namePattern?: string, page?: number, pageSize?: number, years?: string[], departments?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAllStudentsGet(
        {
            namePattern,
            years: arrayParamAjust(years?.map(e => StudentYearEnum[e as keyof typeof StudentYearEnum])),
            departments: arrayParamAjust(departments),
            page,
            pageSize
        }
    );
}

export const getAllProfessors = (namePattern?: string, page?: number, pageSize?: number, faculties?: string[], departments?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAllProfessorsGet({ namePattern, faculties: arrayParamAjust(faculties), departments: arrayParamAjust(departments), page, pageSize });
}

export const getUniversityAllProfessors = (namePattern?: string, page?: number, pageSize?: number, faculties?: string[], departments?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetUniversityAllProfessorsGet({ namePattern, faculties: arrayParamAjust(faculties), departments: arrayParamAjust(departments), page, pageSize });
}

export const getAllAcademicUsersNonStudents = (namePattern?: string, page?: number, pageSize?: number, faculties?: string[], departments?: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUsersNonStudentsGet({ namePattern, faculties: arrayParamAjust(faculties), departments: arrayParamAjust(departments), page, pageSize });
}

export const getAllAcademicUsersByDepartment = (departmentId: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet({ departmentId });
}

export const getProfessorById = (id: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorByIdIdGet({ id });
}

export const getProfessorsById = (ids: string[]) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorsByIdGet({ ids });
}

export const getAdminById = (id: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetAdminByIdIdGet({ id });
}

export const getStudentById = (id: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetStudentByIdIdGet({ id });
}

export const updateProfessorInfo = async (id: string, academicUserUpdateDTO: AcademicUserUpdateDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateProfessorIdPut({ id, academicUserUpdateDTO });
}

export const updateAdminInfo = async (id: string, academicUserUpdateDTO: AcademicUserUpdateDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateAdminIdPut({ id, academicUserUpdateDTO });
}

export const updateStudentInfo = async (id: string, academicUserUpdateDTO: AcademicUserUpdateDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateStudentIdPut({ id, academicUserUpdateDTO });
}

export const getStudentFilters = (language: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetStudentFiltersGet({language});
}

export const getProfessorFilters = (language: string) => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetProfessorFiltersGet({language});
}

export const updateAcademicUserRole = async (id: string, academicUserUpdateRoleDTO: AcademicUserUpdateRoleDTO) => {
    await AuthAcademicUserApiFactory().apiAcademicUserUpdateAcademicUserRoleIdPut({ id, academicUserUpdateRoleDTO });
}

export const getRolesForUser = () => {
    return AuthAcademicUserApiFactory().apiAcademicUserGetRolesForUserGet();
}