/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DepartmentDTO,
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';
import {
    EducationTypeEnum,
    EducationTypeEnumFromJSON,
    EducationTypeEnumFromJSONTyped,
    EducationTypeEnumToJSON,
} from './EducationTypeEnum';
import {
    PlanSubjectDTO,
    PlanSubjectDTOFromJSON,
    PlanSubjectDTOFromJSONTyped,
    PlanSubjectDTOToJSON,
} from './PlanSubjectDTO';
import {
    ProgramTypeEnum,
    ProgramTypeEnumFromJSON,
    ProgramTypeEnumFromJSONTyped,
    ProgramTypeEnumToJSON,
} from './ProgramTypeEnum';
import {
    ProposalStatusEnum,
    ProposalStatusEnumFromJSON,
    ProposalStatusEnumFromJSONTyped,
    ProposalStatusEnumToJSON,
} from './ProposalStatusEnum';
import {
    SimpleViewAcademicUserDTO,
    SimpleViewAcademicUserDTOFromJSON,
    SimpleViewAcademicUserDTOFromJSONTyped,
    SimpleViewAcademicUserDTOToJSON,
} from './SimpleViewAcademicUserDTO';
import {
    StudyDomainEnum,
    StudyDomainEnumFromJSON,
    StudyDomainEnumFromJSONTyped,
    StudyDomainEnumToJSON,
} from './StudyDomainEnum';
import {
    TeachingLanguageEnum,
    TeachingLanguageEnumFromJSON,
    TeachingLanguageEnumFromJSONTyped,
    TeachingLanguageEnumToJSON,
} from './TeachingLanguageEnum';

/**
 * 
 * @export
 * @interface ProposalDTO
 */
export interface ProposalDTO {
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    readonly university?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    facultyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    departmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    name?: string | null;
    /**
     * 
     * @type {ProposalStatusEnum}
     * @memberof ProposalDTO
     */
    proposalStatus?: ProposalStatusEnum;
    /**
     * 
     * @type {SimpleViewAcademicUserDTO}
     * @memberof ProposalDTO
     */
    initiator?: SimpleViewAcademicUserDTO;
    /**
     * 
     * @type {Array<DepartmentDTO>}
     * @memberof ProposalDTO
     */
    partnerDepartments?: Array<DepartmentDTO> | null;
    /**
     * 
     * @type {Array<SimpleViewAcademicUserDTO>}
     * @memberof ProposalDTO
     */
    coordinators?: Array<SimpleViewAcademicUserDTO> | null;
    /**
     * 
     * @type {Array<SimpleViewAcademicUserDTO>}
     * @memberof ProposalDTO
     */
    teachingStaff?: Array<SimpleViewAcademicUserDTO> | null;
    /**
     * 
     * @type {StudyDomainEnum}
     * @memberof ProposalDTO
     */
    studyDomain?: StudyDomainEnum;
    /**
     * 
     * @type {ProgramTypeEnum}
     * @memberof ProposalDTO
     */
    programType?: ProgramTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    targetGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    extraConditions?: string | null;
    /**
     * 
     * @type {EducationTypeEnum}
     * @memberof ProposalDTO
     */
    educationType?: EducationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    programObjectives?: string | null;
    /**
     * 
     * @type {Array<PlanSubjectDTO>}
     * @memberof ProposalDTO
     */
    teachingPlan?: Array<PlanSubjectDTO> | null;
    /**
     * 
     * @type {number}
     * @memberof ProposalDTO
     */
    minimumParticipants?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalDTO
     */
    maximumParticipants?: number;
    /**
     * 
     * @type {TeachingLanguageEnum}
     * @memberof ProposalDTO
     */
    teachingLanguage?: TeachingLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    targetSkills?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    programLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    necessaryEquipment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProposalDTO
     */
    taxCuantum?: number;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    evaluationType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProposalDTO
     */
    isInitiator?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    planPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalDTO
     */
    cVsPath?: string | null;
}

export function ProposalDTOFromJSON(json: any): ProposalDTO {
    return ProposalDTOFromJSONTyped(json, false);
}

export function ProposalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'university': !exists(json, 'university') ? undefined : json['university'],
        'facultyName': !exists(json, 'facultyName') ? undefined : json['facultyName'],
        'departmentName': !exists(json, 'departmentName') ? undefined : json['departmentName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'proposalStatus': !exists(json, 'proposalStatus') ? undefined : ProposalStatusEnumFromJSON(json['proposalStatus']),
        'initiator': !exists(json, 'initiator') ? undefined : SimpleViewAcademicUserDTOFromJSON(json['initiator']),
        'partnerDepartments': !exists(json, 'partnerDepartments') ? undefined : (json['partnerDepartments'] === null ? null : (json['partnerDepartments'] as Array<any>).map(DepartmentDTOFromJSON)),
        'coordinators': !exists(json, 'coordinators') ? undefined : (json['coordinators'] === null ? null : (json['coordinators'] as Array<any>).map(SimpleViewAcademicUserDTOFromJSON)),
        'teachingStaff': !exists(json, 'teachingStaff') ? undefined : (json['teachingStaff'] === null ? null : (json['teachingStaff'] as Array<any>).map(SimpleViewAcademicUserDTOFromJSON)),
        'studyDomain': !exists(json, 'studyDomain') ? undefined : StudyDomainEnumFromJSON(json['studyDomain']),
        'programType': !exists(json, 'programType') ? undefined : ProgramTypeEnumFromJSON(json['programType']),
        'targetGroup': !exists(json, 'targetGroup') ? undefined : json['targetGroup'],
        'extraConditions': !exists(json, 'extraConditions') ? undefined : json['extraConditions'],
        'educationType': !exists(json, 'educationType') ? undefined : EducationTypeEnumFromJSON(json['educationType']),
        'programObjectives': !exists(json, 'programObjectives') ? undefined : json['programObjectives'],
        'teachingPlan': !exists(json, 'teachingPlan') ? undefined : (json['teachingPlan'] === null ? null : (json['teachingPlan'] as Array<any>).map(PlanSubjectDTOFromJSON)),
        'minimumParticipants': !exists(json, 'minimumParticipants') ? undefined : json['minimumParticipants'],
        'maximumParticipants': !exists(json, 'maximumParticipants') ? undefined : json['maximumParticipants'],
        'teachingLanguage': !exists(json, 'teachingLanguage') ? undefined : TeachingLanguageEnumFromJSON(json['teachingLanguage']),
        'targetSkills': !exists(json, 'targetSkills') ? undefined : json['targetSkills'],
        'programLocation': !exists(json, 'programLocation') ? undefined : json['programLocation'],
        'necessaryEquipment': !exists(json, 'necessaryEquipment') ? undefined : json['necessaryEquipment'],
        'taxCuantum': !exists(json, 'taxCuantum') ? undefined : json['taxCuantum'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : json['evaluationType'],
        'isInitiator': !exists(json, 'isInitiator') ? undefined : json['isInitiator'],
        'planPath': !exists(json, 'planPath') ? undefined : json['planPath'],
        'cVsPath': !exists(json, 'cVsPath') ? undefined : json['cVsPath'],
    };
}

export function ProposalDTOToJSON(value?: ProposalDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'facultyName': value.facultyName,
        'departmentName': value.departmentName,
        'name': value.name,
        'proposalStatus': ProposalStatusEnumToJSON(value.proposalStatus),
        'initiator': SimpleViewAcademicUserDTOToJSON(value.initiator),
        'partnerDepartments': value.partnerDepartments === undefined ? undefined : (value.partnerDepartments === null ? null : (value.partnerDepartments as Array<any>).map(DepartmentDTOToJSON)),
        'coordinators': value.coordinators === undefined ? undefined : (value.coordinators === null ? null : (value.coordinators as Array<any>).map(SimpleViewAcademicUserDTOToJSON)),
        'teachingStaff': value.teachingStaff === undefined ? undefined : (value.teachingStaff === null ? null : (value.teachingStaff as Array<any>).map(SimpleViewAcademicUserDTOToJSON)),
        'studyDomain': StudyDomainEnumToJSON(value.studyDomain),
        'programType': ProgramTypeEnumToJSON(value.programType),
        'targetGroup': value.targetGroup,
        'extraConditions': value.extraConditions,
        'educationType': EducationTypeEnumToJSON(value.educationType),
        'programObjectives': value.programObjectives,
        'teachingPlan': value.teachingPlan === undefined ? undefined : (value.teachingPlan === null ? null : (value.teachingPlan as Array<any>).map(PlanSubjectDTOToJSON)),
        'minimumParticipants': value.minimumParticipants,
        'maximumParticipants': value.maximumParticipants,
        'teachingLanguage': TeachingLanguageEnumToJSON(value.teachingLanguage),
        'targetSkills': value.targetSkills,
        'programLocation': value.programLocation,
        'necessaryEquipment': value.necessaryEquipment,
        'taxCuantum': value.taxCuantum,
        'evaluationType': value.evaluationType,
        'isInitiator': value.isInitiator,
        'planPath': value.planPath,
        'cVsPath': value.cVsPath,
    };
}

