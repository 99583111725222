/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationTypeEnum,
    EvaluationTypeEnumFromJSON,
    EvaluationTypeEnumFromJSONTyped,
    EvaluationTypeEnumToJSON,
} from './EvaluationTypeEnum';
import {
    PlanSemesterEnum,
    PlanSemesterEnumFromJSON,
    PlanSemesterEnumFromJSONTyped,
    PlanSemesterEnumToJSON,
} from './PlanSemesterEnum';
import {
    Proposal,
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface PlanSubject
 */
export interface PlanSubject {
    /**
     * 
     * @type {string}
     * @memberof PlanSubject
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof PlanSubject
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PlanSubject
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PlanSubject
     */
    name?: string | null;
    /**
     * 
     * @type {PlanSemesterEnum}
     * @memberof PlanSubject
     */
    semester?: PlanSemesterEnum;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    lectureHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    seminarHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    labHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    individualHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubject
     */
    readonly credits?: number;
    /**
     * 
     * @type {EvaluationTypeEnum}
     * @memberof PlanSubject
     */
    evaluationType?: EvaluationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanSubject
     */
    proposalId?: string;
    /**
     * 
     * @type {Proposal}
     * @memberof PlanSubject
     */
    proposal?: Proposal;
}

export function PlanSubjectFromJSON(json: any): PlanSubject {
    return PlanSubjectFromJSONTyped(json, false);
}

export function PlanSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'semester': !exists(json, 'semester') ? undefined : PlanSemesterEnumFromJSON(json['semester']),
        'lectureHours': !exists(json, 'lectureHours') ? undefined : json['lectureHours'],
        'seminarHours': !exists(json, 'seminarHours') ? undefined : json['seminarHours'],
        'labHours': !exists(json, 'labHours') ? undefined : json['labHours'],
        'individualHours': !exists(json, 'individualHours') ? undefined : json['individualHours'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : EvaluationTypeEnumFromJSON(json['evaluationType']),
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
    };
}

export function PlanSubjectToJSON(value?: PlanSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'semester': PlanSemesterEnumToJSON(value.semester),
        'lectureHours': value.lectureHours,
        'seminarHours': value.seminarHours,
        'labHours': value.labHours,
        'individualHours': value.individualHours,
        'evaluationType': EvaluationTypeEnumToJSON(value.evaluationType),
        'proposalId': value.proposalId,
        'proposal': ProposalToJSON(value.proposal),
    };
}

