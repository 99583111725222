/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PlanSubjectDTO,
    PlanSubjectDTOFromJSON,
    PlanSubjectDTOToJSON,
} from '../models';

export interface ApiTeachingPlanAddProposalIdPostRequest {
    proposalId: string;
    planSubjectDTO: Array<PlanSubjectDTO>;
}

export interface ApiTeachingPlanDeletePlanProposalIdDeleteRequest {
    proposalId: string;
}

export interface ApiTeachingPlanDeletePlanSubjectProposalIdDeleteRequest {
    planSubjectId: string;
    proposalId: string;
}

export interface ApiTeachingPlanEditProposalIdPutRequest {
    proposalId: string;
    planSubjectDTO: Array<PlanSubjectDTO>;
}

export interface ApiTeachingPlanGetTeachingPlanGetRequest {
    proposalId?: string;
    name?: string;
}

/**
 * 
 */
export class TeachingPlanApi extends runtime.BaseAPI {

    /**
     */
    async apiTeachingPlanAddProposalIdPostRaw(requestParameters: ApiTeachingPlanAddProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiTeachingPlanAddProposalIdPost.');
        }

        if (requestParameters.planSubjectDTO === null || requestParameters.planSubjectDTO === undefined) {
            throw new runtime.RequiredError('planSubjectDTO','Required parameter requestParameters.planSubjectDTO was null or undefined when calling apiTeachingPlanAddProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TeachingPlan/Add/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.planSubjectDTO.map(PlanSubjectDTOToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTeachingPlanAddProposalIdPost(requestParameters: ApiTeachingPlanAddProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTeachingPlanAddProposalIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTeachingPlanDeletePlanProposalIdDeleteRaw(requestParameters: ApiTeachingPlanDeletePlanProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiTeachingPlanDeletePlanProposalIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TeachingPlan/DeletePlan/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTeachingPlanDeletePlanProposalIdDelete(requestParameters: ApiTeachingPlanDeletePlanProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTeachingPlanDeletePlanProposalIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTeachingPlanDeletePlanSubjectProposalIdDeleteRaw(requestParameters: ApiTeachingPlanDeletePlanSubjectProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PlanSubjectDTO>> {
        if (requestParameters.planSubjectId === null || requestParameters.planSubjectId === undefined) {
            throw new runtime.RequiredError('planSubjectId','Required parameter requestParameters.planSubjectId was null or undefined when calling apiTeachingPlanDeletePlanSubjectProposalIdDelete.');
        }

        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiTeachingPlanDeletePlanSubjectProposalIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TeachingPlan/DeletePlanSubject/{proposalId}`.replace(`{${"planSubjectId"}}`, encodeURIComponent(String(requestParameters.planSubjectId))).replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanSubjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiTeachingPlanDeletePlanSubjectProposalIdDelete(requestParameters: ApiTeachingPlanDeletePlanSubjectProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<PlanSubjectDTO> {
        const response = await this.apiTeachingPlanDeletePlanSubjectProposalIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiTeachingPlanEditProposalIdPutRaw(requestParameters: ApiTeachingPlanEditProposalIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiTeachingPlanEditProposalIdPut.');
        }

        if (requestParameters.planSubjectDTO === null || requestParameters.planSubjectDTO === undefined) {
            throw new runtime.RequiredError('planSubjectDTO','Required parameter requestParameters.planSubjectDTO was null or undefined when calling apiTeachingPlanEditProposalIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TeachingPlan/Edit/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.planSubjectDTO.map(PlanSubjectDTOToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTeachingPlanEditProposalIdPut(requestParameters: ApiTeachingPlanEditProposalIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiTeachingPlanEditProposalIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiTeachingPlanGetTeachingPlanGetRaw(requestParameters: ApiTeachingPlanGetTeachingPlanGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PlanSubjectDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.proposalId !== undefined) {
            queryParameters['ProposalId'] = requestParameters.proposalId;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/TeachingPlan/GetTeachingPlan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanSubjectDTOFromJSON));
    }

    /**
     */
    async apiTeachingPlanGetTeachingPlanGet(requestParameters: ApiTeachingPlanGetTeachingPlanGetRequest = {}, initOverrides?: RequestInit): Promise<Array<PlanSubjectDTO>> {
        const response = await this.apiTeachingPlanGetTeachingPlanGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
