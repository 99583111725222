import { NOTIFICATION_TYPES, openNotification } from "../Components/Notifications/NotificationsUtils";

export enum MimeType {
    docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf = 'application/pdf'
}

export async function downloadFile(request: Promise<Blob>, fileName: string, type : string = '') {
    request.then(file => {
        if (type === '') {
            type = file.type.toString();
        }
        const blob = new Blob([file], {type} );
        const blobUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = blobUrl;
        a.download = fileName;
        a.click();
        a.remove();
        downloadSucceeded();

    })
    .catch(downloadFailed);
}

const downloadFailed = (_err: any) => {
    openNotification(
        'Eroare',
        'Descărcarea nu s-a realizat cu succes!',
        NOTIFICATION_TYPES.ERROR
    );
};

const downloadSucceeded = () => {
    openNotification(
        'Succes',
        'Descărcarea s-a realizat cu succes!',
        NOTIFICATION_TYPES.SUCCESS
    );
};