/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyUserAddUpdateDTO,
    CompanyUserAddUpdateDTOFromJSON,
    CompanyUserAddUpdateDTOToJSON,
    CompanyUserDTO,
    CompanyUserDTOFromJSON,
    CompanyUserDTOToJSON,
    CompanyUserDTOPagedResponse,
    CompanyUserDTOPagedResponseFromJSON,
    CompanyUserDTOPagedResponseToJSON,
} from '../models';

export interface ApiCompanyUsersAddPostRequest {
    companyUserAddUpdateDTO?: CompanyUserAddUpdateDTO;
}

export interface ApiCompanyUsersChangeRepresentativeUserIdPutRequest {
    id: string;
}

export interface ApiCompanyUsersDeleteIdDeleteRequest {
    id: string;
}

export interface ApiCompanyUsersGetAllGetRequest {
    namePattern?: string;
    page?: number;
    pageSize?: number;
}

export interface ApiCompanyUsersGetByIdIdGetRequest {
    id: string;
}

export interface ApiCompanyUsersUpdateIdPutRequest {
    id: string;
    companyUserAddUpdateDTO?: CompanyUserAddUpdateDTO;
}

/**
 * 
 */
export class CompanyUsersApi extends runtime.BaseAPI {

    /**
     */
    async apiCompanyUsersAddPostRaw(requestParameters: ApiCompanyUsersAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserAddUpdateDTOToJSON(requestParameters.companyUserAddUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersAddPost(requestParameters: ApiCompanyUsersAddPostRequest = {}, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersChangeRepresentativeUserIdPutRaw(requestParameters: ApiCompanyUsersChangeRepresentativeUserIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersChangeRepresentativeUserIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/ChangeRepresentativeUser/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersChangeRepresentativeUserIdPut(requestParameters: ApiCompanyUsersChangeRepresentativeUserIdPutRequest, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersChangeRepresentativeUserIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersDeleteIdDeleteRaw(requestParameters: ApiCompanyUsersDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersDeleteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCompanyUsersDeleteIdDelete(requestParameters: ApiCompanyUsersDeleteIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCompanyUsersDeleteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCompanyUsersGetAllGetRaw(requestParameters: ApiCompanyUsersGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersGetAllGet(requestParameters: ApiCompanyUsersGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<CompanyUserDTOPagedResponse> {
        const response = await this.apiCompanyUsersGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersGetByIdIdGetRaw(requestParameters: ApiCompanyUsersGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersGetByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/GetById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersGetByIdIdGet(requestParameters: ApiCompanyUsersGetByIdIdGetRequest, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersGetByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCompanyUsersUpdateIdPutRaw(requestParameters: ApiCompanyUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCompanyUsersUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/CompanyUsers/Update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyUserAddUpdateDTOToJSON(requestParameters.companyUserAddUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyUserDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiCompanyUsersUpdateIdPut(requestParameters: ApiCompanyUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<CompanyUserDTO> {
        const response = await this.apiCompanyUsersUpdateIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
