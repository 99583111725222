import { ProgramTypeEnum, ProposalApi, ProposalRequestDTO, StudyDomainEnum } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new ProposalApi(getAuthConfiguration());

export const getProposalPage = (name: string, domain: string, faculty: string, type: string, page: number, pageSize: number) => {
    return ApiFactory().apiProposalGetPageGet({ domain: StudyDomainEnum[domain as keyof typeof StudyDomainEnum], name, page, pageSize, faculty, type: ProgramTypeEnum[type as keyof typeof ProgramTypeEnum] });
}

export const getProposal = (proposalId: string) => {
    if (!proposalId) {
        return null;
    }
    return ApiFactory().apiProposalGetProposalIdGet({ proposalId });
}

export const addProposal = (proposalRequestDTO : ProposalRequestDTO) => {
    return ApiFactory().apiProposalAddPost( { proposalRequestDTO } )
}

export const editProposal = (proposalId: string, proposalRequestDTO : ProposalRequestDTO) => {
    return ApiFactory().apiProposalEditProposalIdPut( { proposalId, proposalRequestDTO } )
}

export const validateProposal = (proposalId: string) => {
    return ApiFactory().apiProposalValidateProposalIdPost({ proposalId });
}

export const deleteProposal = (proposalId: string) => {
    return ApiFactory().apiProposalDeleteProposalIdDelete({ proposalId });
}