import { PlanSubjectDTO, ProgramTypeEnum, ProposalRequestDTO, TeachingPlanApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new TeachingPlanApi(getAuthConfiguration());

// export const getProposalPage = (name: string, domain: string, faculty: string, type: string, page: number, pageSize: number) => {
//     return ApiFactory().apiProposalGetPageGet({ domain, name, page, pageSize, faculty, type: ProgramTypeEnum[type as keyof typeof ProgramTypeEnum] });
// }

export const getProposalPlan = (proposalId: string) => {
    if (!proposalId) {
        return null;
    }
    return ApiFactory().apiTeachingPlanGetTeachingPlanGet({ proposalId });
}

export const addProposalPlan = (proposalId: string, planSubjectDTO: PlanSubjectDTO[]) => {
    return ApiFactory().apiTeachingPlanAddProposalIdPost( { proposalId, planSubjectDTO } )
}

export const editProposalPlan = (proposalId: string, planSubjectDTO: PlanSubjectDTO[]) => {
    return ApiFactory().apiTeachingPlanEditProposalIdPut( { proposalId, planSubjectDTO } )
}