/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NotificationType {
    CompanyValidationNeeded = 'CompanyValidationNeeded',
    AccordSignatureNeeded = 'AccordSignatureNeeded',
    AccordFinalized = 'AccordFinalized',
    InternshipStudentApplication = 'InternshipStudentApplication',
    InternshipCompanyAcceptance = 'InternshipCompanyAcceptance',
    InternshipProfessorAcceptance = 'InternshipProfessorAcceptance',
    InternshipCompanyRejection = 'InternshipCompanyRejection',
    InternshipProfessorRejection = 'InternshipProfessorRejection',
    InternshipStudentAcceptance = 'InternshipStudentAcceptance',
    InternshipStudentRejection = 'InternshipStudentRejection',
    ConventionAcknowledgementNeeded = 'ConventionAcknowledgementNeeded',
    ConventionSignatureNeeded = 'ConventionSignatureNeeded',
    NotebookSignatureNeeded = 'NotebookSignatureNeeded',
    NotebookValidationNeeded = 'NotebookValidationNeeded',
    NotebookApproved = 'NotebookApproved',
    NotebookRejected = 'NotebookRejected',
    CertificateSignatureNeeded = 'CertificateSignatureNeeded',
    CertificateValidationNeeded = 'CertificateValidationNeeded',
    CertificateApproved = 'CertificateApproved',
    CertificateRejected = 'CertificateRejected'
}

export function NotificationTypeFromJSON(json: any): NotificationType {
    return NotificationTypeFromJSONTyped(json, false);
}

export function NotificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationType {
    return json as NotificationType;
}

export function NotificationTypeToJSON(value?: NotificationType | null): any {
    return value as any;
}

