/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProgramTypeEnum,
    ProgramTypeEnumFromJSON,
    ProgramTypeEnumToJSON,
    ProposalDTO,
    ProposalDTOFromJSON,
    ProposalDTOToJSON,
    ProposalDTOPagedResponse,
    ProposalDTOPagedResponseFromJSON,
    ProposalDTOPagedResponseToJSON,
    ProposalRequestDTO,
    ProposalRequestDTOFromJSON,
    ProposalRequestDTOToJSON,
    StudyDomainEnum,
    StudyDomainEnumFromJSON,
    StudyDomainEnumToJSON,
} from '../models';

export interface ApiProposalAddPostRequest {
    proposalRequestDTO?: ProposalRequestDTO;
}

export interface ApiProposalDeleteProposalIdDeleteRequest {
    proposalId: string;
}

export interface ApiProposalEditProposalIdPutRequest {
    proposalId: string;
    proposalRequestDTO?: ProposalRequestDTO;
}

export interface ApiProposalGetAllGetRequest {
    domain?: StudyDomainEnum;
    name?: string;
    faculty?: string;
    type?: ProgramTypeEnum;
}

export interface ApiProposalGetPageGetRequest {
    domain?: StudyDomainEnum;
    name?: string;
    faculty?: string;
    type?: ProgramTypeEnum;
    page?: number;
    pageSize?: number;
}

export interface ApiProposalGetProposalIdGetRequest {
    proposalId: string;
}

export interface ApiProposalValidateProposalIdPostRequest {
    proposalId: string;
}

/**
 * 
 */
export class ProposalApi extends runtime.BaseAPI {

    /**
     */
    async apiProposalAddPostRaw(requestParameters: ApiProposalAddPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProposalDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/Add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProposalRequestDTOToJSON(requestParameters.proposalRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiProposalAddPost(requestParameters: ApiProposalAddPostRequest = {}, initOverrides?: RequestInit): Promise<ProposalDTO> {
        const response = await this.apiProposalAddPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProposalDeleteProposalIdDeleteRaw(requestParameters: ApiProposalDeleteProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiProposalDeleteProposalIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/Delete/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProposalDeleteProposalIdDelete(requestParameters: ApiProposalDeleteProposalIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiProposalDeleteProposalIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiProposalEditProposalIdPutRaw(requestParameters: ApiProposalEditProposalIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProposalDTO>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiProposalEditProposalIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/Edit/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProposalRequestDTOToJSON(requestParameters.proposalRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiProposalEditProposalIdPut(requestParameters: ApiProposalEditProposalIdPutRequest, initOverrides?: RequestInit): Promise<ProposalDTO> {
        const response = await this.apiProposalEditProposalIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProposalGetAllGetRaw(requestParameters: ApiProposalGetAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProposalDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.faculty !== undefined) {
            queryParameters['Faculty'] = requestParameters.faculty;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProposalDTOFromJSON));
    }

    /**
     */
    async apiProposalGetAllGet(requestParameters: ApiProposalGetAllGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ProposalDTO>> {
        const response = await this.apiProposalGetAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProposalGetPageGetRaw(requestParameters: ApiProposalGetPageGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProposalDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.domain !== undefined) {
            queryParameters['Domain'] = requestParameters.domain;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.faculty !== undefined) {
            queryParameters['Faculty'] = requestParameters.faculty;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/GetPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiProposalGetPageGet(requestParameters: ApiProposalGetPageGetRequest = {}, initOverrides?: RequestInit): Promise<ProposalDTOPagedResponse> {
        const response = await this.apiProposalGetPageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProposalGetProposalIdGetRaw(requestParameters: ApiProposalGetProposalIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProposalDTO>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiProposalGetProposalIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/Get/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProposalDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiProposalGetProposalIdGet(requestParameters: ApiProposalGetProposalIdGetRequest, initOverrides?: RequestInit): Promise<ProposalDTO> {
        const response = await this.apiProposalGetProposalIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiProposalValidateProposalIdPostRaw(requestParameters: ApiProposalValidateProposalIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.proposalId === null || requestParameters.proposalId === undefined) {
            throw new runtime.RequiredError('proposalId','Required parameter requestParameters.proposalId was null or undefined when calling apiProposalValidateProposalIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Proposal/Validate/{proposalId}`.replace(`{${"proposalId"}}`, encodeURIComponent(String(requestParameters.proposalId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiProposalValidateProposalIdPost(requestParameters: ApiProposalValidateProposalIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiProposalValidateProposalIdPostRaw(requestParameters, initOverrides);
    }

}
