import { useState } from "react";
import styles from "./Notifications.module.scss";
import Layout from "../../Containers/Layout";
import CustomCard from "../../CustomComponents/CustomCard";
import {List, Row, Col, Tooltip} from 'antd';
import { theme } from "../../theme";
import CustomButton from "../../CustomComponents/CustomButton";
import { NotificationDTO, NotificationType } from "../../Api";
import { NOTIFICATION_TYPES, openNotification } from '../Notifications/NotificationsUtils';
import { getAllNotifications, readNotification } from '../../Requests/notification-requests';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useIsStudent } from "../../utils/utilFunctions";
import {useQuery, useQueryClient} from "react-query";

const Notifications = () => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const isStudent = useIsStudent();
    const queryClient = useQueryClient();

    const buildNotificationText = (notification: NotificationDTO) => {
        switch (notification.type) {
            case NotificationType.CompanyValidationNeeded://ok
                return `${t('notifications.company')} ${notification.issuerName} ${t('notifications.joined')} ${t('notifications.validationNeeded')}.`;
            case NotificationType.AccordSignatureNeeded://ok
                return `${t('notifications.generalAccord')} ${t('notifications.forCompany')} ${notification.issuerName} ${t('notifications.signatureNeeded')}.`;
            case NotificationType.AccordFinalized://ok
                return t('notifications.accordFinalized');
            case NotificationType.InternshipStudentApplication://ok prof //ok company
                return `${t('notifications.student')} ${notification.issuerName} ${t('notifications.applied')} ${t('notifications.internship')} ${notification.issuerAction}.`;
            case NotificationType.InternshipCompanyAcceptance://ok
                return `${t('notifications.company')} ${notification.issuerName} ${t('notifications.accepted')} ${t('notifications.application')} ${notification.issuerAction}.`;
            case NotificationType.InternshipProfessorAcceptance://ok
                return `${t('notifications.professor')} ${notification.issuerName} ${t('notifications.accepted')} ${t('notifications.application')} ${notification.issuerAction}.`;
            case NotificationType.InternshipCompanyRejection://ok
                return `${t('notifications.company')} ${notification.issuerName} ${t('notifications.rejected')} ${t('notifications.application')} ${notification.issuerAction}.`;
            case NotificationType.InternshipProfessorRejection://ok
                return `${t('notifications.professor')} ${notification.issuerName} ${t('notifications.rejected')} ${t('notifications.application')} ${notification.issuerAction}.`;
            case NotificationType.InternshipStudentAcceptance://ok prof //ok company
                return `${t('notifications.student')} ${notification.issuerName} ${t('notifications.accepted')} ${t('notifications.internship')} ${notification.issuerAction}.`;
            case NotificationType.InternshipStudentRejection://ok prof //ok company
                return `${t('notifications.student')} ${notification.issuerName} ${t('notifications.rejected')} ${t('notifications.internship')} ${notification.issuerAction}.`;
            case NotificationType.ConventionAcknowledgementNeeded://to test when feature is implemented
                return `${t('notifications.practiceConvention')} ${t('notifications.forStudentEnrollment')} ${notification.issuerName} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.agreementNeeded')}.`;
            case NotificationType.ConventionSignatureNeeded://to test when feature is implemented
                return isStudent ?
                    `${t('notifications.practiceConvention')} ${t('notifications.forYourEnrollment')} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.signatureNeeded')}.` :
                    `${t('notifications.practiceConvention')} ${t('notifications.forStudentEnrollment')} ${notification.issuerName} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.signatureNeeded')}.`;
            case NotificationType.NotebookSignatureNeeded://to test when feature is implemented
                return `${t('notifications.practiceNotebook')} ${t('notifications.forInternshipCompletion')} ${notification.issuerAction} ${t('notifications.byStudent')} ${notification.issuerName} ${t('notifications.signatureNeeded')}.`;
            case NotificationType.NotebookValidationNeeded://to test when feature is implemented
                return `${t('notifications.practiceNotebook')} ${t('notifications.forInternshipCompletion')} ${notification.issuerAction} ${t('notifications.byStudent')} ${notification.issuerName} ${t('notifications.validationNeeded')}.`;
            case NotificationType.NotebookApproved://ok
                return `${t('notifications.practiceNotebook')} ${t('notifications.forYourEnrollment')} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.approved')}. ${t('notifications.canDownload')}.`
            case NotificationType.NotebookRejected://ok
                return `${t('notifications.practiceNotebook')} ${t('notifications.forYourEnrollment')} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.rejected2')}. ${t('notifications.review')}.`
            case NotificationType.CertificateSignatureNeeded://to test when feature is implemented
                return `${t('notifications.practiceCertificate')} ${t('notifications.forInternshipCompletion')} ${notification.issuerAction} ${t('notifications.byStudent')} ${notification.issuerName} ${t('notifications.signatureNeeded')}.`;
            case NotificationType.CertificateValidationNeeded://to test when feature is implemented
                return `${t('notifications.practiceCertificate')} ${t('notifications.forInternshipCompletion')} ${notification.issuerAction} ${t('notifications.byStudent')} ${notification.issuerName} ${t('notifications.validationNeeded')}.`;
            case NotificationType.CertificateApproved://ok
                return `${t('notifications.practiceCertificate')} ${t('notifications.forYourEnrollment')} ${t('notifications.inInternship')} ${notification.issuerAction} ${t('notifications.approved')}. ${t('notifications.canDownload')}.`
            case NotificationType.CertificateRejected://ok
                return `${t('notifications.practiceCertificate')} ${t('notifications.forInternshipCompletion')} ${notification.issuerAction} ${t('notifications.byStudent')} ${notification.issuerName} ${t('notifications.rejected2')}. ${t('notifications.review')}.`
        }
    
        return '';
    }

    const openNotificationsErrorNotification = (ex: any) => {
        if (ex.status) {
            openNotification(
                t('notifications.error'),
                t('usersText.errorTexts.serverFailedDescription'), 
                NOTIFICATION_TYPES.ERROR
            );
        } else {
            openNotification(
                t('notifications.error'), 
                t('usersText.errorTexts.networkFailedDescription'), 
                NOTIFICATION_TYPES.ERROR
            );
        }
      };


    const { data: notifications, isLoading: loading } = useQuery(['getNotifications', current, pageSize],
        () => {
            return getAllNotifications(current, pageSize);
        }, {
            onError: openNotificationsErrorNotification
        });

    const handleListChange = (current: number, size: number) => {
        setCurrent(() => current);
        setPageSize(() => size);
    };

    const handleRead = async (id : string) => {
        await readNotification(id);
        queryClient.invalidateQueries('getNotifications');
        queryClient.invalidateQueries('getNotificationsNumber');
    };

    return (
        <Layout>
            <Row>
                <Col span={20} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <List
                        itemLayout="vertical"
                        size="small"
                        pagination={{
                            total: notifications?.totalCount,
                            current: current,
                            pageSize: pageSize,
                            pageSizeOptions: ["10", "20", "50"],
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            locale: { items_per_page: t('configuration.pagination') },
                            position: 'both',
                            hideOnSinglePage: true,
                            onChange: handleListChange
                        }}
                        loading={loading && !notifications}
                        dataSource={notifications?.data || []}
                        footer={
                            <div>
                            </div>
                        }
                        renderItem={(item) => (
                            <List.Item
                                className={styles.ListItem}
                                key={item.id}>
                                <CustomCard marginbottom ={"0px"} backgroundcolor={theme.lightGreen}>
                                    <Row style={{ alignItems: "center" }}>
                                        <Col span={22}>
                                            <span>
                                                <div className={styles.subtitle}>{buildNotificationText(item)}</div>
                                            </span>
                                        </Col>
                                        <Col span={2}>
                                            <Tooltip title={t('notifications.readAction')}>
                                                <CustomButton
                                                    textcolor={theme.green}
                                                    backgroundcolor={"transparent"}
                                                    paddingvertical={"10px"}
                                                    fontSize={"0.9rem"}
                                                    marginright={"5%"}
                                                    boxshadow={"none"}
                                                    onClick={() => handleRead(item.id ?? "")}
                                                >
                                                    <FontAwesomeIcon icon={solid('circle-check')} style={{ width: '1.2rem', height: '1.2rem'}}/>
                                                </CustomButton>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </CustomCard>
                            </List.Item>
                        )}/>
                </Col>
            </Row>
        </Layout>
    );
};

export default Notifications;
