import { useState, useRef } from "react";
import Layout from "../../Containers/Layout";
import styles from "./MyAccount.module.scss";
import { theme } from "../../theme";
import { Col, Form, Row, Select, Tooltip } from "antd";
import nophoto from "../../Media/nophoto.jpg";
import { useGetCompanyId, getUserId, useIsStudent, useIsProfessor, useIsAdmin, useIsCompany } from "../../utils/utilFunctions";
import { emailPattern, phonePattern, urlPattern, userSkills } from "../../utils/constants";
import CustomInput from "../../CustomComponents/CustomInput";
import CustomForm from "../../CustomComponents/CustomForm";
import { getProfessorById, getAdminById, getStudentById, updateProfessorInfo, updateAdminInfo, updateStudentInfo } from "../../Requests/academic-user-requests";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { getCompanyById, updateCompanyInfo } from "../../Requests/company-requests";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { studentIdSet } from "../Student/StudentSlice";
import CompanyUserTable from "./CompanyUserTable";
import DescriptionEditor from "../../CustomComponents/DescriptionEditor";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSelect from "../../CustomComponents/CustomSelect";
import { useQuery } from "react-query";
import { getDepartmentsForFaculty } from "../../Requests/department-requests";
import CustomButton from "../../CustomComponents/CustomButton";

const { Option } = Select;


const MyAccount = () => {
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({} as any)
    const dispatch = useDispatch();
    const [skills, setSkills] = useState<string[]>(new Array<string>());
    const [form] = Form.useForm<{
        phone: string
        linkedInUrl?: string | null,
        name?: string | null,
        email: string,
        domainOfActivity?: string | null,
        researchField?: string | null,
        skills?: string[] | null,
        description?: string | null,
        siteUrl?: string | null,
        cvUrl?: string | null,
        image?: string | null,
        department?: string,
        preferredLanguage?: string
    }>();
    const isStudent = useIsStudent();
    const isProfessor = useIsProfessor();
    const isAdmin = useIsAdmin();
    const isCompany = useIsCompany();
    const companyId = useGetCompanyId();

    const history = useHistory();
    const redirectToProfile = () => {
        dispatch(studentIdSet(getUserId()));
        isStudent ? history.push("/student") : history.push("/profil");
    };

    const openDataErrorNotification = (_error: any) => {
		openNotification(
            t('account.error'),
            t('account.fetchUserDataError'),
            NOTIFICATION_TYPES.ERROR
        );
	};

    const { data: departmentList } = useQuery(["getDepartmentsForFaculty", isProfessor, isStudent, isAdmin],  async () => (isStudent || isProfessor || isAdmin) ?
        await getDepartmentsForFaculty() : null, {
        onError: (err) => {
            openDataErrorNotification(err);
        },
        refetchOnWindowFocus: false
    });

    const { data: companyState } = useQuery(["getCompanyAccountInfo", isCompany, form],
        () => {
        if(isCompany)
            return getCompanyById(companyId);
        }, {
            onSuccess: (response) => {
                setState(response);
                if (response) {
                    if(isCompany) {
                        form.setFieldsValue(
                            {
                                ...form.getFieldsValue(),
                                name: response.name,
                                email: response.emailContact ? response.emailContact : '',
                                phone: response.phone ? response.phone : '',
                                domainOfActivity: response.domainOfActivity,
                                siteUrl: response.siteUrl,
                                linkedInUrl: response.linkedInUrl,
                                description: response.description,
                                image: response.logo,
                                preferredLanguage: response.preferredLanguage
                            });
                    }
                }
            },
            onError: openDataErrorNotification,
            refetchOnWindowFocus: false
    });

    const { data: studentState } = useQuery(["getStudentAccountInfo", departmentList, isStudent, form],
        () => {
            if(isStudent)
                return getStudentById(getUserId());
        }, {
            onSuccess: (response) => {
                if (response) {
                    setState(response);
                    if (isStudent) {
                        const responseSkills = response.userSkills?.map(x => x.skill!);
                        form.setFieldsValue(
                            {
                                ...form.getFieldsValue(),
                                name: response.name,
                                email: response.email || '',
                                phone: response.phone || '',
                                cvUrl: response.cvUrl,
                                siteUrl: response.gitUrl,
                                linkedInUrl: response.linkedinUrl,
                                description: response.description,
                                skills: responseSkills,
                                image: response.image,
                                department: departmentList?.find(d => d.departmentNameEn === response.departmentNameEn)?.id
                            }
                        )
                    }
                }
            },
            onError: openDataErrorNotification,
            refetchOnWindowFocus: false
        });

    const { data: professorState } = useQuery(["getProfessorAccountInfo", departmentList, isProfessor, form ],
        () => {
            if(isProfessor)
                return getProfessorById(getUserId());
        }, {
            onSuccess: (response) => {
                setState(response);
                if (response) {
                    if (isProfessor) {
                        form.setFieldsValue(
                            {
                                ...form.getFieldsValue(),
                                name: response.name,
                                email: response.email || '',
                                phone: response.phone || '',
                                linkedInUrl: response.linkedinUrl,
                                siteUrl: response.siteUrl,
                                researchField: response.researchField,
                                description: response.description,
                                image: response.image,
                                department: departmentList?.find(d => d.departmentNameEn == response.departmentNameEn)?.id
                            });
                    }
                }
            },
            onError: openDataErrorNotification,
            refetchOnWindowFocus: false
        });


    const { data: adminState } = useQuery(["getAdminAccountInfo", isAdmin, form],
        () => {
            if(isAdmin)
                return getAdminById(getUserId());
        }, {
            onSuccess: (response) => {
                setState(response);
                if (response) {
                    if (isAdmin) {
                        form.setFieldsValue(
                            {
                                ...form.getFieldsValue(),
                                name: response.name,
                                email: response.email || '',
                                phone: response.phone || '',
                                description: response.description,
                                image: response.image,
                                department: departmentList?.find(d => d.departmentNameEn == response.departmentNameEn)?.id
                            });
                    }
                }
            },
            onError: openDataErrorNotification,
            refetchOnWindowFocus: false
        });

    const saveChangesError = () => {
        openNotification(
            t('account.saveError'),
            t('account.saveChangesError'),
            NOTIFICATION_TYPES.ERROR
        );
    }
    
    const missingFieldsError = () => {
        openNotification(
            t('account.saveError'),
            t('account.missingFields'),
            NOTIFICATION_TYPES.ERROR
        );
    }
    
    const successMessage = () => {
        openNotification(
            t('account.success'),
            t('account.successSaveMessage'),
            NOTIFICATION_TYPES.SUCCESS
        );
    }

    const validateFields = () => {
        if (isStudent) {
            if (!state?.phone || !state?.email || !state?.cvUrl || !state?.description) {
                missingFieldsError();

                return false;
            } else if (!urlPattern.test(state?.cvUrl)) {
                openNotification(
                    t('account.saveError'),
                    t('account.invalidLink'),
                    NOTIFICATION_TYPES.ERROR
                );

                return false;
            }
        } else if (isCompany) {
            if (!state?.name || !state?.phone || !state?.emailContact || !state?.siteUrl || !state?.description || !state?.linkedInUrl) {
                missingFieldsError();

                return false;
            }
        } else if (isProfessor) {
            if (!state?.phone || !state?.email || !state?.description) {
                missingFieldsError();

                return false;
            }
        } else if (isAdmin) {
            if (!state.phone || !state.email) {
                missingFieldsError();

                return false;
            }
        }

        if (!!state?.phone && !phonePattern.test(state.phone)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidPhone'),
                NOTIFICATION_TYPES.ERROR
            );
            return false;
        } else if (!!state?.email && !emailPattern.test(state.email)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidEmail'),
                NOTIFICATION_TYPES.ERROR
            );
            return false;
        } else if (!!state?.siteUrl && !urlPattern.test(state.siteUrl)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidSiteLink'),
                NOTIFICATION_TYPES.ERROR
            );
            return false;
        } else if (!!state.linkedInUrl && !urlPattern.test(state.linkedInUrl)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidLink'),
                NOTIFICATION_TYPES.ERROR
            );
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if (!validateFields()) {
            return;
        }

        let promise: Promise<void> | null = null;
        if (isStudent) {
            promise = updateStudentInfo(getUserId(), {
                description: state?.description ?? '',
                phone: state?.phone ?? '',
                cvUrl: state?.cvUrl,
                gitUrl: state?.gitUrl,
                linkedinUrl: state?.linkedinUrl ?? '',
                image: state?.image,
                departmentId: form.getFieldValue("department"),
                skills
            });
        } else if (isCompany) {
            promise = updateCompanyInfo(companyId, {
                name: state?.name,
                phone: state?.phone ?? '',
                description: state?.description,
                emailContact: state?.emailContact,
                domainOfActivity: state?.domainOfActivity,
                siteUrl: state?.siteUrl,
                linkedInUrl: state?.linkedInUrl,
                logo: state?.logo
            });
        } else if (isProfessor) {
            promise = updateProfessorInfo(getUserId(), {
                description: state?.description ?? '',
                phone: state?.phone ?? '',
                linkedinUrl: state?.linkedinUrl ?? '',
                siteUrl: state?.siteUrl,
                researchField: state?.researchField,
                image: state?.image,
                departmentId: form.getFieldValue("department"),
            });
        } else if (isAdmin) {
            promise = updateAdminInfo(getUserId(), {
                description: state.description ?? '',
                phone: state.phone,
                image: state.image,
                departmentId: form.getFieldValue("department"),
            });
        }

        promise?.then(() => {
            successMessage();
            redirectToProfile();
        })?.catch(saveChangesError);
    }

    const handleChangeSkills = (value: any) => {
        setSkills(value);
    }

    const handleChange = (event: any, field: any) => {
        event.persist();
        setState({
            ...state,
            [field]: event.target.value,
        });
    };

    const imageUploader = useRef<any>();

    const handleChangeImage = (event: any) => {
        const reader = new FileReader();
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.size > 16384) {
                openNotification(
                    t('account.saveError'),
                    t('account.logoSizeLimitError'),
                    NOTIFICATION_TYPES.ERROR
                );
            } else {
                reader.onload = function (upload) {
                    setState({
                        ...state,
                        image: upload && upload.target ? upload.target.result as string : ''
                    })
                };
                reader.readAsDataURL(file);
            }
        }
    };

    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.tableofContents}>
                        <div className={styles.save}>
                            <div style={{marginBottom: "20px", marginTop: "-4px", marginRight: "10px"}}>
                                <CustomButton
                                    paddingvertical={"19px"}
                                    fontSize={"0.9rem"}
                                    boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
                                    onClick={history.goBack}
                                >
                                    {t('profile.backToInternships')}
                                </CustomButton>
                            </div>
                            <ConfirmationModal
                                nameButton={t('account.save')}
                                modalText={t('account.saveMessage')}
                                titleButton={''}
                                handleFunction={handleSave}
                                iconButton={null}
                                shapeButton={''}
                                typeButton={''}
                                disabledProp={false}
                                styleProp={{background: theme.secondColor, color: theme.white, marginRight: 'auto', marginTop: '0', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                            />
                        </div>
                        <div className={styles.firstColumn}>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleChangeImage}
                                ref={imageUploader}
                                style={{
                                    display: "none"
                                }} />
                            {
                                state && <div className={styles.image}>
                                    <img src={!isCompany ? (state.image ?? nophoto) : (state.logo ?? nophoto)} alt="Profile" />
                                </div>
                            }
                            <Tooltip title={t('account.changePicture')}>
                                <button
                                    className={styles.changeImage}
                                    onClick={_e => imageUploader.current && imageUploader.current.click()}>
                                    <FontAwesomeIcon icon={solid('camera')}/>
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                    <CustomForm form={form} layout={theme.layout} boxshadow="none" initialValues={state}>
                        <div className={styles.tableElements}>
                            <Row justify="space-between">
                                <Col span={!isCompany ? 24 : 12}>
                                    <Form.Item
                                        name="name"
                                        label={!isCompany ? t('account.name') + ':' : t('account.companyName') + ':'}
                                        rules={[
                                            {
                                                required: isCompany,
                                                whitespace: true,
                                            }
                                        ]}>
                                        <CustomInput
                                            disabled={!isCompany}
                                            className={styles.customInput}
                                            backgroundcolor={theme.white}
                                            onChange={(event: any) => handleChange(event, "name")} />
                                    </Form.Item>
                                </Col>
                                {isCompany && <Col span={11}>
                                    <Form.Item
                                        name="preferredLanguage"
                                        label={t('account.preferredLanguage') + ':'}
                                        rules={[
                                            {
                                                whitespace: true,
                                            }
                                        ]}>
                                        <CustomSelect
                                            className={styles.customInput}
                                        >
                                            <Option value="ro">{t('account.romanian')}</Option>
                                            <Option value="en">{t('account.english')}</Option>
                                        </CustomSelect>
                                    </Form.Item>
                                </Col>}
                            </Row>

                            <Row justify="space-between">
                                <Col span={!isCompany ? 7 : 12}>
                                    <Form.Item
                                        name="email"
                                        label={t('account.contactEmail') + ':'}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                            },
                                            {
                                                pattern: emailPattern,
                                                message: t('account.invalidEmail'),
                                            },
                                        ]}>
                                        <CustomInput
                                            disabled={!isCompany}
                                            className={styles.customInput}
                                            backgroundcolor={theme.white}
                                            onChange={(event: any) => handleChange(event, "email")} />
                                    </Form.Item>
                                </Col>

                                <Col span={!isCompany ? 7 : 11}>
                                    <Form.Item
                                        name="phone"
                                        label={t('account.phone') + ':'}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                            },
                                            {
                                                pattern: phonePattern,
                                                message: t('account.invalidPhone'),
                                            },
                                        ]}>
                                        <CustomInput
                                            className={styles.customInput}
                                            backgroundcolor={theme.white}
                                            onChange={(event: any) => handleChange(event, "phone")} />
                                    </Form.Item>
                                </Col>

                                {!isCompany && <Col span={7}>
                                    <Form.Item
                                        name="department"
                                        label={t('account.department') + ':'}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <CustomSelect
                                            allowClear
                                            className={styles.customInput}
                                            style={{ width: "100%", textAlign: "left", marginTop: "15px"}}
                                            placeholder={t('account.department')}
                                        >
                                            {departmentList?.map((department) => <Option key={department.id} value={department.id}>{i18n.language === 'en' ? department.departmentNameEn : department.departmentNameRo}</Option>)}
                                        </CustomSelect>
                                    </Form.Item>
                                </Col>}
                            </Row>

                            <Row justify="space-between">
                                {
                                    isStudent && <Col span={7}>
                                        <Form.Item
                                            name="cvUrl"
                                            label={t('account.cvUrl') + ':'}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                },
                                                {
                                                    pattern: urlPattern,
                                                    message: t('account.invalidLink'),
                                                },
                                            ]}>
                                            <CustomInput
                                                className={styles.customInput}
                                                backgroundcolor={theme.white}
                                                onChange={(event: any) => handleChange(event, "cvUrl")} />
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    isCompany && <Col span={7}>
                                    <Form.Item
                                        name="domainOfActivity"
                                        label={t('account.activityDomain') + ':'}>
                                        <CustomInput
                                            className={styles.customInput}
                                            backgroundcolor={theme.white}
                                            onChange={(event: any) => handleChange(event, "domainOfActivity")} />
                                    </Form.Item>
                                    </Col>
                                }
                                {
                                    isProfessor &&
                                    <>
                                        <Col span={12}>
                                            <Form.Item name="researchField" label={t('account.researchDomain') + ":"}>
                                                <CustomInput
                                                    className={styles.customInput}
                                                    backgroundcolor={theme.white}
                                                    onChange={(event: any) => handleChange(event, "researchField")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item name="siteUrl" label={t('account.siteLink') + ":"}>
                                                <CustomInput
                                                    className={styles.customInput}
                                                    backgroundcolor={theme.white}
                                                    onChange={(event: any) => handleChange(event, "siteUrl")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                {
                                    !isProfessor && !isAdmin &&
                                    <>
                                        <Col span={7}>
                                            <Form.Item
                                                name="siteUrl"
                                                label={(isStudent ? t('account.githubLink') : t('account.siteLink')) + ':'}
                                                rules={[
                                                    {
                                                        required: isCompany,
                                                        whitespace: true,
                                                    },
                                                    {
                                                        pattern: urlPattern,
                                                        message: t('account.invalidLink'),
                                                    }
                                                ]}>
                                                <CustomInput
                                                    className={styles.customInput}
                                                    backgroundcolor={theme.white}
                                                    onChange={(event: any) => handleChange(event, "siteUrl")} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7}>
                                            <Form.Item
                                                name="linkedInUrl"
                                                label={isStudent ? t('account.linkedinLink') + ':' : t('account.companyLinkedin') + ':'}
                                                rules={[
                                                    {
                                                        required: isCompany,
                                                        whitespace: true,
                                                    },
                                                    {
                                                        pattern: urlPattern,
                                                        message: t('account.invalidLink'),
                                                    }
                                                ]}>
                                                <CustomInput
                                                    className={styles.customInput}
                                                    backgroundcolor={theme.white}
                                                    onChange={(event: any) => handleChange(event, "linkedInUrl")} />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </div>
                    </CustomForm>
                </div>
                <CustomForm form={form} layout={theme.layout} boxshadow={"none"} initialValues={{ description: state?.description }}>
                    {
                        isStudent &&
                        <Form.Item name="skills" label={t('account.skills') + ':'}>
                            <Select
                                mode="tags"
                                allowClear
                                style={{ display: "inherit", borderRadius: "4px" }}
                                value={skills}
                                onChange={handleChangeSkills}>
                                {userSkills}
                            </Select>
                        </Form.Item>
                    }
                    <Form.Item
                        name='description'
                        label={t('account.description') + ':'}
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            }
                        ]}>
                        <DescriptionEditor description={state?.description}
                            onEditorChange={(newDescription: string) => setState({
                            ...state,
                            description: newDescription
                        })} />
                    </Form.Item>
                </CustomForm>
                {
                    isCompany && <CompanyUserTable />
                }
            </div>
        </Layout>
    );
}

export default MyAccount;
