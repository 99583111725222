/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PlanSemesterEnum {
    M = 'M',
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
    S4 = 'S4'
}

export function PlanSemesterEnumFromJSON(json: any): PlanSemesterEnum {
    return PlanSemesterEnumFromJSONTyped(json, false);
}

export function PlanSemesterEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanSemesterEnum {
    return json as PlanSemesterEnum;
}

export function PlanSemesterEnumToJSON(value?: PlanSemesterEnum | null): any {
    return value as any;
}

