/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';

/**
 * 
 * @export
 * @interface SimpleViewAcademicUserDTO
 */
export interface SimpleViewAcademicUserDTO {
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly cvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly linkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly gitUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly researchField?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly image?: string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly roles?: Array<Role> | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly departmentNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly departmentNameEn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly facultyNameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimpleViewAcademicUserDTO
     */
    readonly facultyNameEn?: string | null;
}

export function SimpleViewAcademicUserDTOFromJSON(json: any): SimpleViewAcademicUserDTO {
    return SimpleViewAcademicUserDTOFromJSONTyped(json, false);
}

export function SimpleViewAcademicUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleViewAcademicUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'cvUrl': !exists(json, 'cvUrl') ? undefined : json['cvUrl'],
        'linkedinUrl': !exists(json, 'linkedinUrl') ? undefined : json['linkedinUrl'],
        'gitUrl': !exists(json, 'gitUrl') ? undefined : json['gitUrl'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'researchField': !exists(json, 'researchField') ? undefined : json['researchField'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'departmentNameRo': !exists(json, 'departmentNameRo') ? undefined : json['departmentNameRo'],
        'departmentNameEn': !exists(json, 'departmentNameEn') ? undefined : json['departmentNameEn'],
        'facultyNameRo': !exists(json, 'facultyNameRo') ? undefined : json['facultyNameRo'],
        'facultyNameEn': !exists(json, 'facultyNameEn') ? undefined : json['facultyNameEn'],
    };
}

export function SimpleViewAcademicUserDTOToJSON(value?: SimpleViewAcademicUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

