/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProposalStatusEnum {
    Draft = 'Draft',
    DepartmentApproval = 'DepartmentApproval',
    FacultyApproval = 'FacultyApproval',
    QaApproval = 'QAApproval',
    SenateApproval = 'SenateApproval',
    RnppApproval = 'RNPPApproval',
    Finalized = 'Finalized',
    All = 'ALL'
}

export function ProposalStatusEnumFromJSON(json: any): ProposalStatusEnum {
    return ProposalStatusEnumFromJSONTyped(json, false);
}

export function ProposalStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalStatusEnum {
    return json as ProposalStatusEnum;
}

export function ProposalStatusEnumToJSON(value?: ProposalStatusEnum | null): any {
    return value as any;
}

