import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import i18n from "../../i18n";
import { InternshipCompetenceDTO, PlanSubjectDTO } from "../../Api";

const { Option } = Select;
const { List, Item } = Form;

const PlanForm = (props: { name: string, initialValue: PlanSubjectDTO[], showSemester: boolean, onChange: (key: number) => void }) => {
    const { t } = i18n;

    return <List initialValue={props.initialValue} name={props.name} >
        {(fields, { add, remove }) => (
            <div>
                <label style={{ marginBottom: "8px" }}>{'* Plan învățământ (fișele disciplinelor se vor încărca la pasul următor):'}</label>
                <p style={{textAlign:"left", fontWeight:900}}>Durata şi numǎrul de credite transferabile: x ore / y=x/25 ECTS</p>
                <p style={{textAlign:"left"}}>
                    IB1 - Program postuniversitar de educatie permanenta de iniţiere <b>0 - 5 ECTS</b>
                </p>
                <p style={{textAlign:"left"}}>
                    IB2 - Program postuniversitar de educatie permanenta de perfecţionare <b>5 - 15 ECTS</b>
                </p>
                <p style={{textAlign:"left"}}>
                    IB3 - Program postuniversitar de educatie permanenta de specializare <b>15 - 90 ECTS</b>
                </p>
                <p style={{textAlign:"left"}}>
                    IB4 - Program postuniversitar de educatie permanenta de calificare si recalificare <b>60 - 150 ECTS</b>
                </p>
                <p style={{textAlign:"left"}}>
                    IIC - Program conversie profesională a cadrelor didactice din învăţământul preuniversitar
                    <br/><b style={{marginLeft:"30px"}}>min. 3 semestre/90 ECTS</b>
                    <br/><b style={{marginLeft:"30px"}}>min. 4 semestre/120 ECTS</b>
                </p>
                {fields.map(({ key, name, ...restField }) => (
                    <Space
                        key={key}
                        style={{
                            display: 'flex',
                            marginBottom: 8,
                        }}
                        align="baseline"
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                        <Col xs={24} sm={24} md={24} lg={18} xl={12}>
                            <Item
                                {...restField}
                                name={[name, 'name']}
                                initialValue=''
                                rules={[
                                    {
                                        required: true,
                                        message: t('competences.missingField'),
                                    },
                                ]}
                            >
                                <Input placeholder='Denumire disciplină' />
                            </Item>
                        </Col>
                        {props.showSemester ? <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'semester']}
                            rules={[
                                {
                                    required: true,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder='Semestru'
                            >
                                <Option value='S1'>S1</Option>
                                <Option value='S2'>S2</Option>
                                <Option value='S3'>S3</Option>
                                <Option value='S4'>S4</Option>
                            </Select>
                        </Item>
                        </Col> : null
                        }
                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'lectureHours']}
                            initialValue=''
                            rules={[
                                {
                                    required: true,
                                    whitespace: false,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Input addonAfter='ore curs' onChange={() => props.onChange(name)} placeholder='Curs' />
                        </Item>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'seminarHours']}
                            initialValue=''
                            rules={[
                                {
                                    required: true,
                                    whitespace: false,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Input addonAfter='ore seminar' onChange={() => props.onChange(name)} placeholder='Seminar' />
                        </Item>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'labHours']}
                            initialValue=''
                            rules={[
                                {
                                    required: true,
                                    whitespace: false,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Input addonAfter='ore laborator' onChange={() => props.onChange(name)} placeholder='Laborator' />
                        </Item>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'individualHours']}
                            initialValue=''
                            rules={[
                                {
                                    required: true,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Input addonAfter='ore preg. ind.' onChange={() => props.onChange(name)} placeholder='P. individuală' />
                        </Item>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'credits']}
                            initialValue=''
                            rules={[
                                {
                                    required: true,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Input addonAfter='ECTS' disabled placeholder='Credite' />
                        </Item>
                        </Col>
                        <Col xs={22} sm={22} md={10} lg={6} xl={4}>
                        <Item
                            {...restField}
                            name={[name, 'evaluationType']}
                            rules={[
                                {
                                    required: true,
                                    message: t('competences.missingField'),
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder='Formă de evaluare'
                            >
                                <Option value='Exam'>Examen</Option>
                                <Option value='Verification'>Verificare</Option>
                            </Select>
                        </Item>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={1}>
                            {fields.length > 1 && <MinusCircleOutlined style={{marginTop: '8px'}} onClick={() => remove(name)} />}
                        </Col>
                        </Row>
                    </Space>
                ))}
                <Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Adaugă disciplină
                    </Button>
                </Item>
            </div>
        )}
    </List>
};

export default PlanForm;