import { CompanyUserAddUpdateDTO, CompanyUsersApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const CompanyUsersApiFactory = () => new CompanyUsersApi(getAuthConfiguration());

//de testat toate
export const addCompanyUsers = async (companyUserAddUpdateDTO: CompanyUserAddUpdateDTO) => {
    await CompanyUsersApiFactory().apiCompanyUsersAddPost({ companyUserAddUpdateDTO });
}

export const deleteCompanyUser = async (id: string) => {
    await CompanyUsersApiFactory().apiCompanyUsersDeleteIdDelete({ id });
}

export const updateCompanyUser = async (id: string, companyUserAddUpdateDTO: CompanyUserAddUpdateDTO) => {
    await CompanyUsersApiFactory().apiCompanyUsersUpdateIdPut({ id, companyUserAddUpdateDTO});
}

export const getAllCompanyUsers = (namePattern?: string, page?: number, pageSize?: number) => {
    return CompanyUsersApiFactory().apiCompanyUsersGetAllGet({ namePattern, page, pageSize });
}

export const getCompanyUserById = (id: string) => {
    return CompanyUsersApiFactory().apiCompanyUsersGetByIdIdGet({ id });
}

export const makeRepresentative = async (id: string) => {
    await CompanyUsersApiFactory().apiCompanyUsersChangeRepresentativeUserIdPut({ id });
}