/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeachingLanguageEnum {
    Ro = 'RO',
    En = 'EN',
    Fr = 'FR',
    De = 'DE'
}

export function TeachingLanguageEnumFromJSON(json: any): TeachingLanguageEnum {
    return TeachingLanguageEnumFromJSONTyped(json, false);
}

export function TeachingLanguageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeachingLanguageEnum {
    return json as TeachingLanguageEnum;
}

export function TeachingLanguageEnumToJSON(value?: TeachingLanguageEnum | null): any {
    return value as any;
}

