/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
} from './Company';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';
import {
    PasswordAction,
    PasswordActionFromJSON,
    PasswordActionFromJSONTyped,
    PasswordActionToJSON,
} from './PasswordAction';

/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    companyId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    isRepresentative?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    department?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof CompanyUser
     */
    company?: Company;
    /**
     * 
     * @type {Array<PasswordAction>}
     * @memberof CompanyUser
     */
    passwordActions?: Array<PasswordAction> | null;
    /**
     * 
     * @type {Array<CompanyInternship>}
     * @memberof CompanyUser
     */
    companyInternshipsAsTutor?: Array<CompanyInternship> | null;
}

export function CompanyUserFromJSON(json: any): CompanyUser {
    return CompanyUserFromJSONTyped(json, false);
}

export function CompanyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'isRepresentative': !exists(json, 'isRepresentative') ? undefined : json['isRepresentative'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'passwordActions': !exists(json, 'passwordActions') ? undefined : (json['passwordActions'] === null ? null : (json['passwordActions'] as Array<any>).map(PasswordActionFromJSON)),
        'companyInternshipsAsTutor': !exists(json, 'companyInternshipsAsTutor') ? undefined : (json['companyInternshipsAsTutor'] === null ? null : (json['companyInternshipsAsTutor'] as Array<any>).map(CompanyInternshipFromJSON)),
    };
}

export function CompanyUserToJSON(value?: CompanyUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'email': value.email,
        'password': value.password,
        'name': value.name,
        'companyId': value.companyId,
        'isRepresentative': value.isRepresentative,
        'function': value._function,
        'phone': value.phone,
        'department': value.department,
        'company': CompanyToJSON(value.company),
        'passwordActions': value.passwordActions === undefined ? undefined : (value.passwordActions === null ? null : (value.passwordActions as Array<any>).map(PasswordActionToJSON)),
        'companyInternshipsAsTutor': value.companyInternshipsAsTutor === undefined ? undefined : (value.companyInternshipsAsTutor === null ? null : (value.companyInternshipsAsTutor as Array<any>).map(CompanyInternshipToJSON)),
    };
}

