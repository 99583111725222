import {  useState } from "react";
import styles from "./Proposals.module.scss";
import { Button, Dropdown, Menu, Modal, Progress, Select, Steps, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { NOTIFICATION_TYPES, openNotification } from "../Components/Notifications/NotificationsUtils";
import { ProgramTypeEnum, ProposalDTO, ProposalStatusEnum } from "../Api";
import Search from 'antd/lib/input/Search';
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Layout from "../Containers/Layout";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteProposal, getProposalPage, validateProposal, getProposal } from "../Requests/proposal-requests";
import { ProgramTypeEnumConverter, ProposalStatusEnumConverter, StudyDomainEnumConverter } from "./EnumConverters";
import { DownloadOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useHistory  } from "react-router-dom";
import { downloadCVs, downloadDeptApproval, downloadFacultyApproval, downloadQAApproval, downloadRNPPApproval, downloadSenateApproval, downloadSubjectFiles, uploadCVs, uploadDeptApproval, uploadFacultyApproval, uploadQAApproval, uploadRNPPApproval, uploadSenateApproval, uploadSubjectFiles } from "../Requests/file-manager-requests";
import { downloadFile } from "../utils/downloadUtils";
import { theme } from "../theme";
import Dragger from "antd/lib/upload/Dragger";
import { generateProposal } from "../Requests/proposal-generator-requests";
import { generatePlan } from "../Requests/plan-generator-requests";
import { ActionKeyEnum, departmentApprovalItems, departmentApprovalItemsForAdmin, draftItems, editAction, facultyApprovalItems, facultyApprovalItemsForAdmins, finalizedItems, QAApprovalItems, QAApprovalItemsForAdmin, RNPPApprovalItems, RNPPApprovalItemsForAdmin, SenateApprovalItems, SenateApprovalItemsForAdmin } from "./ProposalActions";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useIsAdmin } from "../utils/utilFunctions";
import { saveAs } from 'file-saver';
import * as excel from 'exceljs';
const { Option } = Select;

interface PageResponse<T> {
    data?: T[] | null,
    totalCount?: number,
    page?: number,
    pageSize?: number
}

export type PageCallBack<T> = (currentPage: number, pageSize: number) => Promise<PageResponse<T> | null | undefined>;
export type ColumnType<T> = { title: string, key: any, dataIndex?: string, width?: any, render?: (text: string, record: T, index: number) => any };

const Proposals = () => {
    const { t } = useTranslation();
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermDomain, setSearchTermDomain] = useState('');
    const [searchTermType, setSearchTermType] = useState('');
    const [searchTermFaculty, setSearchTermFaculty] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrent] = useState(1);
    const history = useHistory();
    const isAdmin = useIsAdmin();

    const [modalProps, setModalProps] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        proposalId: '',
        uploadHandler: uploadCVs
    });

    const getActions = (record: ProposalDTO): ItemType[] | undefined => {
        switch(record.proposalStatus) {
            case ProposalStatusEnum.Draft:
                return draftItems;
            case ProposalStatusEnum.DepartmentApproval:
                return isAdmin ? (!record.isInitiator ? departmentApprovalItemsForAdmin : departmentApprovalItems!.concat(editAction)) : departmentApprovalItems;
            case ProposalStatusEnum.FacultyApproval:
                return isAdmin ? (!record.isInitiator ? facultyApprovalItemsForAdmins : facultyApprovalItems!.concat(editAction)) : facultyApprovalItems;
            case ProposalStatusEnum.QaApproval:
                return isAdmin ? QAApprovalItemsForAdmin : QAApprovalItems;
            case ProposalStatusEnum.SenateApproval:
                return isAdmin ? SenateApprovalItemsForAdmin : SenateApprovalItems;
            case ProposalStatusEnum.RnppApproval:
                return isAdmin ? RNPPApprovalItemsForAdmin : RNPPApprovalItems;
            case ProposalStatusEnum.Finalized:
                return finalizedItems;

        }
    }
    const [modalPropsDelete, setModalPropsDelete] = useState({
        isModalVisible: false,
        title: '',
        fileExtension: '',
        proposalId: '',
        uploadHandler: uploadCVs
    });

    const [modalPropsStatus, setModalPropsStatus] =
    useState<{  isModalVisible: boolean,
                title: string,
                proposal: ProposalDTO | undefined
            }>
    ({
        isModalVisible: false,
        title: '',
        proposal: undefined,
    });

    const handleShow = (proposalId: string, uploadHandler: any, fileExtension: string = 'zip') => {
        setModalProps(modalProps => ({...modalProps, isModalVisible: true, proposalId, uploadHandler, fileExtension}));
    }

    const handleClose = () => {
        console.log('aocolasd');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOk = () => {
        console.log('aoco');
        setModalProps(modalProps => ({...modalProps, isModalVisible: false}));

    }

    const handleShowDelete = (proposalId: string) => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: true, proposalId}));
    }

    const handleCloseDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
    }

    const handleOkDelete = () => {
        setModalPropsDelete(modalProps => ({...modalProps, isModalVisible: false}));
        deleteProposal((modalPropsDelete.proposalId))
                .then(() => {
                    deleteSucceded();
                    invalidateQuery();
                })
                .catch(deleteFailed);

    }

    const handleShowStatus = (proposal: ProposalDTO) => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: true, proposal}));
    }

    const handleCloseStatus = () => {
        setModalPropsStatus(modalPropsStatus => ({...modalPropsStatus, isModalVisible: false}));
    }

    const currentStatus = (proposal: ProposalDTO) => {
        if(proposal?.proposalStatus == ProposalStatusEnum.Draft && proposal.cVsPath !=null && proposal.planPath != null)
        {
            return 2;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.DepartmentApproval)
        {
            return 3;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.FacultyApproval)
        {
            return 4;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.QaApproval)
        {
            return 5;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.SenateApproval)
        {
            return 6;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.RnppApproval)
        {
            return 7;
        }
        else if(proposal?.proposalStatus == ProposalStatusEnum.Finalized)
        {
            return 8;
        }
        else
        {
            return 1;
        }
    }
  
    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrent(() => pagination.current ?? 1);
        setPageSize(() => pagination.pageSize ?? 10);
    };

    const uploadFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Încarcarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };
    
    
    const uploadSucceded = () => {
        handleClose();
        openNotification(
            'Succes',
            'Încarcarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
            );
        };
        
    const validationSucceded = () => {
        openNotification(
            'Succes',
            'Validarea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const validationFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Validarea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const deleteSucceded = () => {
        openNotification(
            'Succes',
            'Ștergerea s-a realizat cu succes!',
            NOTIFICATION_TYPES.SUCCESS
        );
    };

    const deleteFailed = (_err: any) => {
        openNotification(
            'Eroare',
            'Ștergerea nu s-a realizat cu succes!',
            NOTIFICATION_TYPES.ERROR
        );
    };

    const wrongFile = () => {
        openNotification(
            'Eroare',
            'Extensia fișierului selectat nu este permisă!',
            NOTIFICATION_TYPES.ERROR
        );
    };

  
    const upload = async (file: Blob | null) => {
        if (file) {
            await modalProps.uploadHandler(modalProps.proposalId, file).then(uploadSucceded);
        }
    }

    const queryClient = useQueryClient();

    const invalidateQuery = () => { queryClient.invalidateQueries('getProposalPage') };

    const openFetchError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Datele nu au putut fi aduse din server!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openPlanHoursError = (_error: any) => {
        openNotification(
            'Eroare!',
            'Numarul de ore din planul de invatamant nu este multiplu de 25!',
            NOTIFICATION_TYPES.ERROR
        );
    }

    const openRequiredFilesError = (_error: any, errorMessage: string) => {
        openNotification(
            'Eroare!',
            errorMessage,
            NOTIFICATION_TYPES.ERROR
        );
    }

    const validPlanHours = (record: ProposalDTO) => {
        var valid = true;
        console.log(record);
        
        record.teachingPlan?.forEach(e => {
            if (e.totalHours! % 25 != 0  && record.programType != ProgramTypeEnum.Ib1) {
                valid = false;
            }
        });
        return valid;
    }

    const validRequiredFiles = (record: ProposalDTO) => {
        var valid = true;
        
        if(record.planPath == null || record.cVsPath == null)
        {
            valid = false
        }
        return valid;
    }

    const { data, isLoading } = useQuery(['getProposalPage', searchTermName, searchTermDomain, searchTermType, searchTermFaculty, pageSize, currentPage],
        () => {
            return getProposalPage(searchTermName, searchTermDomain, searchTermFaculty, searchTermType, currentPage, pageSize);
        },
        {
            onError: openFetchError,
        }
    );

    const onMenuClick = (e: any, record: ProposalDTO) => {
        if (e.key == ActionKeyEnum.DownloadSubjectFiles)
        {
            downloadFile(downloadSubjectFiles(record.id!), "Fișe discipline - " + record.name);

            return;
        }
        else if (e.key == ActionKeyEnum.UploadSubjectFiles)
        {
            handleShow(record.id!, uploadSubjectFiles, 'zip');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadCVs)
        {
            downloadFile(downloadCVs(record.id!), "CV-uri - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadCVs)
        {
            handleShow(record.id!, uploadCVs, 'zip');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadDeptApproval)
        {
            downloadFile(downloadDeptApproval(record.id!), "Aviz departament - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadDeptApproval)
        {
            handleShow(record.id!, uploadDeptApproval, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadFacultyApproval)
        {
            downloadFile(downloadFacultyApproval(record.id!), "Aviz facultate - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadFacultyApproval)
        {
            handleShow(record.id!, uploadFacultyApproval, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadQaApproval)
        {
            downloadFile(downloadQAApproval(record.id!), "Aviz calitate - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadQAApproval)
        {
            handleShow(record.id!, uploadQAApproval, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadSenateApproval)
        {
            downloadFile(downloadSenateApproval(record.id!), "Hotărâre senat - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadSenateApproval)
        {
            handleShow(record.id!, uploadSenateApproval, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.DownloadRNPPApproval)
        {
            downloadFile(downloadRNPPApproval(record.id!), "Înscriere RNPP - " + record.name);
            return;
        }
        else if (e.key == ActionKeyEnum.UploadRNPPApproval)
        {
            handleShow(record.id!, uploadRNPPApproval, 'pdf');
            return;
        }
        else if (e.key == ActionKeyEnum.GeneratePlan)
        {
            validPlanHours(record)
                ? downloadFile(generatePlan(record.id!), "Plan învățământ - " + record.name) 
                : openPlanHoursError('');
            return;
        }
        else if (e.key == ActionKeyEnum.EditProposal)
        {
            history.push('/editare-propunere/' + record.id);
            return;
        }
        else if (e.key == ActionKeyEnum.DeleteProposal)
        {
            handleShowDelete(record.id!);
            return;
        }
        else if (e.key == ActionKeyEnum.GenerateProposal)
        {
            validPlanHours(record)
                ? downloadFile(generateProposal(record.id!), "Propunere - " + record.name) 
                : openPlanHoursError('');
            return;
        }
        else if (e.key == ActionKeyEnum.ValidateProposal)
        {
            var errorMessage = "";
            if(record.planPath == null && record.cVsPath == null)
            {
                errorMessage = 'Nu au fost incarcate "Fise discipline" si "CV-uri"!'
            }
            else if(record.planPath == null && record.cVsPath != null)
            {
                errorMessage = 'Nu au fost incarcate "Fise discipline"!'
            }else if(record.planPath != null && record.cVsPath == null)
            {
                errorMessage = 'Nu au fost incarcate "CV-uri"!'
            }
            !validPlanHours(record)
                ? openPlanHoursError('') 
                : !validRequiredFiles(record)
                ? openRequiredFilesError('', errorMessage) 
                : validateProposal(record.id!)
                    .then(() => {
                        validationSucceded();
                        invalidateQuery();
                    })
                    .catch(validationFailed);
            return;
        }
    };

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTermName} style={{ width: 200 }} />
    );

    const FilterByDomainInput = (
        <Select defaultValue="All" style={{ width: "250px" }} onChange={setSearchTermDomain}>
            <Option value="All">Toate Domeniile</Option>
            <Option value='MCti'>Calculatoare si tehnologia informţiei</Option>
            <Option value='MAuto'>Ingineria autovehiculelor</Option>
            <Option value='MMateriale'>Ingineria materialelor</Option>
            <Option value='MMediu'>Ingineria mediului</Option>
            <Option value='MAlimente'>Ingineria produselor alimentare</Option>
            <Option value='MSisteme'>Ingineria sistemelor</Option>
            <Option value='MTransporturi'>Ingineria transporturilor</Option>
            <Option value='MAerospatiala'>Inginerie aerospaţială</Option>
            <Option value='MChimica'>Inginerie chimică</Option>
            <Option value='MElectrica'>Inginerie electrică</Option>
            <Option value='MEtti'>Inginerie electronică, telecomunicaţii și tehnologii informaționale</Option>
            <Option value='MEnergetica'>Inginerie energetică</Option>
            <Option value='MIndustriala'>Inginerie industrială</Option>
            <Option value='MMecanica'>Inginerie mecanică</Option>
            <Option value='MManagement'>Inginerie şi management</Option>
            <Option value='MMecatronica'>Mecatronică şi robotică</Option>
            <Option value='MAplicate'>Ştiinţe inginereşti aplicate</Option>
            <Option value='MComunicare'>Stiințe ale comunicării</Option>
            <Option value='MRelatii'>Relații internaționale și studii europene</Option>
            <Option value='MEconomie'>Economie</Option>
            <Option value='MEducatie'>Științe ale educației</Option>
        </Select>
    );

    const FilterByTypeInput = (
        <Select defaultValue="All" style={{ width: "250px" }} onChange={setSearchTermType}>
            <Option value="All">Toate Programele</Option>
            <Option value="Ia1">A1 - formare şi dezvoltare profesională continuă</Option>
            <Option value="Ia2">A2 - perfectionare</Option>
            <Option value="Ib1">B1 - educatie permanenta de initiere</Option>
            <Option value="Ib2">B2 - educatie permanenta de perfectionare</Option>
            <Option value="Ib3">B3 - educatie permanenta de specializare</Option>
            <Option value="Ib4">B4 - educatie permanenta de calificare si recalificare</Option>
            <Option value="Iic">C - program conversie profesională</Option>
        </Select>
    );

    const FilterByFacultyInput = (
        <Search placeholder='Facultate' allowClear onSearch={setSearchTermFaculty} style={{ width: 200 }} />
    );

    
    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            render: (text: string, record: ProposalDTO, index: number) => {
                return record.name;
            }
        },
        {
            title: 'Coordonator',
            dataIndex: 'initiator.name',
            render: (text: string, record: ProposalDTO, index: number) => {
                return record.initiator?.name;
            }
        },
        {
            title: FilterByDomainInput,
            dataIndex: 'studyDomain',
            render: (text: string, record: ProposalDTO, index: number) => {
                return StudyDomainEnumConverter(record.studyDomain);
            }
        },
        {
            title: FilterByTypeInput,
            dataIndex: 'programType',
            render: (text: string, record: ProposalDTO, index: number) => {
                return ProgramTypeEnumConverter(record.programType);
            }
        },
        {
            title: 'ECTS',
            render: (text: string, record: ProposalDTO, index: number) => {
                return record?.teachingPlan?.map(s => s.credits!).reduce((partialSum: number, a: number) => partialSum + a, 0);
            }
        },
        {
            title: FilterByFacultyInput,
            dataIndex: 'facultyName',
            key: 'facultyName'
        },
        {
            title: 'Status',
            dataIndex: 'proposalStatus',
            
            render: (text: string, record: ProposalDTO, index: number) => {
                return <div onClick={() => handleShowStatus(record!)} style={{cursor: "pointer"}}>
                    {ProposalStatusEnumConverter(record.proposalStatus)}
                </div>
                
            }
        },
        {
            title: 'Acțiuni',
            width: '50px',
            render: (text: string, record: ProposalDTO, index: number) => 
                <Dropdown placement="bottomLeft"
                    overlay={
                        <Menu 
                        onClick={(e) => onMenuClick(e, record)}
                        items={getActions(record)} />
                    }
                >
                    <FontAwesomeIcon icon={solid('bars')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px", marginLeft: "13px"}}/>
                </Dropdown>
        }
    ];


    const reportColumns: ColumnType<ProposalDTO>[] = [
        {
            title: 'Denumire',
            key: 'name',
        },
        {
            title: 'Coordonator',
            key: 'initiatorName',
        },
        {
            title: 'Domeniu',
            key: 'studyDomain',
        },
        {
            title: 'Tip Program',
            key: 'programType',
        },
        {
            title: 'Facultate',
            key: 'facultyName'
        },
        {
            title: 'ECTS',
            key: 'credits'
        },
        {
            title: 'Status',
            key: 'proposalStatus',
        }
    ];

    const [progress, setProgress] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);

    const downloadCsv = async (columns: { header: string, key: string }[], objs?: any[] | null) => {
        if (objs === undefined || objs === null) {
            return;
        }
    
        objs.forEach(e => {
            e.initiatorName = e.initiator.name;
            e.programType = ProgramTypeEnumConverter(e.programType)
            e.studyDomain = StudyDomainEnumConverter(e.studyDomain)
            e.proposalStatus = ProposalStatusEnumConverter(e.proposalStatus)
            e.credits = e.teachingPlan.map((s: { credits: any; }) => s.credits).reduce((partialSum: number, a: number) => partialSum + a, 0);
        });

        const workbook = new excel.Workbook();
        const sheet = workbook.addWorksheet('Report');
        sheet.columns = columns;
        sheet.addRows(objs);
    
        const csv = await workbook.csv.writeBuffer({
            encoding: 'utf-16', formatterOptions: {
                delimiter: ',',
                quote: "\""
            }
        });
    
        saveAs(
            new Blob([csv], {
                type: 'text/csv'
            }),
            'Report.csv'
        );
    };
    
    const openErrorNotification = (error: string, message: string) => {
        openNotification(
            error,
            message,
            NOTIFICATION_TYPES.ERROR
        );
    }
    
    async function downloadAll<T>(getCall: PageCallBack<T>, setProgress: (progress: number) => void, onError: () => void) {
        let data = new Array<T>();
        let hasData = true;
        let pageIndex = 1;
        setProgress(0);
    
        do {
            const page = await getCall(pageIndex, 100).catch(onError);
    
            ++pageIndex;
    
            if (page?.data === undefined || page?.data === null) {
                onError();
    
                return;
            }
    
            data = data.concat(page.data);
    
            if ((pageIndex - 1) * 100 >= (page.totalCount ?? 0)) {
                hasData = false;
            }
    
            setProgress(hasData ? (Math.round((pageIndex - 1) * 100 / (page.totalCount ?? 1) * 100)) : 100)
        } while (hasData);
    
        return data;
    }

    const downloadReport = async () => {
        setDownloading(true);

        return downloadCsv(reportColumns.map(e => { return { header: e.title, key: e.key?.toString() ?? '' } }),
            await downloadAll((page, size) => getProposalPage(searchTermName, searchTermDomain, searchTermFaculty, searchTermType, page, size), (value) => {
                setDownloadError(false);
                setProgress(value)
            }, () => {
                setDownloadError(true);
                openErrorNotification("Eroare!", "Raportul nu a putut fi descărcat cu succes!")
            }));
    }

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Layout>
        <Modal
                visible={modalPropsDelete.isModalVisible}
                onOk={handleOkDelete}
                onCancel={handleCloseDelete}
                title="Stergere"
                width={"50%"}
                okText={t('account.confirm')}
                cancelText={t('account.cancel')}
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
                >
                    <div>
                        <Typography>Sigur doriti stergerea propunerii?</Typography>
                    </div>
            </Modal>
            <Modal
                visible={modalPropsStatus.isModalVisible}
                onOk={handleCloseStatus}
                onCancel={handleCloseStatus}
                title="Status propunere"
                //width={"50%"}
                okButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white } }}
                cancelButtonProps={{ style: { visibility: "hidden" } }}
                >
                    <div>
                        <Steps
                            direction="vertical"
                            current={currentStatus(modalPropsStatus.proposal!)}
                            items={[
                            {
                                title: 'Draft',
                            },
                            {
                                title: 'Încărcare CV-uri și Fișe Discipline',
                            },
                            {
                                title: 'Trimitere spre validare',
                            },
                            {
                                title: 'Încărcare aviz departament',
                            },
                            {
                                title: 'Încărcare aviz facultate',
                            },
                            {
                                title: 'Încărcare aviz Calitate (de către Administratori)',
                            },
                            {
                                title: 'Încărcare Hotărâre Senat (de către Administratori)',
                            },
                            {
                                title: 'Încărcare Înscriere RNPP (de către Administratori)',
                            },
                            {
                                title: 'Finalizat',
                            },
                            ]}
                        />
                    </div>
            </Modal>
            <Modal
                visible={modalProps.isModalVisible}
                closable={false}
                onOk={handleOk}
                onCancel={handleClose}
                title="Încărcare fișier"
                width={"50%"}
                cancelText='Anulare'
                cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
                okButtonProps={{ style: { display: 'none' } }}
                >
                    <div>
                    <Dragger
                        multiple={false}
                        beforeUpload={(uploaded) => { 
                            if (uploaded.name.includes(modalProps.fileExtension)) {
                                upload(uploaded).then(invalidateQuery).catch(uploadFailed);
                            }
                            else {
                                wrongFile();
                            }
                            return false; 
                        }}
                        showUploadList={false}
                    >
                        <p className="ant-upload-drag-icon" >
                            <InboxOutlined style={{color: theme.green}}/>
                        </p>
                        <p className="ant-upload-text">Dă click aici sau trage fișierul pe care vrei să îl încarci în acest chenar</p>
                        <p className="ant-upload-hint">
                            Extensii acceptate: {modalProps.fileExtension}
                        </p>
                    </Dragger>
                    </div>
            </Modal>
            {downloading && <div onClick={() => setDownloading(false)} style={{cursor: "pointer"}}>
                <Progress percent={progress} status={downloadError ? "exception" : undefined} />
            </div>}
            <Table
                className={styles.usersTable}
                dataSource={data?.data || []}
                pagination={{
                    total: data?.totalCount,
                    current: currentPage,
                    pageSize: data?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total: number, range: [number, number]) => {
                        return <div>
                                {isAdmin && <Tooltip title="Descărcare raport propuneri">
                                    <Button type="primary" icon={<DownloadOutlined />} style={{marginRight: 10}} onClick={downloadReport}>
                                        Descărcare raport
                                    </Button>
                                </Tooltip>}
                                <Tooltip title="Adaugă propunere">
                                    <Link to='/adaugare-propunere'>
                                        <Button type="primary" icon={<PlusOutlined />}>
                                            Adaugă propunere
                                        </Button>
                                    </Link>
                                </Tooltip>
                            </div>
                    },
                    hideOnSinglePage: false,
                    locale: { items_per_page: t('configuration.pagination')},
                    position: ['topRight', 'bottomRight']
                }}
                locale={{
                    emptyText: (
                        <span>
                            <h3>Nu există propuneri adăugate! Apasă butonul de mai jos pentru a adăuga o propunere nouă!</h3>
                            <Tooltip title="Adaugă propunere">
                                <Link to='/adaugare-propunere'>
                                    <Button type="primary" icon={<PlusOutlined />}>
                                        Adaugă propunere
                                    </Button>
                                </Link>
                            </Tooltip>
                        </span>
                      )
                }}
                columns={columns}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Layout>
    </div>
}

export default Proposals;
