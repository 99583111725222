import moment from "moment";
import { useQuery } from "react-query";
import { Role, SimpleViewAcademicUserDTO } from "../Api";
import { getRolesForUser } from "../Requests/academic-user-requests";

const jwt = require('jsonwebtoken');

export function getUserId(): any {
    return jwt.decode(localStorage.getItem('token'))?.sub;
}

export function useGetCompanyId(): any {
    return useRoles().data?.includes(Role.Company) ? jwt.decode(localStorage.getItem('token'))?.companyId : null;
}

export function getTokenExpire(): any {
    return jwt.decode(localStorage.getItem('token'))?.exp;
}

export function isUserLogged(): boolean {
    return localStorage.getItem('token') != null;
}

export function logout(isAcademic: boolean): void {
    localStorage.clear();

    // if (isAcademic) {
    //     window.location.href = `${process.env.REACT_APP_AUTH_API_URL}/auth?clientAction=logout&clientName=https://${process.env.REACT_APP_DOMAIN}/`;
    // }
};

export const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export default function useRoles() {
    return useQuery(["getRoles"], () => isUserLogged() ? getRolesForUser() : [], { refetchOnWindowFocus: false });
};

export const useIsProfessor = () => {
    return useRoles().data?.includes(Role.Professor);
};

export const useIsStudent = () => {
    return useRoles().data?.includes(Role.Student);
};

export const useIsCompany = () => {
    return useRoles().data?.includes(Role.Company);
};

export const useIsAdmin = () => {
    return useRoles().data?.includes(Role.Admin);
};

export const useIsFacultyAdmin = () => {
    return useRoles().data?.includes(Role.FacultyAdmin);
};

export const useIsInternshipAdmin = () => {
    return useRoles().data?.includes(Role.InternshipAdmin);
};

export const useIsDean = () => {
    return useRoles().data?.includes(Role.Dean);
};

export const useIsRector = () => {
    return useRoles().data?.includes(Role.Rector);
}

export const useIsUPBAcademicUser = () => {
    const { data } = useRoles();

    return data?.includes(Role.Rector) || data?.includes(Role.Dean)
        || data?.includes(Role.InternshipAdmin) || data?.includes(Role.Admin)
        || data?.includes(Role.FacultyAdmin) || data?.includes(Role.Professor)
        || data?.includes(Role.GeneralDirector) || data?.includes(Role.EconomicDirector)
        || data?.includes(Role.LegalCounselor) || data?.includes(Role.PreventiveFinancialControl);
}

export const useIsGeneralDirector = () => {
    return useRoles().data?.includes(Role.GeneralDirector);
}

export const useIsEconomicDirector = () => {
    return useRoles().data?.includes(Role.EconomicDirector);
}

export const useIsLegalCounselor = () => {
    return useRoles().data?.includes(Role.LegalCounselor);
}

export const useIsPreventiveFinancialControl = () => {
    return useRoles().data?.includes(Role.PreventiveFinancialControl);
}

export const useIsUniversityAccordResponsible = () => {
    return useRoles().data?.includes(Role.UniversityAccordResponsible);
}

export const getKey = (record: SimpleViewAcademicUserDTO) => {
    return record.id!;
}

export const getAllKeys = (filterItems: any[]) => {
    const keys: any[] = [];

    filterItems.forEach((filter) => {
        keys.push(filter.key);

        filter.children.forEach((filterItem: any) => {
            keys.push(filterItem.key.split("@")[1]);
        });
    });

    return keys;
};

export function geti18nLanguage(): any {
    return localStorage.getItem('i18nextLng');
}

export const addMonths = (date: Date, months: number): moment.Moment => {
    const newDate = moment(date);
    newDate.add(months, "months");

    return newDate;
};