import { useEffect, useState } from "react";
import Layout from "../../Containers/Layout";
import styles from "./ProposalForm.module.scss";
import { Form, DatePicker, Select, Radio, Col, Row, Spin } from "antd";
import CustomForm from "../../CustomComponents/CustomForm";
import { theme } from "../../theme";
import { Redirect, useParams } from "react-router-dom";
import {
  NOTIFICATION_TYPES,
  openNotification,
} from "../../Components/Notifications/NotificationsUtils";
import { getUserId, useIsProfessor } from "../../utils/utilFunctions";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { useTranslation } from "react-i18next";
import DescriptionEditor from "../../CustomComponents/DescriptionEditor";
import { useQuery } from "react-query";
import { EducationTypeEnum, PlanSubjectDTO, ProgramTypeEnum, StudyDomainEnum, TeachingLanguageEnum } from "../../Api";
import { getAllProfessors, getProfessorById, getProfessorsById, getUniversityAllProfessors } from "../../Requests/academic-user-requests";
import { addProposal, editProposal, getProposal } from "../../Requests/proposal-requests";
import PlanForm from "./PlanForm";
import { addProposalPlan, editProposalPlan, getProposalPlan } from "../../Requests/plan-requests";
import useDebounce from "../../Hooks/debounce";
import { getAllDepartments } from "../../Requests/department-requests";

const { RangePicker } = DatePicker;
const { Group } = Radio;
const { Option } = Select;
const dateFormatList = "YYYY-MM-DD";


const ProposalForm = (props: any) => {
  const { t, i18n } = useTranslation();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Datele nu au putut fi aduse din server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const currentUserId = getUserId();

  const [redirect, setRedirect] = useState(false);
  const [showSemester, setShowSemester] = useState(false);
  const [selectedProgramType, setSelectedProgramType] = useState(ProgramTypeEnum.Ia1);
  const [coordonatorName, setCoordonatorName] = useState('');
  const [departamentId, setDepartamentId] = useState("");
  const [coordinatorSearch, setCoordinatorSearch] = useState("");
  const debounceCoordinatorSearch = useDebounce(coordinatorSearch, 400);

  const [teachingStaffSearch, setTeachingStaffSearch] = useState("");
  const debounceTeachingStaffSearch = useDebounce(teachingStaffSearch, 400);


  const [departmentSearch, setDepartmentSearch] = useState("");
  const debounceDepartmentSearch = useDebounce(departmentSearch, 400);

  const [form] = Form.useForm<{
    department?: string | null,
    name: string | null,
    partnerDepartments: string[],
    coordinators: string[],
    teachingStaff: string[],
    studyDomain: StudyDomainEnum,
    programType: ProgramTypeEnum,
    targetGroup: string,
    extraConditions: string,
    educationType: EducationTypeEnum,
    programObjectives: string,
    minimumParticipants: number,
    maximumParticipants: number,
    teachingLanguage: TeachingLanguageEnum,
    targetSkills: string,
    programLocation: string,
    necessaryEquipment: string,
    taxCuantum: number,
    evaluationType: string,
    plan: PlanSubjectDTO[]
  }>();

  const onChange = (key: number) => {
    const fields = form.getFieldsValue();
    const { plan } = fields;
    
    Object.assign(plan[key], { credits: Math.floor((Number(plan[key].lectureHours!)
                                                  + Number(plan[key].labHours!)
                                                  + Number(plan[key].seminarHours!)
                                                  + Number(plan[key].individualHours!)) / 25) });
    form.setFieldsValue({ plan })
  }

  const { proposalId } = useParams<{ proposalId: string }>();
  const isProfessor = useIsProfessor();

  const handleCoordinatorSearch = (searchTerm: string) => {
    console.log(searchTerm);
    setCoordonatorName(searchTerm);
  }


  const { data: professorList } = useQuery(["getProfessors", coordonatorName], () => getAllProfessors(coordonatorName), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    refetchOnWindowFocus: false
  });

  useQuery(["getUserId", isProfessor], async () => isProfessor ? await getProfessorById(getUserId()) : null, {
    refetchOnWindowFocus: false,
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        setDepartamentId(data.departmentId!);
      }
    }
  });

  const { data: proposal } = useQuery(["getProposal", proposalId], () => getProposal(proposalId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
          name: data.name,
          partnerDepartments: data.partnerDepartments?.map(pd => pd.id),
          coordinators: data.coordinators?.map(c => c.id),
          teachingStaff: data.teachingStaff?.map(c => c.id),
          studyDomain: data.studyDomain ?? undefined,
          programType: data.programType,
          targetGroup: data.targetGroup ?? undefined,
          extraConditions: data.extraConditions ?? undefined,
          educationType: data.educationType,
          programObjectives: data.programObjectives ?? undefined,
          minimumParticipants: data.minimumParticipants ?? 0,
          maximumParticipants: data.maximumParticipants ?? 0,
          teachingLanguage: data.teachingLanguage,
          targetSkills: data.targetSkills ?? undefined,
          programLocation: data.programLocation ?? undefined,
          necessaryEquipment: data.necessaryEquipment ?? undefined,
          taxCuantum: data.taxCuantum,
          evaluationType: data.evaluationType ?? undefined
        });
        setShowSemester(data.programType === ProgramTypeEnum.Iic);
        setSelectedProgramType(data.programType!);

      }
    },
    refetchOnWindowFocus: false
  });

  const { data: proposalPlan } = useQuery(["getProposalPlan", proposalId], () => getProposalPlan(proposalId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        form.setFieldsValue({
          plan: data
        });
      }
    },
    refetchOnWindowFocus: false
  });

  const { data: coordinatorsSource, isLoading: isLoadingCoordinators } = useQuery(["getAllCoordinators", isProfessor, proposal, debounceCoordinatorSearch], async () => {
    if (proposal?.coordinators && debounceCoordinatorSearch === '') {
      let ids = proposal?.coordinators ? proposal.coordinators.map(c => c.id!) : [];
      return ids.length !== 0
      ?  getProfessorsById(ids).then(e => e.map(c => { return { id: c.id, name: c.name } }))
      : [];
    }

    return getUniversityAllProfessors(debounceCoordinatorSearch, 1, 25).then(e => e.data?.map(e => { return { id: e.id, name: e.name } }));
  }, {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    refetchOnWindowFocus: false
  });

  const { data: teachingStaffSource, isLoading: isLoadingTeachingStaff } = useQuery(["getAllTeachingStaff", isProfessor, proposal, debounceTeachingStaffSearch], async () => {
    if (proposal?.teachingStaff && debounceTeachingStaffSearch === '') {
      let ids = proposal?.teachingStaff ? proposal.teachingStaff.map(c => c.id!) : [];
      return ids.length !== 0
      ?  getProfessorsById(ids).then(e => e.map(c => { return { id: c.id, name: c.name } }))
      : [];
    }

    return getUniversityAllProfessors(debounceTeachingStaffSearch, 1, 25).then(e => e.data?.map(e => { return { id: e.id, name: e.name } }));
  }, {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    refetchOnWindowFocus: false
  });

  const { data: departmentSource, isLoading: isLoadingDepartments } = useQuery(["getAllDepartments", isProfessor, proposal, debounceDepartmentSearch], async () => {
    // if (proposal?.partnerDepartments && debounceDepartmentSearch === '') {
    //   let ids = proposal?.partnerDepartments ? proposal.partnerDepartments.map(c => c.id!) : [];
    //   return getProfessorsById(ids).then(e => e.map(c => { return { id: c.id, name: c.name } }));
    // }

    return getAllDepartments(debounceDepartmentSearch, 1, 25).then(e => e.data?.map(e => { return { id: e.id, name: e.departmentNameRo } }));
  }, {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    refetchOnWindowFocus: false
  });


  const openSaveErrorNotification = (error: any) => {
    if (error.status === 400) {
      openNotification(
        'Eroare!',
        'Datele completate nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      openNotification(
        'Eroare!',
        'Propunerea nu a putut fi salvată!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Salvarea s-a efectuat cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  }


  const handleSave = () => {
    const state = form.getFieldsValue();
    console.warn(state);
    
   //TODO validation
    if (state.name !== "") {
      let obj = {
        name: state.name,
        partnerDepartments: state.partnerDepartments,
        coordinators: state.coordinators,
        teachingStaff: state.teachingStaff,
        studyDomain: state.studyDomain,
        programType: state.programType,
        targetGroup: state.targetGroup,
        extraConditions: state.extraConditions,
        educationType: state.educationType,
        programObjectives: state.programObjectives,
        minimumParticipants: state.minimumParticipants,
        maximumParticipants: state.maximumParticipants,
        teachingLanguage: state.teachingLanguage,
        targetSkills: state.targetSkills,
        programLocation: state.programLocation,
        necessaryEquipment: state.necessaryEquipment,
        taxCuantum: state.taxCuantum,
        evaluationType: state.evaluationType
      };
      let plan = state.plan;
      (proposalId ? editProposal(proposalId, obj) : addProposal(obj))
      .then((proposal) => {
        (proposalId ? editProposalPlan(proposal.id!, plan) : addProposalPlan(proposal.id!, plan))
        .then(openSuccessNotificationAndRedirect)
        // .catch(openSaveErrorNotification);
        .catch((e) => console.error(e))
      })
      .catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Datele introduse nu sunt valide!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };
  

  if (redirect) {
    return <Redirect to="/propuneri" />;
  } else {
    return (
      <Layout>
        <div className={styles.container}>
          <CustomForm
            form={form}
            layout={theme.layout}
            action="/propuneri"
            boxshadow={"none"}
          >
            <div className={styles.firstLine}>    

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name='name'
                    label='Denumirea programului de studii:'
                    rules={[
                      {
                        required: true,
                        message: 'Denumirea programului de studii este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează denumirea programului de studii'
                      />
                  </Form.Item>
                </Col>
                
                {/* select departamente partenere */}
                <Col span={24}>
                  <Form.Item
                    label='Departamente partenere (în afară de departamentul inițiatorul propunerii):'
                    name='partnerDepartments'
                    style={{ fontStyle: "italic", marginTop: "-10px", marginBottom: "10px" }}>
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Completează departamentele partenere!'
                      filterOption={false}
                      onSearch={setDepartmentSearch}
                      notFoundContent={isLoadingDepartments ? <Spin size="small" /> : null}
                      >
                      {departmentSource?.filter((departament) => departament.id != departamentId).map((user) => 
                        <Option key={user.id} value={user.id}>{user.name}</Option>)}
                    </Select>

                  </Form.Item>
                </Col>

                {/* select coordonatori */}
                <Col span={24}>
                  <Form.Item
                    label='Coordonatori (în afară de inițiatorul propunerii):'
                    name='coordinators'
                    style={{ fontStyle: "italic", marginTop: "-10px", marginBottom: "10px" }}>
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Completează coordonatorii programului!'
                      filterOption={false}
                      onSearch={setCoordinatorSearch}
                      notFoundContent={isLoadingCoordinators ? <Spin size="small" /> : null}
                      >
                      {coordinatorsSource?.filter((user) => user.id != getUserId()).map((user) => 
                        <Option key={user.id} value={user.id}>{user.name}</Option>)}
                    </Select>

                  </Form.Item>
                </Col>

                {/* select domeniu de studii */}
                <Col span={8}>
                    <Form.Item
                      name='studyDomain'
                      // initialValue={ProgramTypeEnum.Ia1}
                      label='Domeniul de studiu:'
                      rules={[
                        {
                          required: true,
                          message: 'Domeniul de studiu este obligatoriu!',
                          whitespace: true,
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        style={{ width: "100%", textAlign: "left" }}
                        placeholder='Alege domeniul de studiu'
                      >
                        <Option value='M_CTI'>Calculatoare si tehnologia informţiei</Option>
                        <Option value='M_Auto'>Ingineria autovehiculelor</Option>
                        <Option value='M_Materiale'>Ingineria materialelor</Option>
                        <Option value='M_Mediu'>Ingineria mediului</Option>
                        <Option value='M_Alimente'>Ingineria produselor alimentare</Option>
                        <Option value='M_Sisteme'>Ingineria sistemelor</Option>
                        <Option value='M_Transporturi'>Ingineria transporturilor</Option>
                        <Option value='M_Aerospatiala'>Inginerie aerospaţială</Option>
                        <Option value='M_Chimica'>Inginerie chimică</Option>
                        <Option value='M_Electrica'>Inginerie electrică</Option>
                        <Option value='M_ETTI'>Inginerie electronică, telecomunicaţii și tehnologii informaționale</Option>
                        <Option value='M_Energetica'>Inginerie energetică</Option>
                        <Option value='M_Industriala'>Inginerie industrială</Option>
                        <Option value='M_Mecanica'>Inginerie mecanică</Option>
                        <Option value='M_Management'>Inginerie şi management</Option>
                        <Option value='M_Mecatronica'>Mecatronică şi robotică</Option>
                        <Option value='M_Aplicate'>Ştiinţe inginereşti aplicate</Option>
                        <Option value='M_Comunicare'>Stiințe ale comunicării</Option>
                        <Option value='M_Relatii'>Relații internaționale și studii europene</Option>
                        <Option value='M_Economie'>Economie</Option>
                        <Option value='M_Educatie'>Științe ale educației</Option>
                      </Select>
                    </Form.Item>
                </Col>

                {/* select tip program */}
                <Col span={8}>
                  <Form.Item
                    name='programType'
                    // initialValue={ProgramTypeEnum.Ia1}
                    label='Tipul programului:'
                    rules={[
                      {
                        required: true,
                        message: 'Tipul programului este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Alege tipul programului'
                      onChange={(value) => {
                        setShowSemester(value === ProgramTypeEnum.Iic)
                        setSelectedProgramType(value);
                      }}
                    >
                      <Option value={ProgramTypeEnum.Ia1}>A1 - formare şi dezvoltare profesională continuă</Option>
                      <Option value={ProgramTypeEnum.Ia2}>A2 - perfectionare</Option>
                      <Option value={ProgramTypeEnum.Ib1}>B1 - educatie permanenta de initiere</Option>
                      <Option value={ProgramTypeEnum.Ib2}>B2 - educatie permanenta de perfectionare</Option>
                      <Option value={ProgramTypeEnum.Ib3}>B3 - educatie permanenta de specializare</Option>
                      <Option value={ProgramTypeEnum.Ib4}>B4 - educatie permanenta de calificare si recalificare</Option>
                      <Option value={ProgramTypeEnum.Iic}>C - program conversie profesională</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* select forma invatamant */}
                <Col span={8}>
                  <Form.Item
                    name='educationType'
                    initialValue={EducationTypeEnum.F}
                    label='Forma de învățământ:'
                    rules={[
                      {
                        required: true,
                        message: 'Forma de învățământ este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Alege forma de învățământ'
                    >
                      <Option value={EducationTypeEnum.F}>Cu frecvenţǎ</Option>
                      <Option value={EducationTypeEnum.R}>Cu frecvenţǎ redusǎ</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* select personal de predate */}
                <Col span={24}>
                  <Form.Item
                    label='Personal de predare implicat (CV-urile se vor încărca la pasul următor):'
                    name='teachingStaff'
                    style={{ fontStyle: "italic", marginTop: "-10px", marginBottom: "10px" }}>
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Completează coordonatorii programului!'
                      filterOption={false}
                      onSearch={setTeachingStaffSearch}
                      notFoundContent={isLoadingTeachingStaff ? <Spin size="small" /> : null}
                      >
                      {teachingStaffSource?.map((user) => 
                        <Option key={user.id} value={user.id}>{user.name}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>

                {/* Plan de invatamant */}
                <Col span={24}>
                  {<PlanForm name="plan" initialValue={[{}]} showSemester={showSemester} onChange={onChange}/>}
                </Col>

                {/* Loc de desfasurare */}
                <Col span={24}>
                  <Form.Item
                    name='programLocation'
                    label='Locul de desǎșurare al Programului (facultate, departament, sala)'
                    rules={[
                      {
                        required: true,
                        message: 'Locul de desǎșurare al Programului (facultate, departament, sala) este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează locul de desǎșurare al Programului (facultate, departament, sala)'
                    />
                  </Form.Item>
                </Col>

                {/* Grup tinta */}
                <Col span={24}>
                  <Form.Item
                    name='targetGroup'
                    label='Grupul tintă căruia i se adresează:'
                    rules={[
                      {
                        required: true,
                        message: 'Acest câmp este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DescriptionEditor
                      onEditorChange={(targetGroup: string) => form.setFieldsValue({ ...form.getFieldsValue(), targetGroup })}
                    />
                  </Form.Item>
                </Col>

                {/* Conditii extra */}
                <Col span={24}>
                  <Form.Item
                    name='extraConditions'
                    label={<div>
                      {selectedProgramType === ProgramTypeEnum.Ia1
                      ? <div>Absolvenții care au finalizat cu diplomă cel puțin studiile universitare de licență sau echivalente.</div>
                      : selectedProgramType === ProgramTypeEnum.Ia2
                      ? <div>Absolvenţii învăţământului superior de scurtă durată cu diplomă de absolvire, cât şi absolvenţii învăţământului superior cu diplomă cel puțin de licență sau echivalentă;</div>
                      : selectedProgramType === ProgramTypeEnum.Ib1
                      ? <div>Absolvenți ai învățământului superior de scurtă durată cu diplomă de absolvire, cât şi absolvenți de studii universitare de licență sau echivalente din orice domeniu fundamental, pentru dobândirea de informații noi/ cunoștințe/ abilități-deprinderi/ competențe adaptate oricăror programe de formare.</div>
                      : selectedProgramType === ProgramTypeEnum.Ib2
                      ? <div>Absolvenți ai învățământului superior de scurtă durată cu diplomă de absolvire, cât și absolvenții învătământului superior cu diplomă de licență sau echivalentă, care doresc perfecționarea competențelor profesionale existente sau transversale conform ESCO.<br />Absolvenţi ai programelor postuniversitare de educaţie permanentǎ de iniţiere ȋn acelaşi domeniu de studii universitare cu cel al programului postuniversitar.</div>
                      : selectedProgramType === ProgramTypeEnum.Ib3
                      ? <div>Absolvenți ai învățământului superior de scurtă durată cu diplomă de absolvire, cât și absolvenții învătământului superior cu diplomă de licență sau echivalentă, care doresc să dobândească rezultate ale învățării în aceeași arie ocupațională/grupa de baza în care a absolvit programul de licenţǎ (vizează dobândirea competenţelor aferente unui întreg standard ocupațional de nivel 6 sau 7 conform CNC).<br />Absolvenţi ai programelor postuniversitare de educaţie permanentǎ de iniţiere ȋn acelaşi domeniu de studii universitare cu cel al programului postuniversitar.</div>
                      : selectedProgramType === ProgramTypeEnum.Ib4
                      ? <div>Absolvenți ai învățământului superior de scurtă durată  cu diplomă de absolvire, cât și absolvenții învătământului superior cu diplomă de licență sau echivalentă, care vor sa dobandească o noua ocupatie din aceeasi grupa minora din COR cu cea in care detine calificarea inițială.</div>
                      : selectedProgramType === ProgramTypeEnum.Iic
                      ? <div>Cadre didactice incadrate in sistemul de invatamant preuniversitar cu diploma de licenta sau echivalenta.</div>
                      : ''
                      }
                      <br/>
                      Condiții suplimentare pe care trebuie să le posede candidații pentru a fi eligibili:
                    </div>}
                  >
                    <DescriptionEditor
                      onEditorChange={(extraConditions: string) => form.setFieldsValue({ ...form.getFieldsValue(), extraConditions })}
                    />
                  </Form.Item>
                </Col>

                {/* Obiective program */}
                <Col span={24}>
                  <Form.Item
                    name='programObjectives'
                    label='Obiectivele programului:'
                    rules={[
                      {
                        required: true,
                        message: 'Acest câmp este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DescriptionEditor
                      onEditorChange={(programObjectives: string) => form.setFieldsValue({ ...form.getFieldsValue(), programObjectives })}
                    />
                  </Form.Item>
                </Col>

                {/* Dotǎrile, echipamentele şi materialele necesare formǎrii: */}
                <Col span={24}>
                  <Form.Item
                    name='necessaryEquipment'
                    label='Dotǎrile, echipamentele şi materialele necesare formǎrii:'
                    rules={[
                      {
                        required: true,
                        message: 'Acest câmp este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DescriptionEditor
                      onEditorChange={(necessaryEquipment: string) => form.setFieldsValue({ ...form.getFieldsValue(), necessaryEquipment })}
                    />
                  </Form.Item>
                </Col>

                {/* nr min part */}
                <Col span={6}>
                  <Form.Item
                    name='minimumParticipants'
                    label='Nr. min. de participanţi'
                    rules={[
                      {
                        required: true,
                        message: 'Numărul minim de participanţi este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Nr. min. de participanţi'
                      />
                  </Form.Item>
                </Col>

                {/* nr max part */}
                <Col span={6}>
                  <Form.Item
                    name='maximumParticipants'
                    label='Nr. max. de participanţi'
                    rules={[
                      {
                        required: true,
                        message: 'Numărul maxim de participanţi este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Nr. max de participanţi'
                      />
                  </Form.Item>
                </Col>
                
                {/* limba predare */}
                <Col span={6}>
                  <Form.Item
                    name='teachingLanguage'
                    initialValue={TeachingLanguageEnum.Ro}
                    label='Limba de predare:'
                    rules={[
                      {
                        required: true,
                        message: 'Limba de predare este obligatorie!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder='Alege limba de predare'
                    >
                      <Option value={TeachingLanguageEnum.Ro}>Română</Option>
                      <Option value={TeachingLanguageEnum.En}>Engleză</Option>
                      <Option value={TeachingLanguageEnum.Fr}>Franceză</Option>
                      <Option value={TeachingLanguageEnum.De}>Germană</Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Cuantum taxǎ: */}
                <Col span={6}>
                  <Form.Item
                    name='taxCuantum'
                    label='Cuantum taxǎ/cursant:'
                    rules={[
                      {
                        required: true,
                        message: 'Cuantumul taxei este obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                    >
                    <textarea
                      rows={1}
                      cols={100}
                      placeholder='Completează cuantumul taxei'
                      />
                  </Form.Item>
                </Col>

                {/* Ocupația/Grupa de bază/Competențe pentru care se organizează programul */}
                <Col span={24}>
                  <Form.Item
                    name='targetSkills'
                    label={<div>
                      {selectedProgramType === ProgramTypeEnum.Iic
                      ? 'Competențe pentru care se organizează programul pentru noi specializări şi/sau ocuparea de noi funcţii didactice, altele decât cele ocupate în baza formării iniţiale:'
                      : selectedProgramType === ProgramTypeEnum.Ib3 || selectedProgramType === ProgramTypeEnum.Ib4
                      ? 'Ocupația pentru care se organizează programul din grupa majoră 2 cuprinsă în COR/ESCO sau mai multe ocupații din grupa de bază din COR/ESCO:'
                      : 'Competențele cheie/transversale pentru care se organizează programul:'
                      }
                    </div>}
                    rules={[
                      {
                        required: true,
                        message: 'Câmp obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    
                    <DescriptionEditor
                      onEditorChange={(targetSkills: string) => form.setFieldsValue({ ...form.getFieldsValue(), targetSkills })}
                    />
                  </Form.Item>
                </Col>

                {/* Modalitatea de finalizare și evaluare */}
                <Col span={24}>
                  <Form.Item
                    name='evaluationType'
                    label='Modalitatea de finalizare și evaluare:'
                    rules={[
                      {
                        required: true,
                        message: 'Câmp obligatoriu!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <DescriptionEditor
                      onEditorChange={(evaluationType: string) => form.setFieldsValue({ ...form.getFieldsValue(), evaluationType })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={styles.secondLine}>
              <div className={styles.firstColumn}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={proposalId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>
          </CustomForm>
        </div>
      </Layout>
    );
  }
};

export default ProposalForm;
