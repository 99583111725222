import { createSlice } from '@reduxjs/toolkit'
import i18n from '../../i18n';

interface MailValidationState {
  loading: boolean,
  message: string
}

const name = 'mailValidation';

export const mailValidationSlice = createSlice({
  name,
  initialState: {
    loading: true,
    message: i18n.t('mailValidationText.wait')
  },
  reducers: {
    mailValidationRequestSucceeded: (state: MailValidationState) => {
      return {
        ...state,
        loading: false,
        message: i18n.t('mailValidationText.success'),
      };
    },
    mailValidationRequestConflict: (state: MailValidationState) => {
      return {
        ...state,
        loading: false,
        message: i18n.t('mailValidationText.errorExpire'),
      };
    },
    mailValidationRequestUnauthorized: (state: MailValidationState) => {
      return {
        ...state,
        loading: false,
        message: i18n.t('mailValidationText.errorUsed'),
      };
    },
    mailValidationRequestFailedOther: (state: MailValidationState) => {
      return {
        ...state,
        loading: false,
        message: i18n.t('mailValidationText.errorOther'),
      };
    }
  },
})

export const { mailValidationRequestConflict, mailValidationRequestFailedOther, mailValidationRequestSucceeded, mailValidationRequestUnauthorized } = mailValidationSlice.actions

export const selectLoading = (state: { mailValidation: MailValidationState }) => state.mailValidation.loading
export const selectMessage = (state: { mailValidation: MailValidationState }) => state.mailValidation.message

export default mailValidationSlice.reducer