import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../Components/Home/Home';
import CompanyInternships from '../Components/Internships/CompanyInternships';
import FacultyInternships from '../Components/Internships/FacultyInternships';
import StudentInternships from '../Components/Internships/StudentInternships';
import Signup from '../Components/Signup/Signup';
import Login from '../Components/Login/Login';
import Profile from '../Components/Profile/Profile';
import Account from '../Components/MyAccount/MyAccount';
import Internship from '../Components/InternshipInfo/InternshipInfo';
import Info from '../Components/Info/Info';
import AddInternship from '../Components/AddForm/AddInternshipForm';
import Terms from '../Components/Terms/Terms';
import Privacy from '../Components/Privacy/Privacy';
import Questions from '../Components/Questions/Questions';
import Notifications from '../Components/Notifications/Notifications';
import ReactivateAccount from '../Components/ReactivateAccount/ReactivateAccount';
import Trainees from '../Components/Trainees/Trainees';
import Students from '../Components/Students/Students';
import MailValidation from '../Components/MailValidation/MailValidation';
import Student from '../Components/Student/Student';
import UniversityAccords from '../Components/UniversityAccords/UniversityAccords';
import PracticeNotebook from '../Components/PracticeNotebooks/PracticeNotebook';
import PracticeCertificate from '../Components/PracticeCertificate/PracticeCertificate';
import Users from '../Components/Admin/Users';
import { useIsCompany } from "../utils/utilFunctions";
import Proposals from '../Proposals/Proposals';
import ProposalForm from '../Proposals/ProposalForm/ProposalForm';

const Router = () => {
  const isCompany = useIsCompany();

  return (
    // <BrowserRouter>
    //   <Switch>
    //     <Route exact path='/' component={Home} />
    //     <Route path='/stagii' component={isCompany ? CompanyInternships : FacultyInternships} />
    //     <Route path='/inregistrare' component={Signup} />
    //     <Route path='/autentificare' component={Login} />
    //     <Route path='/profil' component={Profile} />
    //     <Route path='/editare-stagiu/:internshipId' component={AddInternship} />
    //     <Route path='/editare-stagiu' component={AddInternship} />
    //     <Route path='/info' component={Info} />
    //     <Route path='/termeni' component={Terms} />
    //     <Route path='/intrebari-frecvente' component={Questions} />
    //     <Route path='/reactivare-cont' component={ReactivateAccount} />
    //     <Route path='/stagiu' component={Internship} />
    //     <Route path='/stagiile-mele' component={StudentInternships} />
    //     <Route path='/editare-caiet-practica' component={PracticeNotebook} />
    //     <Route path='/stagii-companii' component={CompanyInternships} />
    //     <Route path='/stagii-facultati' component={FacultyInternships} />
    //     <Route path='/politica&confidentialitate' component={Privacy} />
    //     <Route path='/contul-meu' component={Account} />
    //     <Route path='/notificari' component={Notifications} />
    //     <Route path='/stagiari' component={Trainees} />
    //     <Route path='/studenti' component={Students} />
    //     <Route path='/student' component={Student} />
    //     <Route path='/editare-atestat-practica' component={PracticeCertificate} />
    //     <Route path='/mail-de-validare/:validationToken' component={MailValidation} />
    //     <Route path='/acorduri-universitate' component={UniversityAccords}/>
    //     <Route path='/utilizatori' component={Users} />
    //     <Route path='/propuneri' component={Proposals} />
    //     <Route path='/editare-propunere/:proposalId' component={ProposalForm} />
    //     <Route path='/adaugare-propunere' component={ProposalForm} />
    //   </Switch>
    // </BrowserRouter>
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/autentificare' component={Login} />
        <Route path='/info' component={Info} />
        <Route path='/termeni' component={Terms} />
        <Route path='/intrebari-frecvente' component={Questions} />
        <Route path='/politica&confidentialitate' component={Privacy} />
        <Route path='/contul-meu' component={Account} />
        <Route path='/notificari' component={Notifications} />
        <Route path='/propuneri' component={Proposals} />
        <Route path='/editare-propunere/:proposalId' component={ProposalForm} />
        <Route path='/adaugare-propunere' component={ProposalForm} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;