import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExtendedViewStudentDTO, SkillEndorsement, StudentAppreciation, StudentAppreciationDTO } from '../../Api'

interface StudentState {
    loading: boolean,
    studentId?: string,
    internshipEnrollmentId?: string,
    student?: ExtendedViewStudentDTO,
    message?: string
}

const name = 'student';

export const studentSlice = createSlice({
    name,
    initialState: {
        loading: false
    },
    reducers: {
        studentIdSet: (state: StudentState, action: PayloadAction<string>) => {
            return {
                ...state,
                studentId: action.payload,
                loading: false,
                student: null,
                message: null
            };
        },
        studentInternshipEnrollmentIdSet: (state: StudentState, action: PayloadAction<string>) => {
            return {
                ...state,
                internshipEnrollmentId: action.payload,
                loading: false,
                student: null,
                message: null
            };
        },
        studentLoad: (state: StudentState) => {
            return {
                ...state,
                loading: true,
                message: null
            };
        },
        studentGetSucceeded: (state: StudentState, action: PayloadAction<ExtendedViewStudentDTO>) => {
            return {
                ...state,
                loading: false,
                student: action.payload,
                message: null
            };
        },
        studentAddSkillEndorsement: (state: StudentState, action: PayloadAction<SkillEndorsement>) => {
            if (state.student) {
                state.student.userSkills?.filter(x => x.id === action.payload.userSkillId)?.forEach(x => {
                    x.skillEndorsements?.push(action.payload);
                });
            }

            return state;
        },
        studentDeleteSkillEndorsement: (state: StudentState, action: PayloadAction<SkillEndorsement>) => {
            if (state.student) {
                state.student.userSkills?.filter(x => x.id === action.payload.userSkillId)?.forEach(x => {
                    x.skillEndorsements = x.skillEndorsements?.filter(y => y.id !== action.payload.id)
                });
            }

            return state;
        },
        studentAddAppreciation: (state: StudentState, action: PayloadAction<StudentAppreciationDTO>) => {
            if (state.student) {
                const result = action.payload;
                const newElement = {
                    id: result.id,
                    title: result.title,
                    message: result.message,
                    professor: {
                        id: result.professorId,
                        name: result.professorName,
                        image: result.professorImage
                    },
                    professorId: result.professorId
                };

                state.student.receivedStudentAppreciations?.unshift(newElement);
            }

            return state;
        },
        studentUpdateAppreciation: (state: StudentState, action: PayloadAction<StudentAppreciationDTO>) => {
            if (state.student) {
                const result = action.payload;
                const newElement = {
                    id: result.id,
                    title: result.title,
                    message: result.message,
                    professor: {
                        id: result.professorId,
                        name: result.professorName,
                        image: result.professorImage
                    },
                    professorId: result.professorId
                };

                const index = state.student.receivedStudentAppreciations?.findIndex(x => x.id === action.payload.id);

                if (index !== undefined && index >= 0) {
                    state.student.receivedStudentAppreciations?.splice(index, 1);
                }

                state.student.receivedStudentAppreciations?.unshift(newElement);
            }

            return state;
        },
        studentDeleteAppreciation: (state: StudentState, action: PayloadAction<StudentAppreciation>) => {
            if (state.student) {
                const index = state.student.receivedStudentAppreciations?.findIndex(x => x.id === action.payload.id);

                if (index !== undefined && index >= 0) {
                    state.student.receivedStudentAppreciations?.splice(index, 1);
                }
            }

            return state;
        },
    }
})

export const { studentIdSet, studentInternshipEnrollmentIdSet, studentLoad, studentGetSucceeded, studentAddSkillEndorsement, studentDeleteSkillEndorsement, studentAddAppreciation, studentUpdateAppreciation, studentDeleteAppreciation } = studentSlice.actions

export const selectLoading = (state: { student: StudentState }) => state.student.loading
export const selectStudent = (state: { student: StudentState }) => state.student.student
export const selectStudentId = (state: { student: StudentState }) => state.student.studentId
export const selectMessage = (state: { student: StudentState }) => state.student.studentId
export const selectStudentInternshipEnrollmentId = (state: { student: StudentState }) => state.student.internshipEnrollmentId

export default studentSlice.reducer