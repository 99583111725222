import {
    CompanyApi,
    CompanyAddDTO,
    CompanyLoginDTO,
    CompanyUpdateDTO,
    CompanyStateEnum
} from "../Api";
import { getAuthConfiguration } from "./configuration";
import {arrayParamAjust} from "../utils/dataUtils";

const AuthCompanyApiFactory = () => new CompanyApi(getAuthConfiguration());

export const fetchCompanyData = (cui: number) => {
    return AuthCompanyApiFactory().apiCompanyGetByCuiCuiGet({ cui });
}

export const registerCompany = async (companyAddDTO: CompanyAddDTO) => {
    await new CompanyApi().apiCompanyRegisterPost({ companyAddDTO });
}

export const loginCompany = async (companyLoginDTO: CompanyLoginDTO) => {
    return new CompanyApi().apiCompanyLoginPost({ companyLoginDTO });
}

export const getCompanyById = (id: string) => {
    return AuthCompanyApiFactory().apiCompanyGetByIdIdGet({ id });
}

export const rejectCompany = (id: string) => {
    return AuthCompanyApiFactory().apiCompanyRejectIdPut({ id });
}

export const approveCompany = (id: string) => {
    return AuthCompanyApiFactory().apiCompanyApproveIdPut({ id });
}

export const activateCompany = (id: string) => {
    return AuthCompanyApiFactory().apiCompanyActivateIdPut({ id });
}

export const getCompanies = (namePattern?: string, page?: number, pageSize?: number) => {
    return AuthCompanyApiFactory().apiCompanyGetAllGet({ namePattern, page, pageSize });
}

export const getCompaniesByState = (namePattern?: string, companyStates?: Array<string>, page?: number, pageSize?: number,) => {
    return AuthCompanyApiFactory().apiCompanyGetCompaniesByStateGet({ namePattern, page, pageSize,
        companyStates: arrayParamAjust(companyStates?.map(e => CompanyStateEnum[e as keyof typeof CompanyStateEnum]))
    });
}

export const updateCompanyInfo = async (id: string, companyUpdateDTO: CompanyUpdateDTO) => {
    await AuthCompanyApiFactory().apiCompanyUpdateIdPut({ id, companyUpdateDTO });
}

export const getCompaniesFilter = () => {
    return AuthCompanyApiFactory().apiCompanyGetCompanyStateFiltersGet();
}
