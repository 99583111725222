import Layout from '../../Containers/Layout';
import styles from './Home.module.scss';
import { useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import image1 from '../../Media/logoheader.svg';
import image2 from '../../Media/undraw_teaching_re_g7e3.svg';
import CustomButton from '../../CustomComponents/CustomButton';
import { theme } from '../../theme';
import { NOTIFICATION_TYPES, openNotification } from '../Notifications/NotificationsUtils';
import { useTranslation } from 'react-i18next';
import {isUserLogged, useIsAdmin, useIsCompany, useIsProfessor, useIsStudent} from "../../utils/utilFunctions";
import { useQueryClient } from 'react-query';


const Home = () => {
  const { t } = useTranslation();
  const [boolInfo, setBoolInfo] = useState(false);
  const [studentBool, setStudentBool] = useState(false);
  const [adminBool, setAdminBool] = useState(false);
  const search = useLocation().search;
  const isStudent = useIsStudent();
  const isCompany = useIsCompany();
  const isAdmin = useIsAdmin();
  const isProfessor = useIsProfessor();
  const queryClient = useQueryClient();

  const openExpire = () => openNotification(
    t('home.messages.sessionExpired'),
    t('home.messages.sessionExpiredMessage'),
    NOTIFICATION_TYPES.ERROR
  );

  useEffect(() => {
    if (!boolInfo) {
      const token = new URLSearchParams(search).get('token') as any;
      const expired = new URLSearchParams(search).get('expired') as any;

      if (expired) {
        queryClient.invalidateQueries("getRoles");
        openExpire();
      }

      //TO DO: complete fetch info for profile

      if (token) {
        localStorage.setItem('token', token);

        if (isStudent) {
          setStudentBool(true);
        }
        else if (isAdmin) {
          setAdminBool(true);
        }
        window.location.href = '/'
      } else {
        const error = new URLSearchParams(search).get('error');
        if (error) {
          openNotification(
            t('home.messages.loginFailure'), 
            t('home.messages.noAccount'), 
            NOTIFICATION_TYPES.ERROR
          );
        }
      }
      setBoolInfo(true);
    }
  }, [search, boolInfo, t]);

  if (adminBool) {
    return (<Redirect to='/stagii' />);
  }
  else if (studentBool) {
    return (<Redirect to='/stagii' />);
  }
  else {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <h1>{t('home.welcomeText')}</h1>
            {/* <div className={styles.fadeinimage}>
              <img alt={'img1'} src={image1} />
            </div> */}
            <h1 style={{fontSize: '25px', fontWeight: '1000', color: theme.primaryColor}}>PROPUNERI PROGRAME <br />POSTUNIVERSITARE</h1>

            {(isUserLogged() && isProfessor) ?
                <CustomButton
                  backgroundcolor={theme.primaryColor}
                  textcolor={theme.white}
                  fontSize={"1rem"}
                  paddingvertical={"1.2rem"}
                  paddinghorizontal={"1.4rem"}
                >
                  <Link to='/propuneri' >Către propunerile mele</Link>
                </CustomButton>
            : null}
          </div>
          <div>
            <img className={styles.svg} alt={'img2'} src={image2} />
          </div>
        </div>
      </Layout >
    );
  }
}
export default Home;