/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    EducationTypeEnum,
    EducationTypeEnumFromJSON,
    EducationTypeEnumFromJSONTyped,
    EducationTypeEnumToJSON,
} from './EducationTypeEnum';
import {
    PlanSubject,
    PlanSubjectFromJSON,
    PlanSubjectFromJSONTyped,
    PlanSubjectToJSON,
} from './PlanSubject';
import {
    ProgramTypeEnum,
    ProgramTypeEnumFromJSON,
    ProgramTypeEnumFromJSONTyped,
    ProgramTypeEnumToJSON,
} from './ProgramTypeEnum';
import {
    ProposalCoordinator,
    ProposalCoordinatorFromJSON,
    ProposalCoordinatorFromJSONTyped,
    ProposalCoordinatorToJSON,
} from './ProposalCoordinator';
import {
    ProposalDepartment,
    ProposalDepartmentFromJSON,
    ProposalDepartmentFromJSONTyped,
    ProposalDepartmentToJSON,
} from './ProposalDepartment';
import {
    ProposalStatusEnum,
    ProposalStatusEnumFromJSON,
    ProposalStatusEnumFromJSONTyped,
    ProposalStatusEnumToJSON,
} from './ProposalStatusEnum';
import {
    ProposalTeachingStaff,
    ProposalTeachingStaffFromJSON,
    ProposalTeachingStaffFromJSONTyped,
    ProposalTeachingStaffToJSON,
} from './ProposalTeachingStaff';
import {
    StudyDomainEnum,
    StudyDomainEnumFromJSON,
    StudyDomainEnumFromJSONTyped,
    StudyDomainEnumToJSON,
} from './StudyDomainEnum';
import {
    TeachingLanguageEnum,
    TeachingLanguageEnumFromJSON,
    TeachingLanguageEnumFromJSONTyped,
    TeachingLanguageEnumToJSON,
} from './TeachingLanguageEnum';

/**
 * 
 * @export
 * @interface Proposal
 */
export interface Proposal {
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Proposal
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Proposal
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    departmentId?: string;
    /**
     * 
     * @type {Department}
     * @memberof Proposal
     */
    department?: Department;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    initiatorId?: string;
    /**
     * 
     * @type {AcademicUser}
     * @memberof Proposal
     */
    initiator?: AcademicUser;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    name?: string | null;
    /**
     * 
     * @type {Array<ProposalCoordinator>}
     * @memberof Proposal
     */
    proposalCoordinators?: Array<ProposalCoordinator> | null;
    /**
     * 
     * @type {Array<ProposalTeachingStaff>}
     * @memberof Proposal
     */
    proposalTeachingStaff?: Array<ProposalTeachingStaff> | null;
    /**
     * 
     * @type {Array<ProposalDepartment>}
     * @memberof Proposal
     */
    partnerDepartments?: Array<ProposalDepartment> | null;
    /**
     * 
     * @type {ProposalStatusEnum}
     * @memberof Proposal
     */
    proposalStatus?: ProposalStatusEnum;
    /**
     * 
     * @type {StudyDomainEnum}
     * @memberof Proposal
     */
    studyDomain?: StudyDomainEnum;
    /**
     * 
     * @type {ProgramTypeEnum}
     * @memberof Proposal
     */
    programType?: ProgramTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    targetGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    extraConditions?: string | null;
    /**
     * 
     * @type {EducationTypeEnum}
     * @memberof Proposal
     */
    educationType?: EducationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    programObjectives?: string | null;
    /**
     * 
     * @type {Array<PlanSubject>}
     * @memberof Proposal
     */
    teachingPlan?: Array<PlanSubject> | null;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    minimumParticipants?: number;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    maximumParticipants?: number;
    /**
     * 
     * @type {TeachingLanguageEnum}
     * @memberof Proposal
     */
    teachingLanguage?: TeachingLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    targetSkills?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    programLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    necessaryEquipment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    taxCuantum?: number;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    evaluationType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    planPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    cVsPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    departmentApprovalPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    facultyApprovalPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    qaApprovalPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    senateApprovalPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    rnppApprovalPath?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Proposal
     */
    isInitiator?: boolean;
}

export function ProposalFromJSON(json: any): Proposal {
    return ProposalFromJSONTyped(json, false);
}

export function ProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Proposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'initiatorId': !exists(json, 'initiatorId') ? undefined : json['initiatorId'],
        'initiator': !exists(json, 'initiator') ? undefined : AcademicUserFromJSON(json['initiator']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'proposalCoordinators': !exists(json, 'proposalCoordinators') ? undefined : (json['proposalCoordinators'] === null ? null : (json['proposalCoordinators'] as Array<any>).map(ProposalCoordinatorFromJSON)),
        'proposalTeachingStaff': !exists(json, 'proposalTeachingStaff') ? undefined : (json['proposalTeachingStaff'] === null ? null : (json['proposalTeachingStaff'] as Array<any>).map(ProposalTeachingStaffFromJSON)),
        'partnerDepartments': !exists(json, 'partnerDepartments') ? undefined : (json['partnerDepartments'] === null ? null : (json['partnerDepartments'] as Array<any>).map(ProposalDepartmentFromJSON)),
        'proposalStatus': !exists(json, 'proposalStatus') ? undefined : ProposalStatusEnumFromJSON(json['proposalStatus']),
        'studyDomain': !exists(json, 'studyDomain') ? undefined : StudyDomainEnumFromJSON(json['studyDomain']),
        'programType': !exists(json, 'programType') ? undefined : ProgramTypeEnumFromJSON(json['programType']),
        'targetGroup': !exists(json, 'targetGroup') ? undefined : json['targetGroup'],
        'extraConditions': !exists(json, 'extraConditions') ? undefined : json['extraConditions'],
        'educationType': !exists(json, 'educationType') ? undefined : EducationTypeEnumFromJSON(json['educationType']),
        'programObjectives': !exists(json, 'programObjectives') ? undefined : json['programObjectives'],
        'teachingPlan': !exists(json, 'teachingPlan') ? undefined : (json['teachingPlan'] === null ? null : (json['teachingPlan'] as Array<any>).map(PlanSubjectFromJSON)),
        'minimumParticipants': !exists(json, 'minimumParticipants') ? undefined : json['minimumParticipants'],
        'maximumParticipants': !exists(json, 'maximumParticipants') ? undefined : json['maximumParticipants'],
        'teachingLanguage': !exists(json, 'teachingLanguage') ? undefined : TeachingLanguageEnumFromJSON(json['teachingLanguage']),
        'targetSkills': !exists(json, 'targetSkills') ? undefined : json['targetSkills'],
        'programLocation': !exists(json, 'programLocation') ? undefined : json['programLocation'],
        'necessaryEquipment': !exists(json, 'necessaryEquipment') ? undefined : json['necessaryEquipment'],
        'taxCuantum': !exists(json, 'taxCuantum') ? undefined : json['taxCuantum'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : json['evaluationType'],
        'planPath': !exists(json, 'planPath') ? undefined : json['planPath'],
        'cVsPath': !exists(json, 'cVsPath') ? undefined : json['cVsPath'],
        'departmentApprovalPath': !exists(json, 'departmentApprovalPath') ? undefined : json['departmentApprovalPath'],
        'facultyApprovalPath': !exists(json, 'facultyApprovalPath') ? undefined : json['facultyApprovalPath'],
        'qaApprovalPath': !exists(json, 'qaApprovalPath') ? undefined : json['qaApprovalPath'],
        'senateApprovalPath': !exists(json, 'senateApprovalPath') ? undefined : json['senateApprovalPath'],
        'rnppApprovalPath': !exists(json, 'rnppApprovalPath') ? undefined : json['rnppApprovalPath'],
        'isInitiator': !exists(json, 'isInitiator') ? undefined : json['isInitiator'],
    };
}

export function ProposalToJSON(value?: Proposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'departmentId': value.departmentId,
        'department': DepartmentToJSON(value.department),
        'initiatorId': value.initiatorId,
        'initiator': AcademicUserToJSON(value.initiator),
        'name': value.name,
        'proposalCoordinators': value.proposalCoordinators === undefined ? undefined : (value.proposalCoordinators === null ? null : (value.proposalCoordinators as Array<any>).map(ProposalCoordinatorToJSON)),
        'proposalTeachingStaff': value.proposalTeachingStaff === undefined ? undefined : (value.proposalTeachingStaff === null ? null : (value.proposalTeachingStaff as Array<any>).map(ProposalTeachingStaffToJSON)),
        'partnerDepartments': value.partnerDepartments === undefined ? undefined : (value.partnerDepartments === null ? null : (value.partnerDepartments as Array<any>).map(ProposalDepartmentToJSON)),
        'proposalStatus': ProposalStatusEnumToJSON(value.proposalStatus),
        'studyDomain': StudyDomainEnumToJSON(value.studyDomain),
        'programType': ProgramTypeEnumToJSON(value.programType),
        'targetGroup': value.targetGroup,
        'extraConditions': value.extraConditions,
        'educationType': EducationTypeEnumToJSON(value.educationType),
        'programObjectives': value.programObjectives,
        'teachingPlan': value.teachingPlan === undefined ? undefined : (value.teachingPlan === null ? null : (value.teachingPlan as Array<any>).map(PlanSubjectToJSON)),
        'minimumParticipants': value.minimumParticipants,
        'maximumParticipants': value.maximumParticipants,
        'teachingLanguage': TeachingLanguageEnumToJSON(value.teachingLanguage),
        'targetSkills': value.targetSkills,
        'programLocation': value.programLocation,
        'necessaryEquipment': value.necessaryEquipment,
        'taxCuantum': value.taxCuantum,
        'evaluationType': value.evaluationType,
        'planPath': value.planPath,
        'cVsPath': value.cVsPath,
        'departmentApprovalPath': value.departmentApprovalPath,
        'facultyApprovalPath': value.facultyApprovalPath,
        'qaApprovalPath': value.qaApprovalPath,
        'senateApprovalPath': value.senateApprovalPath,
        'rnppApprovalPath': value.rnppApprovalPath,
        'isInitiator': value.isInitiator,
    };
}

