import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuProps, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";

    export enum ActionKeyEnum {
        UploadSubjectFiles,
        DownloadSubjectFiles,
        UploadCVs,
        DownloadCVs,
        UploadDeptApproval,
        DownloadDeptApproval,
        UploadFacultyApproval,
        DownloadFacultyApproval,
        UploadQAApproval,
        DownloadQaApproval,
        UploadSenateApproval,
        DownloadSenateApproval,
        UploadRNPPApproval,
        DownloadRNPPApproval,
        GeneratePlan,
        EditProposal,
        DeleteProposal,
        GenerateProposal,
        ValidateProposal

    }

    const generationActions: MenuProps['items'] = [
        {
            label: 'Generează plan învățământ',
            key: ActionKeyEnum.GeneratePlan,
            icon:
                <FontAwesomeIcon icon={solid('file-export')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        },
        {
            label: 'Generează propunere',
            key: ActionKeyEnum.GenerateProposal,
            icon:
                <FontAwesomeIcon icon={solid('file-export')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        }
    ];

    const downloadSubjectFilesApprovalAction: ItemType =
    {
        label: 'Fișe discipline',
        key: ActionKeyEnum.DownloadSubjectFiles,
        icon: 
        <Tooltip title='Descarcă fișe discipline'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadSubjectFilesApprovalAction: ItemType =
    {
        label: 'Fișe discipline',
        key: ActionKeyEnum.UploadSubjectFiles,
        icon:
        <Tooltip title='Încarcă fișe discipline'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadCVsApprovalAction: ItemType =
    {
        label: 'CV-uri',
        key: ActionKeyEnum.DownloadCVs,
        icon:
        <Tooltip title='Descarcă CV-uri'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadCVsApprovalAction: ItemType =
    {
        label: 'CV-uri',
        key: ActionKeyEnum.UploadCVs,
        icon:
        <Tooltip title='Încarcă CV-uri'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadDepartmentApprovalAction: ItemType =
    {
        label: 'Aviz departament',
        key: ActionKeyEnum.DownloadDeptApproval,
        icon:
        <Tooltip title='Descarcă aviz departament'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadDepartmentApprovalAction: ItemType =
    {
        label: 'Aviz departament',
        key: ActionKeyEnum.UploadDeptApproval,
        icon:
        <Tooltip title='Încarcă aviz departament'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadFacultyApprovalAction: ItemType =
    {
        label: 'Aviz facultate',
        key: ActionKeyEnum.UploadFacultyApproval,
        icon:
        <Tooltip title='Încarcă aviz facultate'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadFacultyApprovalAction: ItemType =
    {
        label: 'Aviz facultate',
        key: ActionKeyEnum.DownloadFacultyApproval,
        icon:
        <Tooltip title='Descarcă aviz facultate'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadQAApprovalAction: ItemType =
    {
        label: 'Aviz calitate',
        key: ActionKeyEnum.UploadQAApproval,
        icon:
        <Tooltip title='Încarcă aviz calitate'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadQAApprovalAction: ItemType =
    {
        label: 'Aviz calitate',
        key: ActionKeyEnum.DownloadQaApproval,
        icon:
        <Tooltip title='Descarcă aviz calitate'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const uploadSenateApprovalAction: ItemType =
    {
        label: 'Hotărâre senat',
        key: ActionKeyEnum.UploadSenateApproval,
        icon:
        <Tooltip title='Încarcă hotărâre senat'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadSenateApprovalAction: ItemType =
    {
        label: 'Hotărâre senat',
        key: ActionKeyEnum.DownloadSenateApproval,
        icon:
        <Tooltip title='Descarcă hotărâre senat'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };
    
    const uploadRNPPApprovalAction: ItemType =
    {
        label: 'Aviz RNPP',
        key: ActionKeyEnum.UploadRNPPApproval,
        icon:
        <Tooltip title='Încarcă aviz RNPP'>
            <FontAwesomeIcon icon={solid('upload')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadRNPPApprovalAction: ItemType =
    {
        label: 'Aviz RNPP',
        key: ActionKeyEnum.DownloadRNPPApproval,
        icon:
        <Tooltip title='Descarcă aviz RNPP'>
            <FontAwesomeIcon icon={solid('download')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        </Tooltip>
    };

    const downloadInitialDocumentActions: MenuProps['items'] = [
        downloadSubjectFilesApprovalAction,
        downloadCVsApprovalAction
    ];

    const initialDocumentActions: MenuProps['items'] = [
        uploadSubjectFilesApprovalAction,
        downloadSubjectFilesApprovalAction,
        uploadCVsApprovalAction,
        downloadCVsApprovalAction
    ];

    const deleteAction: ItemType =
    {
        label: 'Ștergere propunere',
        key: ActionKeyEnum.DeleteProposal,
        icon:
            <FontAwesomeIcon icon={solid('trash')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
    };

    export const editAction: ItemType =
    {
        label: 'Editare propunere',
        key: ActionKeyEnum.EditProposal,
        icon:
            <FontAwesomeIcon icon={solid('edit')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
        
    };

    const validateAction: ItemType =
    {
        label: 'Trimite spre validare',
        key: ActionKeyEnum.ValidateProposal,
        icon:
            <FontAwesomeIcon icon={solid('check-square')} style={{ cursor: 'pointer', height: "1rem", padding: "6px 0px" }}/>
    };

    const downloadActions: MenuProps['items'] = [
        downloadSubjectFilesApprovalAction,
        downloadCVsApprovalAction,
        downloadDepartmentApprovalAction,
        downloadFacultyApprovalAction,
    ];

    // DRAFT
    export const draftItems: MenuProps['items'] =
        initialDocumentActions
        .concat(generationActions)
        .concat([
            editAction,
            deleteAction,
            validateAction
        ]);


    // DepartmentApproval
    export const departmentApprovalItems: MenuProps['items'] =
        downloadInitialDocumentActions
        .concat([
            uploadDepartmentApprovalAction
        ])
        .concat(generationActions)
        .concat([
            deleteAction
        ]);

    export const departmentApprovalItemsForAdmin: MenuProps['items'] =
        downloadInitialDocumentActions
        .concat(generationActions)
        .concat([
            editAction,
        ]);

    // FacultyApproval
    export const facultyApprovalItems: MenuProps['items'] =
        downloadInitialDocumentActions
        .concat([
            downloadDepartmentApprovalAction,
            uploadFacultyApprovalAction
        ])
        .concat(generationActions)
        .concat([
            deleteAction
        ]);

    export const facultyApprovalItemsForAdmins: MenuProps['items'] =
        downloadInitialDocumentActions
        .concat([
            downloadDepartmentApprovalAction
        ])
        .concat(generationActions)
        .concat([
            editAction
        ]);

    // QAApproval
    export const QAApprovalItemsForAdmin: MenuProps['items'] =
        downloadActions
        .concat([
            uploadQAApprovalAction
        ])
        .concat(generationActions)
        .concat([
            editAction,
        ]);
    
    export const QAApprovalItems: MenuProps['items'] =
        downloadActions
        .concat(generationActions);


    // SenateApproval
    export const SenateApprovalItemsForAdmin: MenuProps['items'] =
        downloadActions
        .concat([
            downloadQAApprovalAction,
            uploadSenateApprovalAction
        ])
        .concat(generationActions)
        .concat([
            editAction,
        ]);

    export const SenateApprovalItems: MenuProps['items'] =
        downloadActions
        .concat([
            downloadQAApprovalAction
        ])
        .concat(generationActions);


    // RNPPApproval
    export const RNPPApprovalItemsForAdmin: MenuProps['items'] =
        downloadActions
        .concat([
            downloadQAApprovalAction,
            downloadSenateApprovalAction,
            uploadRNPPApprovalAction
        ])
        .concat(generationActions)
        .concat([
            editAction,
        ]);

    export const RNPPApprovalItems: MenuProps['items'] =
        downloadActions
        .concat([
            downloadQAApprovalAction,
            downloadSenateApprovalAction,
        ])
        .concat(generationActions);


    // Finalized
    export const finalizedItems: MenuProps['items'] =
        downloadActions
        .concat([
            downloadQAApprovalAction,
            downloadSenateApprovalAction,
            downloadRNPPApprovalAction
        ])
        .concat(generationActions);
