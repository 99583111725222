/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserToJSON,
    AcademicUserUpdateDTO,
    AcademicUserUpdateDTOFromJSON,
    AcademicUserUpdateDTOToJSON,
    AcademicUserUpdateRoleDTO,
    AcademicUserUpdateRoleDTOFromJSON,
    AcademicUserUpdateRoleDTOToJSON,
    AdminDTO,
    AdminDTOFromJSON,
    AdminDTOToJSON,
    ExtendedViewStudentDTO,
    ExtendedViewStudentDTOFromJSON,
    ExtendedViewStudentDTOToJSON,
    Filter,
    FilterFromJSON,
    FilterToJSON,
    ProfessorDTO,
    ProfessorDTOFromJSON,
    ProfessorDTOToJSON,
    Role,
    RoleFromJSON,
    RoleToJSON,
    SimpleViewAcademicUserDTOPagedResponse,
    SimpleViewAcademicUserDTOPagedResponseFromJSON,
    SimpleViewAcademicUserDTOPagedResponseToJSON,
    SimpleViewStudentDTOPagedResponse,
    SimpleViewStudentDTOPagedResponseFromJSON,
    SimpleViewStudentDTOPagedResponseToJSON,
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumToJSON,
} from '../models';

export interface ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest {
    departmentId: string;
}

export interface ApiAcademicUserGetAcademicUsersNonStudentsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAdminByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetAllAcademicUsersGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllProfessorsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetAllStudentsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserGetProfessorByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetProfessorFiltersGetRequest {
    language?: string;
}

export interface ApiAcademicUserGetProfessorsByIdGetRequest {
    ids?: Array<string>;
}

export interface ApiAcademicUserGetStudentByIdIdGetRequest {
    id: string;
}

export interface ApiAcademicUserGetStudentFiltersGetRequest {
    language?: string;
}

export interface ApiAcademicUserGetUniversityAllProfessorsGetRequest {
    namePattern?: string;
    faculties?: Array<string>;
    departments?: Array<string>;
    years?: Array<StudentYearEnum>;
    page?: number;
    pageSize?: number;
}

export interface ApiAcademicUserUpdateAcademicUserRoleIdPutRequest {
    id: string;
    academicUserUpdateRoleDTO?: AcademicUserUpdateRoleDTO;
}

export interface ApiAcademicUserUpdateAdminIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

export interface ApiAcademicUserUpdateProfessorIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

export interface ApiAcademicUserUpdateStudentIdPutRequest {
    id: string;
    academicUserUpdateDTO?: AcademicUserUpdateDTO;
}

/**
 * 
 */
export class AcademicUserApi extends runtime.BaseAPI {

    /**
     */
    async apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRaw(requestParameters: ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AcademicUser>>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAcademicUserByDepartmentId/{departmentId}`.replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AcademicUserFromJSON));
    }

    /**
     */
    async apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGet(requestParameters: ApiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRequest, initOverrides?: RequestInit): Promise<Array<AcademicUser>> {
        const response = await this.apiAcademicUserGetAcademicUserByDepartmentIdDepartmentIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAcademicUsersNonStudentsGetRaw(requestParameters: ApiAcademicUserGetAcademicUsersNonStudentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAcademicUsersNonStudents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAcademicUsersNonStudentsGet(requestParameters: ApiAcademicUserGetAcademicUsersNonStudentsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAcademicUsersNonStudentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAdminByIdIdGetRaw(requestParameters: ApiAcademicUserGetAdminByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetAdminByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAdminById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAdminByIdIdGet(requestParameters: ApiAcademicUserGetAdminByIdIdGetRequest, initOverrides?: RequestInit): Promise<AdminDTO> {
        const response = await this.apiAcademicUserGetAdminByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllAcademicUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllAcademicUsersGet(requestParameters: ApiAcademicUserGetAllAcademicUsersGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllAcademicUsersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllProfessorsGetRaw(requestParameters: ApiAcademicUserGetAllProfessorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllProfessors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllProfessorsGet(requestParameters: ApiAcademicUserGetAllProfessorsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllProfessorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetAllStudentsGetRaw(requestParameters: ApiAcademicUserGetAllStudentsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewStudentDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetAllStudents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewStudentDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetAllStudentsGet(requestParameters: ApiAcademicUserGetAllStudentsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewStudentDTOPagedResponse> {
        const response = await this.apiAcademicUserGetAllStudentsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorByIdIdGetRaw(requestParameters: ApiAcademicUserGetProfessorByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetProfessorByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetProfessorByIdIdGet(requestParameters: ApiAcademicUserGetProfessorByIdIdGetRequest, initOverrides?: RequestInit): Promise<ProfessorDTO> {
        const response = await this.apiAcademicUserGetProfessorByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorFiltersGetRaw(requestParameters: ApiAcademicUserGetProfessorFiltersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiAcademicUserGetProfessorFiltersGet(requestParameters: ApiAcademicUserGetProfessorFiltersGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiAcademicUserGetProfessorFiltersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetProfessorsByIdGetRaw(requestParameters: ApiAcademicUserGetProfessorsByIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProfessorDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetProfessorsById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProfessorDTOFromJSON));
    }

    /**
     */
    async apiAcademicUserGetProfessorsByIdGet(requestParameters: ApiAcademicUserGetProfessorsByIdGetRequest = {}, initOverrides?: RequestInit): Promise<Array<ProfessorDTO>> {
        const response = await this.apiAcademicUserGetProfessorsByIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Role>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetRolesForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     */
    async apiAcademicUserGetRolesForUserGet(initOverrides?: RequestInit): Promise<Array<Role>> {
        const response = await this.apiAcademicUserGetRolesForUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGetRaw(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedViewStudentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserGetStudentByIdIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentById/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedViewStudentDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetStudentByIdIdGet(requestParameters: ApiAcademicUserGetStudentByIdIdGetRequest, initOverrides?: RequestInit): Promise<ExtendedViewStudentDTO> {
        const response = await this.apiAcademicUserGetStudentByIdIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersGetRaw(requestParameters: ApiAcademicUserGetStudentFiltersGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Filter>>> {
        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetStudentFilters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterFromJSON));
    }

    /**
     */
    async apiAcademicUserGetStudentFiltersGet(requestParameters: ApiAcademicUserGetStudentFiltersGetRequest = {}, initOverrides?: RequestInit): Promise<Array<Filter>> {
        const response = await this.apiAcademicUserGetStudentFiltersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserGetUniversityAllProfessorsGetRaw(requestParameters: ApiAcademicUserGetUniversityAllProfessorsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SimpleViewAcademicUserDTOPagedResponse>> {
        const queryParameters: any = {};

        if (requestParameters.namePattern !== undefined) {
            queryParameters['NamePattern'] = requestParameters.namePattern;
        }

        if (requestParameters.faculties) {
            queryParameters['Faculties'] = requestParameters.faculties;
        }

        if (requestParameters.departments) {
            queryParameters['Departments'] = requestParameters.departments;
        }

        if (requestParameters.years) {
            queryParameters['Years'] = requestParameters.years;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['PageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/GetUniversityAllProfessors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleViewAcademicUserDTOPagedResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserGetUniversityAllProfessorsGet(requestParameters: ApiAcademicUserGetUniversityAllProfessorsGetRequest = {}, initOverrides?: RequestInit): Promise<SimpleViewAcademicUserDTOPagedResponse> {
        const response = await this.apiAcademicUserGetUniversityAllProfessorsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateAcademicUserRoleIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateAcademicUserRole/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateRoleDTOToJSON(requestParameters.academicUserUpdateRoleDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateAcademicUserRoleIdPut(requestParameters: ApiAcademicUserUpdateAcademicUserRoleIdPutRequest, initOverrides?: RequestInit): Promise<ProfessorDTO> {
        const response = await this.apiAcademicUserUpdateAcademicUserRoleIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateAdminIdPutRaw(requestParameters: ApiAcademicUserUpdateAdminIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateAdminIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateAdmin/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateAdminIdPut(requestParameters: ApiAcademicUserUpdateAdminIdPutRequest, initOverrides?: RequestInit): Promise<AdminDTO> {
        const response = await this.apiAcademicUserUpdateAdminIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateProfessorIdPutRaw(requestParameters: ApiAcademicUserUpdateProfessorIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateProfessorIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateProfessor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessorDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateProfessorIdPut(requestParameters: ApiAcademicUserUpdateProfessorIdPutRequest, initOverrides?: RequestInit): Promise<ProfessorDTO> {
        const response = await this.apiAcademicUserUpdateProfessorIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAcademicUserUpdateStudentIdPutRaw(requestParameters: ApiAcademicUserUpdateStudentIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedViewStudentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAcademicUserUpdateStudentIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/AcademicUser/UpdateStudent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AcademicUserUpdateDTOToJSON(requestParameters.academicUserUpdateDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedViewStudentDTOFromJSON(jsonValue));
    }

    /**
     */
    async apiAcademicUserUpdateStudentIdPut(requestParameters: ApiAcademicUserUpdateStudentIdPutRequest, initOverrides?: RequestInit): Promise<ExtendedViewStudentDTO> {
        const response = await this.apiAcademicUserUpdateStudentIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
