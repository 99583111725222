import { FileManagerApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new FileManagerApi(getAuthConfiguration());

export const downloadSubjectFiles = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadSubjectFilesProposalIdGet( {proposalId} )
}

export const uploadSubjectFiles = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadSubjectFilesProposalIdPost({ proposalId, file });
}

export const downloadCVs = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadCVsProposalIdGet( {proposalId} )
}

export const uploadCVs = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadCVsProposalIdPost({ proposalId, file });
}

export const downloadDeptApproval = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadDepartmentApprovalProposalIdGet( {proposalId} )
}

export const uploadDeptApproval = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadDepartmentApprovalProposalIdPost({ proposalId, file });
}

export const downloadFacultyApproval = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadFacultyApprovalProposalIdGet( {proposalId} )
}

export const uploadFacultyApproval = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadFacultyApprovalProposalIdPost({ proposalId, file });
}


export const downloadQAApproval = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadQAApprovalProposalIdGet( {proposalId} )
}

export const uploadQAApproval = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadQAApprovalProposalIdPost({ proposalId, file });
}


export const downloadSenateApproval = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloadSenateApprovalProposalIdGet( {proposalId} )
}

export const uploadSenateApproval = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadSenateApprovalProposalIdPost({ proposalId, file });
}

export const downloadRNPPApproval = (proposalId: string) => {
    return ApiFactory().apiFileManagerDownloaRNPPApprovalProposalIdGet( {proposalId} )
}

export const uploadRNPPApproval = (proposalId: string, file: Blob) => {
    return ApiFactory().apiFileManagerUploadRNPPApprovalProposalIdPost({ proposalId, file });
}
