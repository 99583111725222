import { ProgramTypeEnum, ProposalStatusEnum, StudyDomainEnum } from "../Api";

export const StudyDomainEnumConverter = (value: StudyDomainEnum | undefined) => {
    switch (value) {
        case null: return 'N/A';
        case undefined: return 'N/A';
        case StudyDomainEnum.All: return 'Toate';
        case StudyDomainEnum.MCti: return 'Calculatoare si tehnologia informţiei';
        case StudyDomainEnum.MAuto: return 'Ingineria autovehiculelor';
        case StudyDomainEnum.MMateriale: return 'Ingineria materialelor';
        case StudyDomainEnum.MMediu: return 'Ingineria mediului';
        case StudyDomainEnum.MAlimente: return 'Ingineria produselor alimentare';
        case StudyDomainEnum.MSisteme: return 'Ingineria sistemelor';
        case StudyDomainEnum.MTransporturi: return 'Ingineria transporturilor';
        case StudyDomainEnum.MAerospatiala: return 'Inginerie aerospaţială';
        case StudyDomainEnum.MChimica: return 'Inginerie chimică';
        case StudyDomainEnum.MElectrica: return 'Inginerie electrică';
        case StudyDomainEnum.MEtti: return 'Inginerie electronică, telecomunicaţii și tehnologii informaționale';
        case StudyDomainEnum.MEnergetica: return 'Inginerie energetică';
        case StudyDomainEnum.MIndustriala: return 'Inginerie industrială';
        case StudyDomainEnum.MMecanica: return 'Inginerie mecanică';
        case StudyDomainEnum.MManagement: return 'Inginerie şi management';
        case StudyDomainEnum.MMecatronica: return 'Mecatronică şi robotică';
        case StudyDomainEnum.MAplicate: return 'Ştiinţe inginereşti aplicate';
        case StudyDomainEnum.MComunicare: return 'Stiințe ale comunicării';
        case StudyDomainEnum.MRelatii: return 'Relații internaționale și studii europene';
        case StudyDomainEnum.MEconomie: return 'Economie';
        case StudyDomainEnum.MEducatie: return 'Științe ale educației';
    }

}


export const ProgramTypeEnumConverter = (value: ProgramTypeEnum | undefined) => {
    switch (value) {
        case null: return 'N/A';
        case undefined: return 'N/A';
        case ProgramTypeEnum.All: return 'Toate';
        case ProgramTypeEnum.Ia1: return 'A1 - formare şi dezvoltare profesională continuă';
        case ProgramTypeEnum.Ia2: return 'A2 - perfectionare';
        case ProgramTypeEnum.Ib1: return 'B1 - initiere';
        case ProgramTypeEnum.Ib2: return 'B2 - perfectionare';
        case ProgramTypeEnum.Ib3: return 'B3 - specializare';
        case ProgramTypeEnum.Ib4: return 'B4 - calificare si recalificare';
        case ProgramTypeEnum.Iic: return 'C - program conversie profesională';
    }

}

export const ProposalStatusEnumConverter = (value: ProposalStatusEnum | undefined) => {
    switch (value) {
        case null: return 'N/A';
        case undefined: return 'N/A';
        case ProposalStatusEnum.All: return 'Toate';
        case ProposalStatusEnum.Draft: return 'Draft';
        case ProposalStatusEnum.DepartmentApproval: return 'Încărcare Aviz Departament';
        case ProposalStatusEnum.FacultyApproval: return 'Încărcare Aviz Facultate';
        case ProposalStatusEnum.QaApproval: return 'Încărcare Aviz Calitate';
        case ProposalStatusEnum.SenateApproval: return 'Încărcare Hot. Senat';
        case ProposalStatusEnum.RnppApproval: return 'Încărcare Aviz RNPP';
        case ProposalStatusEnum.Finalized: return 'Finalizat';
    }

}