/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyFacultyAccord,
    CompanyFacultyAccordFromJSON,
    CompanyFacultyAccordFromJSONTyped,
    CompanyFacultyAccordToJSON,
} from './CompanyFacultyAccord';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    FacultyInternship,
    FacultyInternshipFromJSON,
    FacultyInternshipFromJSONTyped,
    FacultyInternshipToJSON,
} from './FacultyInternship';
import {
    InternshipFaculty,
    InternshipFacultyFromJSON,
    InternshipFacultyFromJSONTyped,
    InternshipFacultyToJSON,
} from './InternshipFaculty';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface Faculty
 */
export interface Faculty {
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    nameRo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    nameEn?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Faculty
     */
    externalId?: number;
    /**
     * 
     * @type {number}
     * @memberof Faculty
     */
    practiceNumberOfHours?: number;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    practiceEvaluationStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Faculty
     */
    practiceEvaluationEnd?: Date;
    /**
     * 
     * @type {Array<StudentYearEnum>}
     * @memberof Faculty
     */
    practiceYears?: Array<StudentYearEnum> | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Faculty
     */
    departments?: Array<Department> | null;
    /**
     * 
     * @type {Array<FacultyInternship>}
     * @memberof Faculty
     */
    facultyInternships?: Array<FacultyInternship> | null;
    /**
     * 
     * @type {Array<CompanyFacultyAccord>}
     * @memberof Faculty
     */
    companyFacultyAccords?: Array<CompanyFacultyAccord> | null;
    /**
     * 
     * @type {Array<InternshipFaculty>}
     * @memberof Faculty
     */
    internshipFaculties?: Array<InternshipFaculty> | null;
}

export function FacultyFromJSON(json: any): Faculty {
    return FacultyFromJSONTyped(json, false);
}

export function FacultyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Faculty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'nameRo': !exists(json, 'nameRo') ? undefined : json['nameRo'],
        'nameEn': !exists(json, 'nameEn') ? undefined : json['nameEn'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'practiceNumberOfHours': !exists(json, 'practiceNumberOfHours') ? undefined : json['practiceNumberOfHours'],
        'practiceEvaluationStart': !exists(json, 'practiceEvaluationStart') ? undefined : (new Date(json['practiceEvaluationStart'])),
        'practiceEvaluationEnd': !exists(json, 'practiceEvaluationEnd') ? undefined : (new Date(json['practiceEvaluationEnd'])),
        'practiceYears': !exists(json, 'practiceYears') ? undefined : (json['practiceYears'] === null ? null : (json['practiceYears'] as Array<any>).map(StudentYearEnumFromJSON)),
        'departments': !exists(json, 'departments') ? undefined : (json['departments'] === null ? null : (json['departments'] as Array<any>).map(DepartmentFromJSON)),
        'facultyInternships': !exists(json, 'facultyInternships') ? undefined : (json['facultyInternships'] === null ? null : (json['facultyInternships'] as Array<any>).map(FacultyInternshipFromJSON)),
        'companyFacultyAccords': !exists(json, 'companyFacultyAccords') ? undefined : (json['companyFacultyAccords'] === null ? null : (json['companyFacultyAccords'] as Array<any>).map(CompanyFacultyAccordFromJSON)),
        'internshipFaculties': !exists(json, 'internshipFaculties') ? undefined : (json['internshipFaculties'] === null ? null : (json['internshipFaculties'] as Array<any>).map(InternshipFacultyFromJSON)),
    };
}

export function FacultyToJSON(value?: Faculty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'nameRo': value.nameRo,
        'nameEn': value.nameEn,
        'externalId': value.externalId,
        'practiceNumberOfHours': value.practiceNumberOfHours,
        'practiceEvaluationStart': value.practiceEvaluationStart === undefined ? undefined : (value.practiceEvaluationStart.toISOString()),
        'practiceEvaluationEnd': value.practiceEvaluationEnd === undefined ? undefined : (value.practiceEvaluationEnd.toISOString()),
        'practiceYears': value.practiceYears === undefined ? undefined : (value.practiceYears === null ? null : (value.practiceYears as Array<any>).map(StudentYearEnumToJSON)),
        'departments': value.departments === undefined ? undefined : (value.departments === null ? null : (value.departments as Array<any>).map(DepartmentToJSON)),
        'facultyInternships': value.facultyInternships === undefined ? undefined : (value.facultyInternships === null ? null : (value.facultyInternships as Array<any>).map(FacultyInternshipToJSON)),
        'companyFacultyAccords': value.companyFacultyAccords === undefined ? undefined : (value.companyFacultyAccords === null ? null : (value.companyFacultyAccords as Array<any>).map(CompanyFacultyAccordToJSON)),
        'internshipFaculties': value.internshipFaculties === undefined ? undefined : (value.internshipFaculties === null ? null : (value.internshipFaculties as Array<any>).map(InternshipFacultyToJSON)),
    };
}

