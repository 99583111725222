import { useState } from "react";
import styles from "./MyAccount.module.scss";
import { Row, Table } from "antd";
import { getUserId } from "../../utils/utilFunctions";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { CompanyUserDTO } from "../../Api";
import Search from 'antd/lib/input/Search';
import { getAllCompanyUsers, deleteCompanyUser } from "../../Requests/company-users-requests";
import AddUpdateCompanyUserModal from './AddUpdateCompanyUserModal';
import { StatusCodes } from "http-status-codes";
import { useQuery, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../Containers/ConfirmationModal";
import { makeRepresentative } from "../../Requests/company-users-requests";
import { useHistory } from "react-router-dom";
import { theme } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";


const CompanyUserTable = () => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const history = useHistory();

    const queryClient = useQueryClient();
    const invalidateUsersQuery = () => { queryClient.invalidateQueries('getCompanyUsers') };

    const handleDelete = (id: string) => {
        deleteCompanyUser(id)
            .then(invalidateUsersQuery)
            .catch((ex: any) => {
                if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
                    openNotification(
                        t('account.conflict'),
                        t('account.notFoundUser'),
                        NOTIFICATION_TYPES.ERROR
                    );
                } else if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
                    openNotification(
                        t('account.conflict'),
                        t('account.notAllowed'),
                        NOTIFICATION_TYPES.ERROR
                    );
                } else {
                    openNotification(
                        t('account.error'),
                        t('account.unknownError'),
                         NOTIFICATION_TYPES.ERROR
                    );
                }
            });
    }

    const handleChangeRepresentative = (id: string) => {
        makeRepresentative(id)
        .then(() => {history.push("/profil");})
        .catch((ex: any) => {
            if (ex.status && ex.status === StatusCodes.FORBIDDEN) {
                openNotification("Forbidden", "You don't have access!", NOTIFICATION_TYPES.ERROR);
            } else {
                openNotification("Error", "An unknown error occured", NOTIFICATION_TYPES.ERROR);
            }
        });
    }

    const { data: users, isLoading } = useQuery(['getCompanyUsers', searchTerm, page, pageSize], () => {
        return getAllCompanyUsers(searchTerm, page, pageSize).catch(() =>
            openNotification(
                t('account.error'),
                t('usersText.errorTexts.failedUserGet'),
                NOTIFICATION_TYPES.ERROR
            )
        );
    });

    const FilterByNameInput = (
        <Search placeholder={t('account.name')} allowClear onSearch={setSearchTerm} style={{ width: 200 }} />
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('account.email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('account.phone'),
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: t('account.function'),
            dataIndex: '_function',
            key: 'function',
        },
        {
            title: t('account.department'),
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: t('account.actions'),
            render: (_text: string | undefined, record: any) =>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <div className={styles.modalBtnContainer}>
                        <AddUpdateCompanyUserModal type={'edit'} userId={record.id} />
                        {record.isRepresentative !== true ?
                            <div className={styles.modalContainer}>
                                <ConfirmationModal
                                    nameButton={''}
                                    modalText={t('account.changeRepresentativeMessage1') + record.name + t('account.changeRepresentativeMessage2')}
                                    titleButton={t('account.changeRepresentative')}
                                    handleFunction={() => handleChangeRepresentative(record.id)}
                                    iconButton={<FontAwesomeIcon icon={solid('star')}/>}
                                    shapeButton={''}
                                    typeButton={''}
                                    disabledProp={false}
                                    styleProp={{ background: 'transparent', border: 'none', outline: 'none', color: theme.black, boxShadow: 'none', padding: 0, marginTop: 0 }}
                                />
                            </div>
                        : null}
                        {record.isRepresentative !== true ?
                            <div className={styles.modalContainer}>
                                <ConfirmationModal
                                    nameButton={''}
                                    modalText={t('account.deleteUserMessage') + record.name + '?'}
                                    titleButton={t('account.deleteUser')}
                                    handleFunction={() => handleDelete(record.id)}
                                    iconButton={<FontAwesomeIcon icon={solid('user-slash')}/>}
                                    shapeButton={''}
                                    typeButton={''}
                                    disabledProp={false}
                                    styleProp={{ background: 'transparent', border: 'none', outline: 'none', color: theme.black, boxShadow: 'none', padding: 0, marginTop: 0 }}
                                />
                            </div>
                        : null}
                    </div>
                </div>
        },
    ];


    const handleTableChange = (pagination: any) => {
        setPage(() => pagination.current);
        setPageSize(() => pagination.pageSize);
    };

    return <div className={styles.container} style={{ display: "flex", flexDirection: "column", marginTop: "0%" }}>
        <Row>
            <AddUpdateCompanyUserModal type={'add'} userId={getUserId()} />
        </Row>
        <Row>
            <Table
                className={styles.usersTable}
                dataSource={users?.data ?? new Array<CompanyUserDTO>()}
                columns={columns}
                pagination={{
                    total: users?.totalCount,
                    current: users?.page,
                    pageSize: users?.pageSize,
                    pageSizeOptions: ["10", "20", "50"],
                    showSizeChanger: true,
                    locale: { items_per_page: t('configuration.pagination') },
                    position: ['topRight'],
                    hideOnSinglePage: true,
                }}
                rowKey={record => record.id ?? ''}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Row>
    </div>
}

export default CompanyUserTable;