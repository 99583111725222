/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Specialization,
    SpecializationFromJSON,
    SpecializationFromJSONTyped,
    SpecializationToJSON,
} from './Specialization';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';

/**
 * 
 * @export
 * @interface AdditionalStudentData
 */
export interface AdditionalStudentData {
    /**
     * 
     * @type {string}
     * @memberof AdditionalStudentData
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdditionalStudentData
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdditionalStudentData
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof AdditionalStudentData
     */
    averageGrade?: number;
    /**
     * 
     * @type {number}
     * @memberof AdditionalStudentData
     */
    numberOfRepetitions?: number;
    /**
     * 
     * @type {string}
     * @memberof AdditionalStudentData
     */
    domain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalStudentData
     */
    series?: string | null;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof AdditionalStudentData
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {string}
     * @memberof AdditionalStudentData
     */
    specializationId?: string | null;
    /**
     * 
     * @type {Specialization}
     * @memberof AdditionalStudentData
     */
    specialization?: Specialization;
    /**
     * 
     * @type {string}
     * @memberof AdditionalStudentData
     */
    studentId?: string;
    /**
     * 
     * @type {AcademicUser}
     * @memberof AdditionalStudentData
     */
    student?: AcademicUser;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    specializationChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    firstSemesterSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    secondSemesterFirstSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    secondSemesterSecondSubjectChoices?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    specializationRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    firstSemesterSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    secondSemesterFirstSubjectRepartition?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdditionalStudentData
     */
    secondSemesterSecondSubjectRepartition?: Array<string> | null;
}

export function AdditionalStudentDataFromJSON(json: any): AdditionalStudentData {
    return AdditionalStudentDataFromJSONTyped(json, false);
}

export function AdditionalStudentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalStudentData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'averageGrade': !exists(json, 'averageGrade') ? undefined : json['averageGrade'],
        'numberOfRepetitions': !exists(json, 'numberOfRepetitions') ? undefined : json['numberOfRepetitions'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'series': !exists(json, 'series') ? undefined : json['series'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'specializationId': !exists(json, 'specializationId') ? undefined : json['specializationId'],
        'specialization': !exists(json, 'specialization') ? undefined : SpecializationFromJSON(json['specialization']),
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'student': !exists(json, 'student') ? undefined : AcademicUserFromJSON(json['student']),
        'specializationChoices': !exists(json, 'specializationChoices') ? undefined : json['specializationChoices'],
        'firstSemesterSubjectChoices': !exists(json, 'firstSemesterSubjectChoices') ? undefined : json['firstSemesterSubjectChoices'],
        'secondSemesterFirstSubjectChoices': !exists(json, 'secondSemesterFirstSubjectChoices') ? undefined : json['secondSemesterFirstSubjectChoices'],
        'secondSemesterSecondSubjectChoices': !exists(json, 'secondSemesterSecondSubjectChoices') ? undefined : json['secondSemesterSecondSubjectChoices'],
        'specializationRepartition': !exists(json, 'specializationRepartition') ? undefined : json['specializationRepartition'],
        'firstSemesterSubjectRepartition': !exists(json, 'firstSemesterSubjectRepartition') ? undefined : json['firstSemesterSubjectRepartition'],
        'secondSemesterFirstSubjectRepartition': !exists(json, 'secondSemesterFirstSubjectRepartition') ? undefined : json['secondSemesterFirstSubjectRepartition'],
        'secondSemesterSecondSubjectRepartition': !exists(json, 'secondSemesterSecondSubjectRepartition') ? undefined : json['secondSemesterSecondSubjectRepartition'],
    };
}

export function AdditionalStudentDataToJSON(value?: AdditionalStudentData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'averageGrade': value.averageGrade,
        'numberOfRepetitions': value.numberOfRepetitions,
        'domain': value.domain,
        'series': value.series,
        'year': StudentYearEnumToJSON(value.year),
        'specializationId': value.specializationId,
        'specialization': SpecializationToJSON(value.specialization),
        'studentId': value.studentId,
        'student': AcademicUserToJSON(value.student),
        'specializationChoices': value.specializationChoices,
        'firstSemesterSubjectChoices': value.firstSemesterSubjectChoices,
        'secondSemesterFirstSubjectChoices': value.secondSemesterFirstSubjectChoices,
        'secondSemesterSecondSubjectChoices': value.secondSemesterSecondSubjectChoices,
        'specializationRepartition': value.specializationRepartition,
        'firstSemesterSubjectRepartition': value.firstSemesterSubjectRepartition,
        'secondSemesterFirstSubjectRepartition': value.secondSemesterFirstSubjectRepartition,
        'secondSemesterSecondSubjectRepartition': value.secondSemesterSecondSubjectRepartition,
    };
}

