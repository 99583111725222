import { Editor } from "@tinymce/tinymce-react";

const DescriptionEditor = (props: { description?: string | null, value?: string | null, onEditorChange: any}) => {
    return <div>
        <Editor
            value={props.description ?? props.value ?? ''}
            init={{
                resize: false,
                branding: false,
                elementpath: false,
                width: "100%",
                height: "20rem",
                margin: "0",
                menubar: false,
                outputFormat: "text",
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                    "undo redo | formatselect | " +
                    "bold italic | fontselect | fontsizeselect | forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                content_style:
                    "body { font-family:Montserrat; font-size:14px; color: rgba(0, 0, 0, 0.85); }",
            }}
            outputFormat="html"
            onEditorChange={(_newValue, editor) => {
                props.onEditorChange(editor.getContent({ format: 'html' }));
            }}
        />
    </div>;
}

export default DescriptionEditor;