import styled from 'styled-components';
import { Select } from 'antd';

const StyledSelect = styled(Select)`
  outline: none;
  border: none;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 1%;
  text-decoration: none;
  font-family: 'Montserrat';
  width: ${(props: any) => props.fullwidth ? '100%' : props.width ? props.width : 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover, &:focus, &:active {
    outline: none;
    border: none;
    border-radius: 10px;
    margin-top: 15px;
    margin-right: 1%;
    text-decoration: none;
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CustomSelect = (props: any) => {
  return <StyledSelect {...props}>{props.children}</StyledSelect>;
};

export default CustomSelect;
