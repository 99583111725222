import {Modal, Tooltip, Typography} from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomComponents/CustomButton";
import { theme } from "../theme";


const ConfirmationModal = (props: { nameButton: string, modalText: string, titleButton: string, handleFunction: any, iconButton: any, shapeButton: string, typeButton: string, disabledProp: boolean, styleProp: any }) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleShow = () => {
        setIsModalVisible(true);
    }

    const handleClose = () => {
        setIsModalVisible(false);
    }


    return <div style={{ paddingBottom: 20 }}>
        <Tooltip title={props.titleButton}>
            <CustomButton
                paddingvertical={"19px"}
                fontSize={"0.9rem"}
                icon={props.iconButton}
                type={props.typeButton}
                shape={props.shapeButton}
                disabled={props.disabledProp}
                style={props.styleProp}
                onClick={handleShow}
            >
                {props.nameButton}
            </CustomButton>
        </Tooltip>
        <Modal
            visible={isModalVisible}
            onOk={props.handleFunction}
            onCancel={handleClose}
            title=""
            width={"50%"}
            okText={t('account.confirm')}
            cancelText={t('account.cancel')}
            cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
            okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
            >
                <div>
                    <Typography>{props.modalText}</Typography>
                </div>
            </Modal>
    </div>

}

export default ConfirmationModal;