/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcademicUser,
    AcademicUserFromJSON,
    AcademicUserFromJSONTyped,
    AcademicUserToJSON,
} from './AcademicUser';
import {
    Proposal,
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface ProposalCoordinator
 */
export interface ProposalCoordinator {
    /**
     * 
     * @type {string}
     * @memberof ProposalCoordinator
     */
    proposalId?: string;
    /**
     * 
     * @type {Proposal}
     * @memberof ProposalCoordinator
     */
    proposal?: Proposal;
    /**
     * 
     * @type {string}
     * @memberof ProposalCoordinator
     */
    coordinatorId?: string;
    /**
     * 
     * @type {AcademicUser}
     * @memberof ProposalCoordinator
     */
    coordinator?: AcademicUser;
}

export function ProposalCoordinatorFromJSON(json: any): ProposalCoordinator {
    return ProposalCoordinatorFromJSONTyped(json, false);
}

export function ProposalCoordinatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalCoordinator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'coordinatorId': !exists(json, 'coordinatorId') ? undefined : json['coordinatorId'],
        'coordinator': !exists(json, 'coordinator') ? undefined : AcademicUserFromJSON(json['coordinator']),
    };
}

export function ProposalCoordinatorToJSON(value?: ProposalCoordinator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'proposal': ProposalToJSON(value.proposal),
        'coordinatorId': value.coordinatorId,
        'coordinator': AcademicUserToJSON(value.coordinator),
    };
}

