/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationTypeEnum,
    EvaluationTypeEnumFromJSON,
    EvaluationTypeEnumFromJSONTyped,
    EvaluationTypeEnumToJSON,
} from './EvaluationTypeEnum';
import {
    PlanSemesterEnum,
    PlanSemesterEnumFromJSON,
    PlanSemesterEnumFromJSONTyped,
    PlanSemesterEnumToJSON,
} from './PlanSemesterEnum';

/**
 * 
 * @export
 * @interface PlanSubjectDTO
 */
export interface PlanSubjectDTO {
    /**
     * 
     * @type {string}
     * @memberof PlanSubjectDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanSubjectDTO
     */
    name?: string | null;
    /**
     * 
     * @type {PlanSemesterEnum}
     * @memberof PlanSubjectDTO
     */
    semester?: PlanSemesterEnum;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    lectureHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    seminarHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    labHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    individualHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    readonly totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanSubjectDTO
     */
    credits?: number;
    /**
     * 
     * @type {EvaluationTypeEnum}
     * @memberof PlanSubjectDTO
     */
    evaluationType?: EvaluationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlanSubjectDTO
     */
    proposalId?: string;
}

export function PlanSubjectDTOFromJSON(json: any): PlanSubjectDTO {
    return PlanSubjectDTOFromJSONTyped(json, false);
}

export function PlanSubjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanSubjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'semester': !exists(json, 'semester') ? undefined : PlanSemesterEnumFromJSON(json['semester']),
        'lectureHours': !exists(json, 'lectureHours') ? undefined : json['lectureHours'],
        'seminarHours': !exists(json, 'seminarHours') ? undefined : json['seminarHours'],
        'labHours': !exists(json, 'labHours') ? undefined : json['labHours'],
        'individualHours': !exists(json, 'individualHours') ? undefined : json['individualHours'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : EvaluationTypeEnumFromJSON(json['evaluationType']),
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
    };
}

export function PlanSubjectDTOToJSON(value?: PlanSubjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'semester': PlanSemesterEnumToJSON(value.semester),
        'lectureHours': value.lectureHours,
        'seminarHours': value.seminarHours,
        'labHours': value.labHours,
        'individualHours': value.individualHours,
        'credits': value.credits,
        'evaluationType': EvaluationTypeEnumToJSON(value.evaluationType),
        'proposalId': value.proposalId,
    };
}

