/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyUserAddUpdateDTO
 */
export interface CompanyUserAddUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAddUpdateDTO
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAddUpdateDTO
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAddUpdateDTO
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAddUpdateDTO
     */
    _function?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserAddUpdateDTO
     */
    department?: string | null;
}

export function CompanyUserAddUpdateDTOFromJSON(json: any): CompanyUserAddUpdateDTO {
    return CompanyUserAddUpdateDTOFromJSONTyped(json, false);
}

export function CompanyUserAddUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyUserAddUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        '_function': !exists(json, 'function') ? undefined : json['function'],
        'department': !exists(json, 'department') ? undefined : json['department'],
    };
}

export function CompanyUserAddUpdateDTOToJSON(value?: CompanyUserAddUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'function': value._function,
        'department': value.department,
    };
}

