import { Modal, Typography, Input, Tooltip } from "antd"
import { useState } from "react";
import { NOTIFICATION_TYPES, openNotification } from "../Notifications/NotificationsUtils";
import { StatusCodes } from "http-status-codes";
import styles from "./MyAccount.module.scss";
import CustomButton from "../../CustomComponents/CustomButton";
import { theme } from "../../theme";
import { addCompanyUsers, updateCompanyUser, getCompanyUserById } from "../../Requests/company-users-requests";
import { useTranslation } from "react-i18next";
import { emailPattern } from "../../utils/constants";
import { useQueryClient } from "react-query";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const AddUpdateCompanyUserModal = (props: { type: string, userId: string }) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [_function, setFunction] = useState('');
    const [department, setDepartment] = useState('');

    const queryClient = useQueryClient();
    const invalidateUsersQuery = () => { queryClient.invalidateQueries('getCompanyUsers') };

    const handleShow = () => {
        setIsModalVisible(true);
    }

    const handleClose = () => {
        setIsModalVisible(false);
    }

    const handleAdd = () => {
        if (!name || !email || !_function) {
            openNotification(
                t('account.saveError'),
                t('account.missingFields'),
                NOTIFICATION_TYPES.ERROR
            );
        } else if (!emailPattern.test(email)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidEmail'),
                NOTIFICATION_TYPES.ERROR
            ); 
        } else {
            setIsModalVisible(false);
            addCompanyUsers({name, email, phone, _function, department})
            .then(() => invalidateUsersQuery())
            .catch((ex: any) => {
                if (ex.status && ex.status === StatusCodes.CONFLICT) {
                    openNotification(
                        t('account.conflict'),
                        t('account.conflictUser'),
                        NOTIFICATION_TYPES.ERROR
                    );
                } else {
                    openNotification(
                        t('account.error'),
                        t('account.unknownError'),
                        NOTIFICATION_TYPES.ERROR
                    );
                }
            });
        }
    }

    if(props.type === "edit" && !name && !email) {
        getCompanyUserById(props.userId)
        .then((response : any) => {
            setName(response.name);
            setEmail(response.email);
            setPhone(response.phone);
            setFunction(response._function);
            setDepartment(response.department);
        })
        .catch((ex: any) => {
            if (ex.status && ex.status === StatusCodes.NOT_FOUND) {
                openNotification(
                    t('account.error'),
                    t('account.notFoundUser'),
                    NOTIFICATION_TYPES.ERROR
                );
            } else {
                openNotification(
                    t('account.error'),
                    t('account.unknownError'),
                    NOTIFICATION_TYPES.ERROR
                );
            }
        });
    }
    
    const handleUpdate = () => {
        if (!name || !email || !_function) {
            openNotification(
                t('account.saveError'),
                t('account.missingFields'),
                NOTIFICATION_TYPES.ERROR
            );
        } else if (!emailPattern.test(email)) {
            openNotification(
                t('account.saveError'),
                t('account.invalidEmail'),
                NOTIFICATION_TYPES.ERROR
            ); 
        } else {
            setIsModalVisible(false);
            updateCompanyUser(props.userId, {name, email, phone, _function, department})
            .then(() => {window.location.reload()})
            .catch((ex: any) => {
                if (ex.status && ex.status === StatusCodes.CONFLICT) {
                    openNotification(
                        t('account.conflict'),
                        t('account.alreadyUpdatedUser'),
                        NOTIFICATION_TYPES.ERROR
                    );
                } else {
                    openNotification(
                        t('account.error'),
                        t('account.unknownError'),
                        NOTIFICATION_TYPES.ERROR
                    );
                }
            });
        }
    }

    const handleChangeName = (e: any) => {
        setName(e.target.value);
    }

    const handleChangeEmail = (e: any) => {
        setEmail(e.target.value);
    }

    const handleChangePhone = (e: any) => {
        setPhone(e.target.value);
    }

    const handleChangeFunction = (e: any) => {
        setFunction(e.target.value);
    }

    const handleChangeDepartment = (e: any) => {
        setDepartment(e.target.value);
    }

    return <div style={{ display: "flex", alignItems: "center", marginTop: "5%" }}>
        {props.type === 'edit' ? 
            <div className={styles.edit}>
                <Tooltip title={t('account.editUser')}>
                    <FontAwesomeIcon icon={solid('user-pen')} onClick={handleShow} style={{ cursor: 'pointer', height: "1rem" }}/>
                </Tooltip>
            </div>
            :
            <Tooltip title={t('account.addNewMember')}>
                <CustomButton
                    className={styles.addUserButton}
                    backgroundcolor={theme.primaryColor}
                    textcolor={theme.white}
                    paddingvertical={"19px"}
                    fontSize={"0.9rem"}
                    boxshadow={"rgba(0, 0, 0, 0.18) 0px 2px 4px"}
                    marginbottom={"1em"}
                    marginleft={"auto"}
                    onClick={handleShow}
                >
                    {t('account.addUser')}
                </CustomButton>
            </Tooltip> }
        <Modal
            visible={isModalVisible}
            onOk={props.type === 'add' ? handleAdd : handleUpdate}
            onCancel={handleClose}
            title={props.type === 'add' ? t('account.addUser') : t('account.editUser')}
            width={1000}
            okText={t('account.save')}
            cancelText={t('account.cancel')}
            cancelButtonProps={{ style: { background: theme.green, border: theme.green, color: theme.white, borderRadius: "10px" } }}
            okButtonProps={{ style: { background: theme.secondColor, border: theme.secondColor, borderRadius: "10px" } }}
        >
            <div>
                <Typography>* {t('account.name')}:</Typography>
                <Input 
                    placeholder={t('account.name')}
                    style={{ paddingBottom: 10 }} 
                    onChange={handleChangeName} 
                    defaultValue={name} 
                />
            </div>
            <div>
                <Typography>* {t('account.email')}:</Typography>
                <Input 
                    placeholder={t('account.email')}
                    style={{ paddingBottom: 10 }} 
                    onChange={handleChangeEmail} 
                    defaultValue={email} 
                />
            </div>
            <div>
                <Typography>{t('account.phone')}:</Typography>
                <Input 
                    placeholder={t('account.phone')}
                    style={{ paddingBottom: 10 }} 
                    onChange={handleChangePhone} 
                    defaultValue={phone} 
                />
            </div>
            <div>
                <Typography>* {t('account.function')}:</Typography>
                <Input 
                    placeholder={t('account.function')}
                    style={{ paddingBottom: 10 }} 
                    onChange={handleChangeFunction} 
                    defaultValue={_function} 
                />
            </div>
            <div>
                <Typography>{t('account.department')}:</Typography>
                <Input 
                    placeholder={t('account.department')}
                    style={{ paddingBottom: 10 }} 
                    onChange={handleChangeDepartment} 
                    defaultValue={department} 
                />
            </div>
        </Modal>
    </div>
}

export default AddUpdateCompanyUserModal;