/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    Proposal,
    ProposalFromJSON,
    ProposalFromJSONTyped,
    ProposalToJSON,
} from './Proposal';

/**
 * 
 * @export
 * @interface ProposalDepartment
 */
export interface ProposalDepartment {
    /**
     * 
     * @type {string}
     * @memberof ProposalDepartment
     */
    proposalId?: string;
    /**
     * 
     * @type {Proposal}
     * @memberof ProposalDepartment
     */
    proposal?: Proposal;
    /**
     * 
     * @type {string}
     * @memberof ProposalDepartment
     */
    departmentId?: string;
    /**
     * 
     * @type {Department}
     * @memberof ProposalDepartment
     */
    department?: Department;
}

export function ProposalDepartmentFromJSON(json: any): ProposalDepartment {
    return ProposalDepartmentFromJSONTyped(json, false);
}

export function ProposalDepartmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalDepartment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'proposal': !exists(json, 'proposal') ? undefined : ProposalFromJSON(json['proposal']),
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
    };
}

export function ProposalDepartmentToJSON(value?: ProposalDepartment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'proposal': ProposalToJSON(value.proposal),
        'departmentId': value.departmentId,
        'department': DepartmentToJSON(value.department),
    };
}

