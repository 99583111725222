/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PracticeConventionState {
    PendingSupervisorSignature = 'PendingSupervisorSignature',
    PendingTutorSignature = 'PendingTutorSignature',
    PendingStudentSignature = 'PendingStudentSignature',
    PendingRepresentativeSignature = 'PendingRepresentativeSignature',
    PendingDeanValidation = 'PendingDeanValidation',
    Finalized = 'Finalized'
}

export function PracticeConventionStateFromJSON(json: any): PracticeConventionState {
    return PracticeConventionStateFromJSONTyped(json, false);
}

export function PracticeConventionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PracticeConventionState {
    return json as PracticeConventionState;
}

export function PracticeConventionStateToJSON(value?: PracticeConventionState | null): any {
    return value as any;
}

