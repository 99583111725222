/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalStudentData,
    AdditionalStudentDataFromJSON,
    AdditionalStudentDataFromJSONTyped,
    AdditionalStudentDataToJSON,
} from './AdditionalStudentData';
import {
    CompanyInternship,
    CompanyInternshipFromJSON,
    CompanyInternshipFromJSONTyped,
    CompanyInternshipToJSON,
} from './CompanyInternship';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    InternshipEnrollment,
    InternshipEnrollmentFromJSON,
    InternshipEnrollmentFromJSONTyped,
    InternshipEnrollmentToJSON,
} from './InternshipEnrollment';
import {
    PracticeConvention,
    PracticeConventionFromJSON,
    PracticeConventionFromJSONTyped,
    PracticeConventionToJSON,
} from './PracticeConvention';
import {
    ProfessorInternship,
    ProfessorInternshipFromJSON,
    ProfessorInternshipFromJSONTyped,
    ProfessorInternshipToJSON,
} from './ProfessorInternship';
import {
    ProposalCoordinator,
    ProposalCoordinatorFromJSON,
    ProposalCoordinatorFromJSONTyped,
    ProposalCoordinatorToJSON,
} from './ProposalCoordinator';
import {
    ProposalTeachingStaff,
    ProposalTeachingStaffFromJSON,
    ProposalTeachingStaffFromJSONTyped,
    ProposalTeachingStaffToJSON,
} from './ProposalTeachingStaff';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    SkillEndorsement,
    SkillEndorsementFromJSON,
    SkillEndorsementFromJSONTyped,
    SkillEndorsementToJSON,
} from './SkillEndorsement';
import {
    StudentAppreciation,
    StudentAppreciationFromJSON,
    StudentAppreciationFromJSONTyped,
    StudentAppreciationToJSON,
} from './StudentAppreciation';
import {
    StudentPersonalData,
    StudentPersonalDataFromJSON,
    StudentPersonalDataFromJSONTyped,
    StudentPersonalDataToJSON,
} from './StudentPersonalData';
import {
    StudentYearEnum,
    StudentYearEnumFromJSON,
    StudentYearEnumFromJSONTyped,
    StudentYearEnumToJSON,
} from './StudentYearEnum';
import {
    UserSkill,
    UserSkillFromJSON,
    UserSkillFromJSONTyped,
    UserSkillToJSON,
} from './UserSkill';

/**
 * 
 * @export
 * @interface AcademicUser
 */
export interface AcademicUser {
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AcademicUser
     */
    cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    cvUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    linkedinUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    gitUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    researchField?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    departmentId?: string;
    /**
     * 
     * @type {StudentYearEnum}
     * @memberof AcademicUser
     */
    year?: StudentYearEnum;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AcademicUser
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Department}
     * @memberof AcademicUser
     */
    department?: Department;
    /**
     * 
     * @type {StudentPersonalData}
     * @memberof AcademicUser
     */
    studentPersonalData?: StudentPersonalData;
    /**
     * 
     * @type {AdditionalStudentData}
     * @memberof AcademicUser
     */
    additionalStudentData?: AdditionalStudentData;
    /**
     * 
     * @type {Array<UserSkill>}
     * @memberof AcademicUser
     */
    userSkills?: Array<UserSkill> | null;
    /**
     * 
     * @type {Array<SkillEndorsement>}
     * @memberof AcademicUser
     */
    givenSkillEndorsements?: Array<SkillEndorsement> | null;
    /**
     * 
     * @type {Array<StudentAppreciation>}
     * @memberof AcademicUser
     */
    receivedStudentAppreciations?: Array<StudentAppreciation> | null;
    /**
     * 
     * @type {Array<StudentAppreciation>}
     * @memberof AcademicUser
     */
    givenStudentAppreciations?: Array<StudentAppreciation> | null;
    /**
     * 
     * @type {Array<ProfessorInternship>}
     * @memberof AcademicUser
     */
    professorInternships?: Array<ProfessorInternship> | null;
    /**
     * 
     * @type {Array<InternshipEnrollment>}
     * @memberof AcademicUser
     */
    internshipEnrollments?: Array<InternshipEnrollment> | null;
    /**
     * 
     * @type {Array<PracticeConvention>}
     * @memberof AcademicUser
     */
    practiceConventionsAsSupervisor?: Array<PracticeConvention> | null;
    /**
     * 
     * @type {Array<CompanyInternship>}
     * @memberof AcademicUser
     */
    companyInternshipsAsFacultyCoordinator?: Array<CompanyInternship> | null;
    /**
     * 
     * @type {Array<ProposalCoordinator>}
     * @memberof AcademicUser
     */
    proposalCoordinators?: Array<ProposalCoordinator> | null;
    /**
     * 
     * @type {Array<ProposalTeachingStaff>}
     * @memberof AcademicUser
     */
    proposalTeachingStaff?: Array<ProposalTeachingStaff> | null;
}

export function AcademicUserFromJSON(json: any): AcademicUser {
    return AcademicUserFromJSONTyped(json, false);
}

export function AcademicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcademicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'cvUrl': !exists(json, 'cvUrl') ? undefined : json['cvUrl'],
        'linkedinUrl': !exists(json, 'linkedinUrl') ? undefined : json['linkedinUrl'],
        'gitUrl': !exists(json, 'gitUrl') ? undefined : json['gitUrl'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'researchField': !exists(json, 'researchField') ? undefined : json['researchField'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'year': !exists(json, 'year') ? undefined : StudentYearEnumFromJSON(json['year']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'studentPersonalData': !exists(json, 'studentPersonalData') ? undefined : StudentPersonalDataFromJSON(json['studentPersonalData']),
        'additionalStudentData': !exists(json, 'additionalStudentData') ? undefined : AdditionalStudentDataFromJSON(json['additionalStudentData']),
        'userSkills': !exists(json, 'userSkills') ? undefined : (json['userSkills'] === null ? null : (json['userSkills'] as Array<any>).map(UserSkillFromJSON)),
        'givenSkillEndorsements': !exists(json, 'givenSkillEndorsements') ? undefined : (json['givenSkillEndorsements'] === null ? null : (json['givenSkillEndorsements'] as Array<any>).map(SkillEndorsementFromJSON)),
        'receivedStudentAppreciations': !exists(json, 'receivedStudentAppreciations') ? undefined : (json['receivedStudentAppreciations'] === null ? null : (json['receivedStudentAppreciations'] as Array<any>).map(StudentAppreciationFromJSON)),
        'givenStudentAppreciations': !exists(json, 'givenStudentAppreciations') ? undefined : (json['givenStudentAppreciations'] === null ? null : (json['givenStudentAppreciations'] as Array<any>).map(StudentAppreciationFromJSON)),
        'professorInternships': !exists(json, 'professorInternships') ? undefined : (json['professorInternships'] === null ? null : (json['professorInternships'] as Array<any>).map(ProfessorInternshipFromJSON)),
        'internshipEnrollments': !exists(json, 'internshipEnrollments') ? undefined : (json['internshipEnrollments'] === null ? null : (json['internshipEnrollments'] as Array<any>).map(InternshipEnrollmentFromJSON)),
        'practiceConventionsAsSupervisor': !exists(json, 'practiceConventionsAsSupervisor') ? undefined : (json['practiceConventionsAsSupervisor'] === null ? null : (json['practiceConventionsAsSupervisor'] as Array<any>).map(PracticeConventionFromJSON)),
        'companyInternshipsAsFacultyCoordinator': !exists(json, 'companyInternshipsAsFacultyCoordinator') ? undefined : (json['companyInternshipsAsFacultyCoordinator'] === null ? null : (json['companyInternshipsAsFacultyCoordinator'] as Array<any>).map(CompanyInternshipFromJSON)),
        'proposalCoordinators': !exists(json, 'proposalCoordinators') ? undefined : (json['proposalCoordinators'] === null ? null : (json['proposalCoordinators'] as Array<any>).map(ProposalCoordinatorFromJSON)),
        'proposalTeachingStaff': !exists(json, 'proposalTeachingStaff') ? undefined : (json['proposalTeachingStaff'] === null ? null : (json['proposalTeachingStaff'] as Array<any>).map(ProposalTeachingStaffFromJSON)),
    };
}

export function AcademicUserToJSON(value?: AcademicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'cursId': value.cursId,
        'email': value.email,
        'description': value.description,
        'phone': value.phone,
        'cvUrl': value.cvUrl,
        'linkedinUrl': value.linkedinUrl,
        'gitUrl': value.gitUrl,
        'siteUrl': value.siteUrl,
        'researchField': value.researchField,
        'image': value.image,
        'departmentId': value.departmentId,
        'year': StudentYearEnumToJSON(value.year),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'department': DepartmentToJSON(value.department),
        'studentPersonalData': StudentPersonalDataToJSON(value.studentPersonalData),
        'additionalStudentData': AdditionalStudentDataToJSON(value.additionalStudentData),
        'userSkills': value.userSkills === undefined ? undefined : (value.userSkills === null ? null : (value.userSkills as Array<any>).map(UserSkillToJSON)),
        'givenSkillEndorsements': value.givenSkillEndorsements === undefined ? undefined : (value.givenSkillEndorsements === null ? null : (value.givenSkillEndorsements as Array<any>).map(SkillEndorsementToJSON)),
        'receivedStudentAppreciations': value.receivedStudentAppreciations === undefined ? undefined : (value.receivedStudentAppreciations === null ? null : (value.receivedStudentAppreciations as Array<any>).map(StudentAppreciationToJSON)),
        'givenStudentAppreciations': value.givenStudentAppreciations === undefined ? undefined : (value.givenStudentAppreciations === null ? null : (value.givenStudentAppreciations as Array<any>).map(StudentAppreciationToJSON)),
        'professorInternships': value.professorInternships === undefined ? undefined : (value.professorInternships === null ? null : (value.professorInternships as Array<any>).map(ProfessorInternshipToJSON)),
        'internshipEnrollments': value.internshipEnrollments === undefined ? undefined : (value.internshipEnrollments === null ? null : (value.internshipEnrollments as Array<any>).map(InternshipEnrollmentToJSON)),
        'practiceConventionsAsSupervisor': value.practiceConventionsAsSupervisor === undefined ? undefined : (value.practiceConventionsAsSupervisor === null ? null : (value.practiceConventionsAsSupervisor as Array<any>).map(PracticeConventionToJSON)),
        'companyInternshipsAsFacultyCoordinator': value.companyInternshipsAsFacultyCoordinator === undefined ? undefined : (value.companyInternshipsAsFacultyCoordinator === null ? null : (value.companyInternshipsAsFacultyCoordinator as Array<any>).map(CompanyInternshipToJSON)),
        'proposalCoordinators': value.proposalCoordinators === undefined ? undefined : (value.proposalCoordinators === null ? null : (value.proposalCoordinators as Array<any>).map(ProposalCoordinatorToJSON)),
        'proposalTeachingStaff': value.proposalTeachingStaff === undefined ? undefined : (value.proposalTeachingStaff === null ? null : (value.proposalTeachingStaff as Array<any>).map(ProposalTeachingStaffToJSON)),
    };
}

