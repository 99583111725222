import './App.scss';
import Router from './Router/Router';
import 'antd/dist/antd.min.css';
import mailValidationReducer from './Components/MailValidation/MailValidationSlice';
import { PersistGate } from 'redux-persist/integration/react'
import studentReducer from './Components/Student/StudentSlice';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, createStore } from 'redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import fetchIntercept from 'fetch-intercept';
import { getTokenExpire } from './utils/utilFunctions';
import { StatusCodes } from 'http-status-codes';

fetchIntercept.register({
  response: (response) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (response.request?.headers && response.request.headers.has('authorization') && 
      response.status === StatusCodes.UNAUTHORIZED && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return response;
  },
  responseError: (error) => {
    const expire = getTokenExpire();
    const now = Date.now() / 1000;

    if (error.request?.headers && error.request.headers.has('authorization') && (!expire || expire < now)) {
      localStorage.removeItem("token");
      window.location.href = '/?expired=true';
    }

    return Promise.reject(error);
  }
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['student']
};

const rootReducer = combineReducers({
  mailValidation: mailValidationReducer,
  student: studentReducer
})

const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer);
const persistor = persistStore(store);
const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <div className='App'>
            <Router />
          </div>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
