import { DepartmentApi } from "../Api";
import { getAuthConfiguration } from "./configuration";

const AuthFacultyApiFactory = () => new DepartmentApi(getAuthConfiguration());

export const getDepartmentsForFaculty = () => {
    return AuthFacultyApiFactory().apiDepartmentGetAllForFacultyGet();
}

export const getAllDepartments = (namePattern?: string, page?: number, pageSize?: number) => {
    return AuthFacultyApiFactory().apiDepartmentGetAllGet({ page, pageSize, namePattern });
}