import { useTranslation } from 'react-i18next';
import Layout from '../../Containers/Layout';
import styles from './Questions.module.scss';

const Questions = () => {
    const { t } = useTranslation();
    return (
        <Layout>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2>{t('questions.frequentQuestions')}</h2>
                    <div className={styles.info}>
                        <h3>{t('questions.applyInternshipQuestion')}</h3>
                        <h4>{t('questions.applyInternshipAnswer')}</h4>
                    </div>
                    <div className={styles.info}>
                        <h3>{t('questions.addInternshipQuestion')}</h3>
                        <h4>{t('questions.addInternshipAnswer')}</h4>
                    </div>
                </div>
                <div className={styles.container}>
                </div>
            </div>


        </Layout >
    );
}
export default Questions;