import {Link, useLocation} from "react-router-dom";
import {theme} from "../../theme";
import {
    isUserLogged,
    useIsAdmin,
    useIsCompany,
    useIsEconomicDirector,
    useIsFacultyAdmin,
    useIsGeneralDirector,
    useIsInternshipAdmin,
    useIsLegalCounselor, useIsPreventiveFinancialControl,
    useIsProfessor,
    useIsRector,
    useIsStudent, useIsUniversityAccordResponsible
} from '../../utils/utilFunctions';
import {useTranslation} from "react-i18next";

const HeadersLinksProposals = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const isCompany = useIsCompany();
    const isProfessor = useIsProfessor();
    const isStudent = useIsStudent();
    const isInternshipAdmin = useIsInternshipAdmin();
    const isFacultyAdmin = useIsFacultyAdmin();
    const isAdmin = useIsAdmin();
    const isRector = useIsRector();
    const isGeneralDirector = useIsGeneralDirector();
    const isEconomicDirector = useIsEconomicDirector();
    const isLegalCounselor = useIsLegalCounselor();
    const isPreventiveFinancialControl = useIsPreventiveFinancialControl();
    const isUniversityAccordResponsible = useIsUniversityAccordResponsible();

    return (
        <>
            <Link
                to='/'
                style={{
                    fontWeight: location.pathname === "/" ? "bold" : "normal",
                    color: window.innerWidth > 1215 ? (location.pathname === "/" ? theme.primaryColor : "black") : theme.secondColor,
                    fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                    padding: window.innerWidth > 1215 ? "0" : "5px",
                }}
            >
                {t('header.home')}
            </Link>
            {isUserLogged() && isProfessor ?
                (<Link
                        to="/propuneri"
                        style={{
                            fontWeight: location.pathname === "/propuneri" ? "bold" : "normal",
                            color: window.innerWidth > 1215 ? (location.pathname === "/propuneri" ? theme.primaryColor : "black") : theme.secondColor,
                            fontSize: window.innerWidth > 1215 ? "125%" : "20px",
                            padding: window.innerWidth > 1215 ? "0" : "5px",
                        }}
                    >
                        Propuneri
                </Link>)
            : null}
            
        </>
    );

};

export default HeadersLinksProposals;