/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EducationTypeEnum,
    EducationTypeEnumFromJSON,
    EducationTypeEnumFromJSONTyped,
    EducationTypeEnumToJSON,
} from './EducationTypeEnum';
import {
    ProgramTypeEnum,
    ProgramTypeEnumFromJSON,
    ProgramTypeEnumFromJSONTyped,
    ProgramTypeEnumToJSON,
} from './ProgramTypeEnum';
import {
    StudyDomainEnum,
    StudyDomainEnumFromJSON,
    StudyDomainEnumFromJSONTyped,
    StudyDomainEnumToJSON,
} from './StudyDomainEnum';
import {
    TeachingLanguageEnum,
    TeachingLanguageEnumFromJSON,
    TeachingLanguageEnumFromJSONTyped,
    TeachingLanguageEnumToJSON,
} from './TeachingLanguageEnum';

/**
 * 
 * @export
 * @interface ProposalRequestDTO
 */
export interface ProposalRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    departmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalRequestDTO
     */
    partnerDepartments?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalRequestDTO
     */
    coordinators?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalRequestDTO
     */
    teachingStaff?: Array<string> | null;
    /**
     * 
     * @type {StudyDomainEnum}
     * @memberof ProposalRequestDTO
     */
    studyDomain?: StudyDomainEnum;
    /**
     * 
     * @type {ProgramTypeEnum}
     * @memberof ProposalRequestDTO
     */
    programType?: ProgramTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    targetGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    extraConditions?: string | null;
    /**
     * 
     * @type {EducationTypeEnum}
     * @memberof ProposalRequestDTO
     */
    educationType?: EducationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    programObjectives?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProposalRequestDTO
     */
    minimumParticipants?: number;
    /**
     * 
     * @type {number}
     * @memberof ProposalRequestDTO
     */
    maximumParticipants?: number;
    /**
     * 
     * @type {TeachingLanguageEnum}
     * @memberof ProposalRequestDTO
     */
    teachingLanguage?: TeachingLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    targetSkills?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    programLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    necessaryEquipment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProposalRequestDTO
     */
    taxCuantum?: number;
    /**
     * 
     * @type {string}
     * @memberof ProposalRequestDTO
     */
    evaluationType?: string | null;
}

export function ProposalRequestDTOFromJSON(json: any): ProposalRequestDTO {
    return ProposalRequestDTOFromJSONTyped(json, false);
}

export function ProposalRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'partnerDepartments': !exists(json, 'partnerDepartments') ? undefined : json['partnerDepartments'],
        'coordinators': !exists(json, 'coordinators') ? undefined : json['coordinators'],
        'teachingStaff': !exists(json, 'teachingStaff') ? undefined : json['teachingStaff'],
        'studyDomain': !exists(json, 'studyDomain') ? undefined : StudyDomainEnumFromJSON(json['studyDomain']),
        'programType': !exists(json, 'programType') ? undefined : ProgramTypeEnumFromJSON(json['programType']),
        'targetGroup': !exists(json, 'targetGroup') ? undefined : json['targetGroup'],
        'extraConditions': !exists(json, 'extraConditions') ? undefined : json['extraConditions'],
        'educationType': !exists(json, 'educationType') ? undefined : EducationTypeEnumFromJSON(json['educationType']),
        'programObjectives': !exists(json, 'programObjectives') ? undefined : json['programObjectives'],
        'minimumParticipants': !exists(json, 'minimumParticipants') ? undefined : json['minimumParticipants'],
        'maximumParticipants': !exists(json, 'maximumParticipants') ? undefined : json['maximumParticipants'],
        'teachingLanguage': !exists(json, 'teachingLanguage') ? undefined : TeachingLanguageEnumFromJSON(json['teachingLanguage']),
        'targetSkills': !exists(json, 'targetSkills') ? undefined : json['targetSkills'],
        'programLocation': !exists(json, 'programLocation') ? undefined : json['programLocation'],
        'necessaryEquipment': !exists(json, 'necessaryEquipment') ? undefined : json['necessaryEquipment'],
        'taxCuantum': !exists(json, 'taxCuantum') ? undefined : json['taxCuantum'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : json['evaluationType'],
    };
}

export function ProposalRequestDTOToJSON(value?: ProposalRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'departmentId': value.departmentId,
        'name': value.name,
        'partnerDepartments': value.partnerDepartments,
        'coordinators': value.coordinators,
        'teachingStaff': value.teachingStaff,
        'studyDomain': StudyDomainEnumToJSON(value.studyDomain),
        'programType': ProgramTypeEnumToJSON(value.programType),
        'targetGroup': value.targetGroup,
        'extraConditions': value.extraConditions,
        'educationType': EducationTypeEnumToJSON(value.educationType),
        'programObjectives': value.programObjectives,
        'minimumParticipants': value.minimumParticipants,
        'maximumParticipants': value.maximumParticipants,
        'teachingLanguage': TeachingLanguageEnumToJSON(value.teachingLanguage),
        'targetSkills': value.targetSkills,
        'programLocation': value.programLocation,
        'necessaryEquipment': value.necessaryEquipment,
        'taxCuantum': value.taxCuantum,
        'evaluationType': value.evaluationType,
    };
}

